import { Flex, BoxProps } from '@chakra-ui/react'

import LogoWTagLine from '../../LogoWTagLine'
import SocialStack from '../../SocialStack'

const LogoWSocial = (props: BoxProps) => (
  <Flex direction="column" alignItems="center" {...props}>
    <LogoWTagLine color="white" mb="21px" />
    <SocialStack direction="row" justifyContent="center" iconColor="light_gray" />
  </Flex>
)

export default LogoWSocial
