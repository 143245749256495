export const MIIMOSA_CONDITIONS_URL =
  'https://mii-bkt-marketing-prod.s3.eu-central-1.amazonaws.com/site-content/CGU_MiiMOSA_2021_v02.09.2021.pdf#page='

export const MIIMOSA_LEGAL_URL =
  'https://mii-bkt-marketing-prod.s3.eu-central-1.amazonaws.com/site-content/CGU_MiiMOSA_2021_v02.09.2021.pdf#page=24'

export const MIIMOSA_SESSION = '_miimosa_session'
export const MIIMOSA_SESSION_V3 = '_miimosa_session_v3'
export const MIIMOSA_SIGNATURE = '_miimosa_v3_signature'
export const MIIMOSA_FLASH = '_miimosa_v3_flash'

export const LENDING = 'lending'
export const DONATION = 'donation'
