import { createContext } from 'react'
import useSWR from 'swr'

import { createV3Client } from '@miimosa/api/v3/base'
import { Payment } from '@miimosa/protocol'

export const WalletContext = createContext<Payment.FetchWalletResponse | undefined>(undefined)

const WalletProvider = ({ children }: { children: JSX.Element }) => {
  const client = createV3Client()
  const fetcher = (url: string) => client.post(url, {}).then((res) => res.data)
  const { data: wallet } = useSWR<Payment.FetchWalletResponse>('/payment/v1/FetchWallet', fetcher, {
    refreshInterval: 5 * 60000, // milliseconds aka 5 min
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
  })

  return <WalletContext.Provider value={wallet}>{children}</WalletContext.Provider>
}

export default WalletProvider
