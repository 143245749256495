import { captureException } from '@sentry/nextjs'
import { createContext } from 'react'

import { usePersistedState } from '@miimosa/design-system'
import { Projects } from '@miimosa/protocol'

interface CartReward {
  id: number
  title: string
  reward_id: number
  amount: number
  quantity: number
  description: string
  available_quantity: number
  remaining_quantity: number
  delivery_at: string
  shipping_mode: string
  shipping_details: string
}

interface CartContextInterface {
  cartData: { amount: number; uuid: number; closed_at: string; project_slug: string; rewards: CartReward[] }
  createCart: ({ project_id }: { project_id: number }) => void
  getRewardInCart: (rewardId: number) => Projects.Reward
  addToCart: ({
    cart_id,
    reward_id,
    quantity,
  }: {
    cart_id: number
    reward_id: number
    quantity: number
  }) => Promise<string | void>
}

export const CartContext = createContext<CartContextInterface>({} as CartContextInterface)

export const cartStorageKey = 'cart_data'

const initialCartState = {
  amount: 0,
  uuid: null,
  closed_at: null,
  rewards: [],
}

export const CartContextProvider = ({ children }: { children: any }) => {
  const [persistedCartData, setPersistedCartData] = usePersistedState<string>(
    cartStorageKey,
    JSON.stringify(initialCartState)
  )

  const createCart = async ({ project_id }: { project_id: number }) => {
    const result = await fetch(`/nextapi/cart/createCart`, {
      method: 'POST',
      body: JSON.stringify({ project_id }),
    })

    const newCartData = await result.json()

    if (result.status === 200) {
      return setPersistedCartData(JSON.stringify(newCartData))
    } else {
      return captureException('error creating cart')
    }
  }

  const addToCart = async ({
    cart_id,
    reward_id,
    quantity,
  }: {
    cart_id: number
    reward_id: number
    quantity: number
  }) => {
    const result = await fetch(`/nextapi/cart/createReward`, {
      method: 'POST',
      body: JSON.stringify({ cart_id, reward_id, quantity }),
    })

    const newCartData = await result.json()

    if (result.status === 200) {
      return setPersistedCartData(JSON.stringify(newCartData))
    } else {
      return captureException('error adding to cart')
    }
  }

  const getRewardInCart = (rewardId: number) =>
    JSON.parse(persistedCartData)?.rewards.find(({ reward_id }: { reward_id: number }) => reward_id === rewardId)

  const cartState = {
    cartData: JSON.parse(persistedCartData),
    createCart,
    getRewardInCart,
    addToCart,
  }

  return <CartContext.Provider value={cartState}>{children}</CartContext.Provider>
}
