import { Box, Flex, Link as OutsideLink, Text, Grid, GridItem } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import { FC } from 'react'

import { s3ImageURL } from '@miimosa/common'
import type { Lang, CountryCode } from '@miimosa/common/types'
import { Link, usePlatform, toV2Link } from '@miimosa/design-system'

import { columns, Link as LinkData } from './columns'
import { DisclaimerWExternals, LogoWSocial } from './components'

interface FooterLinkProps {
  data: LinkData
  platform: CountryCode
  lang: Lang
  t: (key: string) => string
}

const FooterLink: FC<FooterLinkProps> = ({ data, t, lang, platform }: FooterLinkProps) => {
  const props = {
    fontSize: 'xs',
    variant: 'small_grey',
    textAlign: 'left' as const,
    children: t('footer.links.' + data.text),
  }

  if (data.route) {
    return <Link {...props} href={data.route} />
  }

  if (data.href) {
    const isInternal = data.href.startsWith('/')
    const url = isInternal ? toV2Link(data.href, platform, lang) : data.href
    return <OutsideLink href={url} {...props} target={isInternal ? undefined : '_blank'} />
  }

  return <Text color="white" {...props} />
}

const Footer = () => {
  const { platform, lang } = usePlatform()
  const { t } = useTranslation()

  const firstColumnWidth = { base: '100%', md: '256px' }

  return (
    <footer>
      <Box position="relative" bgImage={s3ImageURL('background-texture-green.jpg')} bgRepeat="repeat">
        <Box position="relative" zIndex={1} mx="auto" maxW="1216px" py={12} px={{ base: '22px', md: 12 }}>
          <Flex justifyContent="space-between" direction={{ base: 'column-reverse', md: 'row' }}>
            <Box w={firstColumnWidth} pt={4}>
              <LogoWSocial mx="auto" />
            </Box>
          </Flex>
          <Flex mt={8} direction={{ base: 'column', md: 'row' }}>
            <DisclaimerWExternals w={firstColumnWidth} mb={{ base: 10, md: 0 }} flex="none" />
            <Grid
              templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)', lg: 'repeat(4, 1fr)' }}
              flex="1 1 0"
              gap={6}
              ml={{ base: undefined, md: 10 }}
            >
              {columns.map((column, idx) => (
                <GridItem key={idx}>
                  {column.map((section, sectionIdx) => (
                    <Flex
                      direction="column"
                      key={section.title}
                      mt={sectionIdx === 0 ? undefined : 8}
                      alignItems="left"
                      columnGap={2}
                      rowGap={2}
                    >
                      <Text as="h3" size="sm" fontWeight="bold" color="white" textAlign="left">
                        {t('footer.titles.' + section.title)}
                      </Text>
                      <Flex direction="column" rowGap={3}>
                        {section.links.map((link) => (
                          <FooterLink key={link.text} data={link} lang={lang} platform={platform} t={t} />
                        ))}
                      </Flex>
                    </Flex>
                  ))}
                </GridItem>
              ))}
            </Grid>
          </Flex>
          {/* <Flex direction="column" mt={10} rowGap={3}>
            <Text color="white" textAlign="justify" fontSize="0.7rem" lineHeight="1.1rem">
              {t('footer.warning_text_1')}
            </Text>
            <Text color="white" textAlign="justify" fontSize="0.7rem" lineHeight="1.1rem">
              {t('footer.warning_text_2')}
            </Text>
          </Flex> */}
        </Box>
      </Box>
      <Text py={3} bg="white" color="dark_green" textAlign="center" size="xs" as="div">
        {t('footer.made_with_love', { year: new Date().getFullYear() })}
      </Text>
    </footer>
  )
}

export default Footer
