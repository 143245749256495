import { Box } from '@chakra-ui/react'
import NextLink from 'next/link'

import { FullWidthContainer } from '@miimosa/design-system/components'
import Logo from '@miimosa/design-system/components/icons/Logo2'

const FullWidthLayoutHeader = () => {
  return (
    <FullWidthContainer
      justify="center"
      align="center"
      bgColor="white"
      height="64px"
      boxShadow="0px 1px 2px rgba(42, 42, 42, 0.08)"
      position="sticky"
      top={0}
      zIndex="2"
    >
      <Box
        mx={{ base: 4, lg: 0 }}
        _focus={{
          outline: 0,
          boxShadow: 'none',
        }}
      >
        <NextLink href={'/'}>
          <Logo color="dark_green" h="20px" w="auto" />
        </NextLink>
      </Box>
    </FullWidthContainer>
  )
}

export default FullWidthLayoutHeader
