import { Flex, BoxProps, Text, Link } from '@chakra-ui/react'

interface Props extends BoxProps {
  text: string
  linkUrl: string
}

const EventBanner = ({ text, linkUrl, ...props }: Props) => {
  return (
    <Link href={linkUrl} isExternal>
      <Flex
        {...props}
        bgColor="#B3E7D6"
        justify="center"
        align="center"
        position="relative"
        direction={{ base: 'column', lg: 'row' }}
        px="40px"
      >
        <Text textAlign="center" size="xs" dangerouslySetInnerHTML={{ __html: text }} />
      </Flex>
    </Link>
  )
}

export default EventBanner
