import { Flex, FlexProps, Text } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'

import Logo from '@miimosa/design-system/components/icons/Logo2'

const LogoWTagLine = (props: FlexProps) => {
  const { t } = useTranslation()

  return (
    <Flex direction="column" {...props}>
      <Logo w="auto" h="32px" display="block" />
      <Text fontSize="0.75rem" fontWeight="700" display="block" as="span" mt="12px">
        {t('tag_line')}
      </Text>
    </Flex>
  )
}

export default LogoWTagLine
