import { Flex, Heading, Text } from '@chakra-ui/react'

interface Props2 {
  title: string
  userFullName: string
}

const TopInformations = ({ title, userFullName }: Props2) => {
  return (
    <Flex direction="column" rowGap={6} w="full" alignItems="center">
      <Flex direction="column" rowGap={2} alignItems="center">
        <Heading size="lg" color="main_gray" fontWeight="bold" as="h1">
          {title}
        </Heading>
        <Flex direction="row">
          <Text size="md">par&nbsp;</Text>
          <Text size="md" fontWeight="bold">
            {userFullName}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default TopInformations
