import { Box, Button, Flex, Heading, Text } from '@chakra-ui/react'
import { sanitize } from 'isomorphic-dompurify'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useContext } from 'react'

import { UserContext } from '@miimosa/design-system'

type Props = {
  financialIntroduction?: string | null
  pnl?: string | null
  balance?: string | null
  incExp?: string | null
  financialAnalysis?: string | null
}

const Finance = ({ financialIntroduction, pnl, balance, incExp, financialAnalysis }: Props) => {
  const { t } = useTranslation('project')
  const user = useContext(UserContext)
  const { asPath } = useRouter()
  const shouldDisplayFinance = financialIntroduction || pnl || balance || incExp || financialAnalysis

  if (!shouldDisplayFinance) {
    return null
  }

  if (!user) {
    return (
      <Flex
        direction="column"
        w="full"
        minHeight="300px"
        justifyContent="center"
        bg="white"
        p={8}
        border="solid 1px"
        borderColor="light_gray"
      >
        <Flex direction="column" alignItems="center" w="full" rowGap={6} mt={6} mb={{ base: 6, md: 0 }}>
          <Text size="xl" textAlign="center">
            Pour avoir accès à ces informations, veuillez vous connecter.
          </Text>
          <Button as="a" href={`/authenticate?return_url=${asPath}`} variant="primary" size="md">
            Se connecter / S&apos;inscrire
          </Button>
        </Flex>
      </Flex>
    )
  }

  return (
    <Flex
      direction="column"
      w="full"
      className="ck ck-content __description project-tabs-content"
      id="ck"
      minH="300px"
      bg="white"
      p={8}
      border="solid 1px"
      borderColor="light_gray"
    >
      {financialIntroduction && (
        <>
          <Heading my={5}>{t('finance.introduction')}</Heading>
          <Box dangerouslySetInnerHTML={{ __html: sanitize(financialIntroduction) }} width="full" height="auto" />
        </>
      )}
      {pnl && (
        <>
          <Box
            className="custom-table custom-table-1 table-responsive table"
            dangerouslySetInnerHTML={{ __html: sanitize(pnl) }}
            width="full"
            height="auto"
          />
        </>
      )}
      {balance && (
        <>
          <Box
            className="custom-table custom-table-1 table-responsive table"
            dangerouslySetInnerHTML={{ __html: sanitize(balance) }}
            width="full"
            height="auto"
          />
        </>
      )}
      {incExp && (
        <>
          <Box
            className="custom-table custom-table-1 table-responsive table"
            width="full"
            height="auto"
            dangerouslySetInnerHTML={{ __html: sanitize(incExp) }}
          />
        </>
      )}
      {financialAnalysis && (
        <>
          <Heading my={5}>{t('finance.analysis')}</Heading>
          <Box width="full" height="auto" dangerouslySetInnerHTML={{ __html: sanitize(financialAnalysis) }} />
        </>
      )}
    </Flex>
  )
}

export default Finance
