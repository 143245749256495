import { type V2Session } from '@miimosa/protocol'

// Open v2 Session for both client and server side
export async function openV2SessionWithoutAxios(
  cookie: string,
  jwt?: V2Session.JWT
): Promise<V2Session.OpenResponse | undefined> {
  const withJWT = jwt ? false : true
  const url = `${process.env.NEXT_PUBLIC_SERVER_ENDPOINT_V3 || process.env.SERVER_ENDPOINT_V3}/v2session/v1/Open`
  let params = { cookie, with_jwt: withJWT }
  if (jwt) {
    params = { ...params, ...{ current_access_token: jwt.access_token } }
  }
  try {
    const res = await fetch(url, {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    })
    if (res.status != 200) {
      return
    }
    return await res.json()
  } catch (error) {
    return
  }
}
