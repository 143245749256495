import { Button, Flex, Heading, Box } from '@chakra-ui/react'
import NextLink from 'next/link'
import { RefObject, useEffect } from 'react'

import type { CommentType } from '@miimosa/common/types'

interface Props {
  title: string
  content: string
  comments: CommentType[]
  slug: string
  containerTopRef: RefObject<HTMLDivElement>
}

const Article = ({ title, content, slug, containerTopRef }: Props) => {
  // Used to display embed iframes from WYSIWG
  useEffect(() => {
    // can not use ref because data comes from api :/
    document.querySelectorAll('oembed[url]').forEach((element) => {
      // force ts ignore because iframely is not set
      // ts-ignore ignores any ts errors on the next line
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.iframely && window.iframely.load(element, element.attributes.url.value)
    })
  }, [])

  const Up = () => {
    const elementPosition = containerTopRef.current?.offsetTop
    if (elementPosition) {
      const offset = elementPosition - 82 // navbar height

      window.scrollTo({
        top: offset,
        behavior: 'smooth',
      })
    }
  }

  return (
    <Flex direction="column">
      <Flex w="auto">
        <NextLink href={`/projects/${slug}/articles`} scroll={false}>
          <Button variant="primary" size="md" onClick={() => Up()}>
            Retour aux actualités
          </Button>
        </NextLink>
      </Flex>
      <Heading size="md" my={10}>
        {title}
      </Heading>
      <Box
        className="ck ck-content __description project-tabs-content"
        id="ck"
        dangerouslySetInnerHTML={{ __html: content }}
        width="full"
        height="auto"
      ></Box>
    </Flex>
  )
}

export default Article
