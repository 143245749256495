import { isNull } from 'lodash'
import { type FieldValues } from 'react-hook-form'

import { Leads } from '@miimosa/protocol'

const formatData = ({
  data,
  leadId,
  funnelStep,
}: {
  data: FieldValues
  leadId: string
  funnelStep: Leads.Step | undefined
}) => {
  const formattedData: Leads.UpdateRequest = {
    funnel_type: 'FUNNEL_TYPE_PSFP',
    lead_id: leadId,
    answers: [],
  }

  if (funnelStep) {
    Object.entries(data).map((value) => {
      const inputName = value[0] as Leads.InputName
      if (inputName.startsWith('INPUT_NAME')) {
        const inputDesc = funnelStep.input_descriptors.filter((o) => o.name == inputName)[0]
        if (inputDesc.type == 'INPUT_TYPE_BOOL' && !isNull(value[1])) {
          formattedData.answers.push({ name: inputName, boolean: value[1] === 'true' })
        }
        if (inputDesc.type == 'INPUT_TYPE_STRING' && !isNull(value[1])) {
          if (Array.isArray(value[1])) {
            formattedData.answers.push({ name: inputName, str: value[1].join(',') })
          } else {
            formattedData.answers.push({ name: inputName, str: value[1] })
          }
        }
        if (inputDesc.type == 'INPUT_TYPE_INT32' && !isNull(value[1])) {
          formattedData.answers.push({ name: inputName, number: Number(value[1].replace(/\s/g, '')) })
        }
      }
    })
  }

  return formattedData
}

export default formatData
