import { Flex, Button } from '@chakra-ui/react'
import { FC, useMemo, useState } from 'react'

import Icon, { IconName } from '@miimosa/design-system/components/Icon'

interface Props {
  defaultIndex: string
  callback: (v: string) => void
  segments: {
    iconName?: IconName
    iconColor?: string
    label: string
    value: string
    segmentRef: any
  }[]
}

const SegmentedButtons: FC<Props> = ({ defaultIndex, callback, segments }) => {
  const [activeIndex, setActiveIndex] = useState<string>(defaultIndex)
  const onChange = (value: string) => {
    setActiveIndex(value)
    callback(value)
  }
  const reversedSegments = useMemo(() => [...segments].reverse(), [segments])

  return (
    <Flex wrap="wrap">
      {reversedSegments.map(({ value, label, iconName, iconColor }) => {
        const isActive = value === activeIndex

        return (
          <Button
            key={value}
            size="md"
            variant={isActive ? 'primary' : 'secondary'}
            onClick={() => onChange(value)}
            {...(iconName
              ? { leftIcon: <Icon name={iconName} size="4xs" color={isActive ? 'bg.O' : iconColor} /> }
              : {})}
            mb="4"
            mr="6px"
          >
            {label}
          </Button>
        )
      })}
    </Flex>
  )
}

export default SegmentedButtons
