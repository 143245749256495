import { createIcon } from '@chakra-ui/icons'

export default createIcon({
    displayName: 'GenerationRegeneration',
    viewBox: '0 0 370 100',
    path: (
        <>
            <path
                d="M131.555 29.041C131.555 17.7887 126.085 10.6954 117.329 10.6954C107.919 10.6954 101.702 18.6332 101.702 30.4765C101.702 39.0265 104.913 45.5499 110.253 48.6532C104.932 51.6299 101.702 58.111 101.702 66.6399C101.702 78.7154 108.106 86.801 117.87 86.801C123.49 86.801 128.419 84.0776 131.368 79.3276L128.568 76.6043C125.824 80.7632 122.09 82.8743 117.889 82.8743C110.738 82.8743 106.071 76.9843 105.94 67.1676H131.518C131.592 66.4921 131.592 65.7954 131.592 65.2043C131.592 57.6465 129.109 51.9887 124.853 49.0543C127.504 47.7876 129.763 45.8032 131.387 43.1643L128.587 40.441C125.842 44.5999 122.108 46.711 117.908 46.711C110.757 46.711 106.09 40.821 105.959 31.0043H131.536C131.555 30.3076 131.555 29.6321 131.555 29.041ZM127.41 63.241H106.108C106.986 55.3032 111.056 50.7854 117.273 50.7854C123.546 50.7643 127.205 55.451 127.41 63.241ZM106.108 27.0776C106.986 19.1399 111.056 14.6221 117.273 14.6221C123.546 14.6221 127.224 19.3087 127.429 27.0776H106.108Z"
                fill="currentcolor"/>
            <path
                d="M155.191 10.6743C149.907 10.6743 145.576 13.461 143.168 18.4432L142.757 11.561H139.21V85.8721H143.28V66.3021C143.28 56.9287 147.611 50.7432 154.295 50.7432C159.84 50.7432 162.715 54.7543 162.715 62.291V85.851H166.785V61.341C166.785 60.8132 166.766 60.3065 166.729 59.821H166.785V25.1776C166.822 16.121 162.472 10.6743 155.191 10.6743ZM162.752 49.6454C160.829 47.8299 158.29 46.8587 155.21 46.8587C150.019 46.8587 145.744 49.5821 143.317 54.3954V30.1599C143.317 20.7865 147.648 14.601 154.332 14.601C159.877 14.601 162.752 18.6121 162.752 26.1488V49.6454Z"
                fill="currentcolor"/>
            <path
                d="M204.236 29.041C204.236 17.7887 198.766 10.6954 190.01 10.6954C180.6 10.6954 174.383 18.6332 174.383 30.4765C174.383 39.0265 177.595 45.5499 182.934 48.6532C177.613 51.6299 174.383 58.111 174.383 66.6399C174.383 78.7154 180.787 86.801 190.551 86.801C196.152 86.801 201.1 84.0776 204.05 79.3276L201.249 76.6043C198.505 80.7632 194.771 82.8743 190.57 82.8743C183.42 82.8743 178.752 76.9843 178.621 67.1676H204.199C204.274 66.4921 204.274 65.7954 204.274 65.2043C204.274 57.6465 201.791 51.9887 197.534 49.0543C200.166 47.7876 202.444 45.8032 204.068 43.1643L201.268 40.441C198.523 44.5999 194.789 46.711 190.589 46.711C183.438 46.711 178.771 40.821 178.64 31.0043H204.218C204.236 30.3076 204.236 29.6321 204.236 29.041ZM200.092 63.241H178.79C179.667 55.3032 183.737 50.7854 189.954 50.7854C196.208 50.7643 199.886 55.451 200.092 63.241ZM178.771 27.0776C179.648 19.1399 183.718 14.6221 189.935 14.6221C196.208 14.6221 199.886 19.3087 200.092 27.0776H178.771Z"
                fill="currentcolor"/>
            <path
                d="M215.849 18.0843L215.457 11.5821H211.91V85.8932H215.98V64.9721C215.98 56.8232 219.658 51.3765 225.258 51.3765C225.987 51.3765 226.995 51.461 228.264 51.841V47.4499C227.667 47.0699 226.453 46.8376 225.464 46.8376C221.655 46.8376 218.127 49.3921 215.98 53.9732V28.8088C215.98 20.6599 219.658 15.2132 225.258 15.2132C225.987 15.2132 226.995 15.2976 228.264 15.6565V11.2865C227.667 10.9065 226.453 10.6743 225.464 10.6743C221.599 10.6743 217.996 13.3132 215.849 18.0843Z"
                fill="currentcolor"/>
            <path
                d="M244.843 10.6743C239.765 10.6743 234.967 12.5743 231.756 15.9732L234.164 19.3721C236.964 16.4165 241.034 14.6221 244.656 14.6221C250.201 14.6221 253.543 17.8732 253.543 24.4387V27.0143C250.873 26.8665 248.129 26.7187 246.001 26.7187C236.516 26.7187 230.897 31.1732 230.897 39.111C230.897 43.6287 232.782 47.1121 235.9 49.0332C234.351 49.8565 232.95 50.9121 231.756 52.1576L234.164 55.5565C236.964 52.6221 241.034 50.8065 244.656 50.8065C250.201 50.8065 253.543 54.0576 253.543 60.6232V63.1987C250.873 63.051 248.129 62.9032 246.001 62.9032C236.516 62.9032 230.897 67.3576 230.897 75.2954C230.897 82.241 235.303 86.8432 241.987 86.8432C247.401 86.8432 251.545 84.4999 253.674 79.8132L254.01 85.9354H257.613V24.0376C257.594 15.361 252.666 10.6743 244.843 10.6743ZM253.524 70.5665C253.524 77.5965 248.708 83.0221 242.36 83.0221C237.879 83.0221 234.948 80.0032 234.948 75.2321C234.948 69.4899 239.018 66.6187 246.71 66.6187C248.652 66.6187 250.649 66.6187 253.524 66.9987V70.5665ZM242.379 46.8587C237.898 46.8587 234.967 43.8399 234.967 39.0899C234.967 33.3476 239.037 30.4765 246.729 30.4765C248.67 30.4765 250.668 30.4765 253.543 30.8565V34.4032C253.524 41.4121 248.726 46.8587 242.379 46.8587ZM250.481 47.851C251.788 46.7532 252.871 45.3387 253.655 43.6076L253.767 49.9199C252.834 49.0543 251.732 48.3576 250.481 47.851Z"
                fill="currentcolor"/>
            <path
                d="M273.034 0.414307L268.964 1.93431V11.6032H263.158V15.5299H268.964V47.7665H263.158V51.6932H268.964V77.301C268.964 83.6554 271.634 86.8221 276.787 86.8221C278.448 86.8221 279.998 86.4421 281.398 85.8298V81.9665C279.867 82.4943 278.392 82.7265 277.384 82.7265C273.837 82.7265 273.034 80.2354 273.034 76.9843V51.6721H281.38V45.8032C279.849 46.331 278.374 46.5632 277.366 46.5632C273.818 46.5632 273.016 44.0721 273.016 40.821V15.5087H281.566V11.5821H273.016V0.414307H273.034Z"
                fill="currentcolor"/>
            <path d="M287.727 85.8932H291.797V11.5821H287.727V85.8932Z" fill="currentcolor"/>
            <path
                d="M331.209 30.5399C331.209 18.6754 324.861 10.6743 315.377 10.6743C305.893 10.6743 299.489 18.6754 299.489 30.6032C299.489 39.1321 302.757 45.6765 308.077 48.7376C302.775 51.7776 299.489 58.2587 299.489 66.7665C299.489 78.6943 305.837 86.7799 315.321 86.7799C324.88 86.7799 331.228 78.6943 331.228 66.6821C331.228 58.2165 327.979 51.7143 322.658 48.6954C327.961 45.6554 331.209 39.111 331.209 30.5399ZM326.878 66.6399C326.878 76.3932 322.21 82.8743 315.321 82.8743C308.432 82.8743 303.839 76.3721 303.839 66.7876C303.839 57.2665 308.451 50.7854 315.321 50.7854C322.192 50.7643 326.878 57.1187 326.878 66.6399ZM315.321 46.711C308.432 46.711 303.839 40.2087 303.839 30.6243C303.839 21.1032 308.451 14.6221 315.321 14.6221C322.21 14.6221 326.878 20.9765 326.878 30.4765C326.878 40.2087 322.192 46.711 315.321 46.711Z"
                fill="currentcolor"/>
            <path
                d="M354.864 10.6743C349.58 10.6743 345.249 13.461 342.84 18.4432L342.448 11.561H338.901V85.8721H342.971V66.3021C342.971 56.9287 347.302 50.7432 353.986 50.7432C359.531 50.7432 362.406 54.7543 362.406 62.291V85.851H366.476V25.1776C366.476 16.121 362.145 10.6743 354.864 10.6743ZM362.406 49.6454C360.483 47.8299 357.944 46.8587 354.864 46.8587C349.673 46.8587 345.398 49.5821 342.971 54.3954V30.1599C342.971 20.7865 347.302 14.601 353.986 14.601C359.531 14.601 362.406 18.6121 362.406 26.1488V49.6454Z"
                fill="currentcolor"/>
            <path
                d="M89.9403 18.3165C87.7373 13.7143 82.9952 10.6954 77.6556 10.6954C68.8995 10.6954 62.9626 18.401 62.9626 29.801C62.9626 38.6887 66.5658 45.381 72.3161 47.9565C66.5845 50.4476 62.9626 57.0765 62.9626 65.9643C62.9626 77.4487 68.9742 85.3021 77.861 85.3021C83.2005 85.3021 87.7373 82.431 89.959 77.8287V82.9587C89.959 91.6354 85.3475 97.2299 78.2717 97.2299C73.791 97.2299 69.9824 95.0343 67.5926 91.1076L64.5121 94.0632C67.3126 98.6654 72.2601 101.157 78.2717 101.157C87.7559 101.157 94.029 93.831 94.029 83.1065V47.7454H94.0103C94.0103 47.471 94.029 47.2176 94.029 46.9221V11.5821H90.351L89.9403 18.3165ZM67.2939 29.8643C67.2939 20.6599 71.644 14.601 78.3837 14.601C85.2542 14.601 89.8656 20.871 89.8656 29.9276C89.8656 39.0687 85.1982 45.191 78.3091 45.191C71.6253 45.191 67.2939 39.1532 67.2939 29.8643ZM78.3091 81.3543C71.6253 81.3543 67.2939 75.3165 67.2939 66.0276C67.2939 56.8232 71.644 50.7643 78.3837 50.7643C85.2542 50.7643 89.8656 57.0343 89.8656 66.091C89.8656 75.2532 85.1982 81.3543 78.3091 81.3543ZM89.9403 46.7743C89.9403 49.0332 89.6229 51.081 89.0255 52.8754C87.6066 50.7432 85.6089 49.0754 83.2752 48.041C86.1877 46.8165 88.5401 44.5787 89.9403 41.6443V46.7743Z"
                fill="currentcolor"/>
            <path
                d="M9.69782 53.0443L8.95103 47.4499H0.811035V85.8932H10.4259V67.4632C10.4259 58.9976 13.3571 55.3876 18.6406 55.3876C20.2462 55.3876 21.7771 55.6832 22.7853 55.9154V47.0699C21.8518 46.6054 20.657 46.2465 19.182 46.2465C15.112 46.2465 11.8262 49.1176 9.69782 53.0443Z"
                fill="currentcolor"/>
            <path
                d="M56.3908 65.0565C56.3908 53.5087 50.1738 46.2465 40.6336 46.2465C30.4772 46.2465 23.7935 54.4799 23.7935 66.7876C23.7935 79.0321 30.2719 87.0965 40.8949 87.0965C47.504 87.0965 53.385 83.9298 56.3908 78.9476L50.3792 73.3532C48.4375 76.6676 45.0956 78.631 41.6231 78.631C37.1423 78.631 34.0805 75.3799 33.2777 69.4899H56.1294C56.2601 68.6032 56.3908 66.6399 56.3908 65.0565ZM33.2217 62.5654C34.0245 57.2032 36.6942 54.0999 40.5029 54.0999C44.3862 54.0999 46.7759 57.351 46.7759 62.5654H33.2217Z"
                fill="currentcolor"/>
            <path
                d="M289.856 0.857638C288.157 0.857638 286.794 2.41986 286.794 4.31986C286.794 6.24097 288.175 7.78208 289.856 7.78208C291.555 7.78208 292.918 6.21986 292.918 4.31986C292.936 2.39875 291.555 0.857638 289.856 0.857638Z"
                fill="currentcolor"/>
        </>
    ),
})
