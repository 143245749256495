import { Text, Flex, BoxProps, useDisclosure, useBreakpointValue } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import { FC, useContext } from 'react'

import { Tag } from '../'

import { getTotalFilters } from '@components'
import { FiltersContext, FiltersDispatchContext } from '@components/pages/projects/HeadFilter/FiltersProvider'

import FilterDrawer from './FilterDrawer'

type Filter = {
  label: string
  value: string
}

interface Props {
  filtersData: { id: string; title: string; filters: Filter[]; isRadio: boolean }[]
  totalResults?: number
  showResults?: boolean
}

const QueryFilter: FC<Props & BoxProps> = ({ filtersData, totalResults = 0, showResults = true, ...rest }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { t } = useTranslation('common', { keyPrefix: 'queryFilter' })
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: 'md' })

  const state = useContext(FiltersContext)
  const dispatch = useContext(FiltersDispatchContext)
  const totalFilters = getTotalFilters(state.filters)
  const formattedTotalResults = new Intl.NumberFormat('fr-FR', { maximumFractionDigits: 0 }).format(totalResults)

  return (
    <Flex align="center" width="100%" justify="space-between" {...rest}>
      <Flex align="center">
        <Tag
          title={t(isMobile ? 'filters_and_sort' : 'filters', { count: totalFilters })}
          onClick={onOpen}
          round
          {...(totalFilters && { onClear: () => dispatch({ type: 'clear' }) })}
          bgColor="dark_green"
          textColor="white"
          textSize="sm"
        />
        {showResults && (
          <Text size="xs" ml="4">
            {t('results_other', { var: formattedTotalResults })}
          </Text>
        )}
      </Flex>

      <FilterDrawer filtersData={filtersData} isOpen={isOpen} onClose={onClose} />
    </Flex>
  )
}

export default QueryFilter
