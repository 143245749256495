import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react'
import dynamic from 'next/dynamic'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import React, { useContext, useEffect, useState } from 'react'

import { BannerType } from '@miimosa/common'
import { usePlatform } from '@miimosa/design-system'
import { Logo2 as Logo } from '@miimosa/design-system/components/icons'
import Burger from '@miimosa/design-system/components/icons/Burger'
import UserContext from '@miimosa/design-system/components/UserContext'

import { PlatformSelect } from '@components/Footer/components'

import DesktopNav from './DesktopNav'
import EventBanner from './EventBanner'
import MobileNav from './MobileNav'

const DynamicHeaderAvatar = dynamic(() => import('./avatar'), { ssr: false })

const Header = ({ bgOpacity }: { bgOpacity: number }) => {
  const { query, pathname } = useRouter()
  const { platform } = usePlatform()

  const { isOpen, onClose, onToggle } = useDisclosure()
  const isMobile = useBreakpointValue({ base: true, lg: false }, { fallback: 'lg' })

  const user = useContext(UserContext)

  useEffect(() => onClose(), [onClose, query])

  const [banner, setBanner] = useState<undefined | BannerType>(undefined)
  const onHome = pathname == '/' || pathname == '/fr' || pathname == '/be'

  useEffect(() => {
    const getBanner = () => {
      try {
        const response = fetch(`/nextapi/users/banner?platform=${encodeURIComponent(`mii_${platform}`)}`)
        response
          .then((result) => {
            if (result.ok == true) {
              return result.json()
            }
          })
          .then((res) => {
            if (res) setBanner(res)
          })
      } catch {}
    }

    getBanner()
  }, [platform])

  const baseHeaderHeight = 72
  const smallHeaderHeight = banner && onHome ? `calc(${baseHeaderHeight}px + 32px)` : `${baseHeaderHeight}px`

  return (
    <Box
      id="top"
      bgColor={isMobile && isOpen ? 'dark_green' : 'rgb(255 255 255/var(--header-bg-opacity))'}
      position="sticky"
      top="0"
      left="0"
      right="0"
      zIndex="1002"
      as="nav"
      sx={{
        '--header-bg-opacity': `${bgOpacity}`,
      }}
    >
      {banner && onHome && <EventBanner text={banner.description} linkUrl={banner.external_url} h="32px" />}
      <Flex
        height={`${baseHeaderHeight}px`}
        justifyContent={{ base: 'space-between', md: 'inherit' }}
        px={{ base: 0, md: '60px' }}
        alignItems="center"
        position="relative"
      >
        <Box
          mx={{ base: 4, lg: 0 }}
          _focus={{
            outline: 0,
            boxShadow: 'none',
          }}
        >
          <NextLink href={'/'} aria-label="MiiMOSA home">
            <Logo color={isMobile && isOpen ? 'white' : 'dark_green'} h="26px" w="auto" />
          </NextLink>
        </Box>
        <Flex height="100%" width="100%" alignItems="center" justify="flex-end" position={{ lg: 'relative' }}>
          <DesktopNav flex="1 1 0%" display={{ base: 'none', lg: 'flex' }} />

          {isMobile && (
            <>
              <PlatformSelect isMobile={true} mobileNavIsOpen={isOpen} />
              <Button
                display={{ lg: 'none' }}
                onClick={onToggle}
                aria-label={'Toggle Navigation'}
                variant="ghost"
                size="iconMD"
                mr="8px"
              >
                <Burger isOpen={isOpen} color={isOpen ? 'white' : 'dark_green'} />
              </Button>
            </>
          )}
          <DynamicHeaderAvatar user={user} display={{ base: 'none', lg: 'flex' }} />
          {!isMobile && <PlatformSelect />}
          {isMobile && isOpen && (
            <Drawer placement="right" onClose={onClose} isOpen={isOpen} size="full">
              <DrawerOverlay marginTop={smallHeaderHeight} />
              <DrawerContent marginTop={smallHeaderHeight} maxHeight={`calc(100% - ${smallHeaderHeight})`}>
                <DrawerBody padding="0">
                  <MobileNav user={user} />
                </DrawerBody>
              </DrawerContent>
            </Drawer>
          )}
        </Flex>
      </Flex>
    </Box>
  )
}

export default Header
