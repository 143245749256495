import { Flex, Button, Text } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'

import type { FrontLabelGroupType } from '@miimosa/common'

interface ButtonProps {
  slug: string
  value: string
}

const ImpactButton = ({ slug, value }: ButtonProps) => {
  const { push } = useRouter()

  return (
    <Button size="xs" variant="secondary" onClick={() => push(`/projects?impact=${slug}`)}>
      <Text size="sm" fontWeight="normal">
        {value}
      </Text>
    </Button>
  )
}

interface Props {
  labels: FrontLabelGroupType[]
}

const ImpactsList = ({ labels }: Props) => {
  const { t } = useTranslation('project')

  return (
    <Flex direction="column" w="full" rowGap={4} mb="20px">
      <Text size="sm">{t('by_supporting_project')}</Text>
      <Flex direction={{ base: 'column', lg: 'row' }} columnGap={2} rowGap={2}>
        {labels.map(
          (label_group) =>
            label_group.slug == 'impact_goals' &&
            label_group.labels
              .filter(
                (e) =>
                  ![
                    'soil_regeneration',
                    'carbone_footprint',
                    'animal_wellbeing',
                    'energy_production',
                    'food_autonomy',
                  ].includes(e.slug)
              )
              .map(({ slug, value }) => <ImpactButton key={slug} slug={slug} value={value} />)
        )}
      </Flex>
    </Flex>
  )
}
export default ImpactsList
