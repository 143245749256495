import { Text, Flex, IconButton, useBreakpointValue } from '@chakra-ui/react'
import { captureException } from '@sentry/nextjs'
import { useTranslation } from 'next-i18next'
import { useEffect, useState } from 'react'

import { buildV2ImagePath } from '@miimosa/common'
import { Icon, usePrevious } from '@miimosa/design-system'

import { Image } from '@components'

interface CartMenuItemProps {
  cartId: number
  imageFileName?: string | null
  id: number
  amount: number
  title: string
  quantity: number
  addToCart: ({ cart_id, reward_id, quantity }: { cart_id: number; reward_id: number; quantity: number }) => void
}

const CartRewardItem = ({ cartId, imageFileName, id, amount, title, quantity, addToCart }: CartMenuItemProps) => {
  const { t } = useTranslation('common')
  const [newQuantity, setNewQuantity] = useState<number>(quantity)
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: 'md' })
  const deleteItem = () => addToCart({ cart_id: cartId, reward_id: id, quantity: 0 })
  const price = isMobile ? `${amount} €` : `${amount * newQuantity} €`

  // we use previous quantity value to avoid undesired calls
  const previousQuantity = usePrevious(quantity)

  // TODO Refacto performance here
  useEffect(() => {
    if (previousQuantity && newQuantity !== previousQuantity) {
      const handleAddToCart = async () => {
        try {
          addToCart({ cart_id: cartId, reward_id: id, quantity: newQuantity })
        } catch (err) {
          captureException(err)
        }
      }
      handleAddToCart()
    }
  }, [newQuantity, cartId, id, addToCart, previousQuantity])

  return (
    <Flex align="flex-start" justify="space-between" mb="4" borderTop="solid 1px" borderColor="light_gray" pt="4">
      <Flex bgColor="dark_green" display={{ base: 'none', md: 'block' }} position="relative" width="64px" height="64px">
        {imageFileName && (
          <Image
            src={buildV2ImagePath({
              kind: 'reward',
              filename: imageFileName,
              id,
              size: 'original',
            })}
            alt=""
            layout="fill"
            objectFit="cover"
          />
        )}
      </Flex>
      <Flex direction="column" width={{ base: '100%', md: '412px' }} ml={{ base: 0, md: '6' }}>
        <Text size={['md', 'lg']} textOverflow="ellipsis" maxW={{ base: '100%', md: 'auto' }}>
          {title}
        </Text>
        {isMobile && (
          <Text size="sm" mt="2">
            {t('unit_price')} {price}
          </Text>
        )}
        <Flex align="center" justifyContent="space-between" mt="2">
          <Flex align="center">
            <Text size={['sm', 'md']}>{t('quantity')}</Text>
            <Flex
              bgColor="light_gray"
              justify={{ base: 'space-between', md: 'flex-start' }}
              align="center"
              ml="4"
              p="1"
              borderRadius="100px"
              mr={{ base: 4, md: 0 }}
            >
              <IconButton
                aria-label="subtract"
                isDisabled={quantity === 1 || newQuantity === 1}
                onClick={() => setNewQuantity(newQuantity - 1)}
              >
                <Icon
                  cursor="pointer"
                  name="Minus"
                  bgColor="dark_green"
                  color="white"
                  size={isMobile ? '6xs' : '5xs'}
                />
              </IconButton>
              <Text mx="4">{newQuantity}</Text>
              <IconButton aria-label="add" onClick={() => setNewQuantity(newQuantity + 1)}>
                <Icon cursor="pointer" name="Add" bgColor="dark_green" color="white" size={isMobile ? '6xs' : '5xs'} />
              </IconButton>
            </Flex>
          </Flex>
          {isMobile && (
            <IconButton aria-label="delete" mr="6" ml="4" onClick={deleteItem}>
              <Icon name="Garbage" size="4xs" color="red" />
            </IconButton>
          )}
        </Flex>
      </Flex>
      {!isMobile && (
        <Flex flex="1 1 0%">
          <Text size="2xl">{price}</Text>
          <IconButton aria-label="delete" ml="4" onClick={deleteItem}>
            <Icon name="Garbage" size="4xs" color="red" />
          </IconButton>
        </Flex>
      )}
    </Flex>
  )
}

export default CartRewardItem
