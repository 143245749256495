import {
  Text,
  Flex,
  Checkbox,
  Box,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  Button,
  Drawer,
  AccordionIcon,
  DrawerOverlay,
  Accordion,
  AccordionItem,
  AccordionPanel,
  AccordionButton,
  RadioGroup,
  Radio,
} from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import { useContext } from 'react'

import { LeftArrow } from '@miimosa/design-system'

import { FiltersContext, FiltersDispatchContext } from '@components/pages/projects/HeadFilter/FiltersProvider'

type Filter = {
  label: string
  value: string
}

const FilterListItem = ({
  id,
  value,
  isSelected,
  label,
}: {
  id: string
  isSelected: boolean
  value: string
  label: string
}) => {
  const dispatch = useContext(FiltersDispatchContext)

  return (
    <Button
      mt="3"
      variant="ghost"
      onClick={() => dispatch({ type: isSelected ? 'remove' : 'add', categoryId: id, newFilter: value })}
    >
      <Checkbox
        pointerEvents="none"
        onClick={() => dispatch({ type: isSelected ? 'remove' : 'add', categoryId: id, newFilter: value })}
        isChecked={isSelected}
        size="sm"
        colorScheme="checkbox"
        mr="2"
      />
      <Text size="sm">{label}</Text>
    </Button>
  )
}

const FilterListItemRadio = ({ id, value, label }: { id: string; value: string; label: string }) => {
  const dispatch = useContext(FiltersDispatchContext)

  return (
    <Flex mt="3">
      <Radio
        colorScheme="radio"
        value={value}
        onClick={() => dispatch({ type: 'addRadio', categoryId: id, newFilter: value })}
        mr="2"
      >
        <Text size="sm" onClick={() => dispatch({ type: 'addRadio', categoryId: id, newFilter: value })}>
          {label}
        </Text>
      </Radio>
    </Flex>
  )
}

const FilterDrawer = ({
  filtersData,
  isOpen,
  onClose,
}: {
  filtersData: { id: string; title: string; filters: Filter[]; isRadio: boolean }[]
  isOpen: boolean
  onClose: () => void
}) => {
  const { t } = useTranslation('common', { keyPrefix: 'queryFilter' })
  const state = useContext(FiltersContext)

  return (
    <Drawer isOpen={isOpen} size="md" placement="left" onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader>
          <Button variant="ghost" onClick={onClose}>
            <LeftArrow color="black" mr="2" width="12px" />
            <Text size="sm">{t('return')}</Text>
          </Button>
        </DrawerHeader>
        <DrawerBody mt="8">
          <Flex direction="column" rowGap={6}>
            <Accordion defaultIndex={0} allowToggle>
              {filtersData.map(({ id, title, filters: filtersItems, isRadio }) => {
                return (
                  <AccordionItem key={id} border="none" borderBottom="solid 1px black" borderWidth="1px">
                    <AccordionButton
                      px={0}
                      _hover={{ bgColor: 'transparent' }}
                      _expanded={{ borderBottom: 'solid 1px black' }}
                    >
                      <Box flex="1" textAlign="left">
                        <Text size="sm">{title}</Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4} px={0} pl="2px">
                      <Flex direction="column" align="flex-start">
                        {isRadio ? (
                          <>
                            <RadioGroup value={state.filters[id]?.[0]}>
                              {filtersItems.map(({ value, label }, index) => {
                                return <FilterListItemRadio id={id} value={value} label={label} key={index} />
                              })}
                            </RadioGroup>
                          </>
                        ) : (
                          filtersItems.map(({ value, label }, index) => {
                            const categoryFilters = state.filters[id]
                            const isSelected = categoryFilters?.includes(value)

                            return (
                              <FilterListItem id={id} isSelected={isSelected} value={value} label={label} key={index} />
                            )
                          })
                        )}
                      </Flex>
                    </AccordionPanel>
                  </AccordionItem>
                )
              })}
            </Accordion>
          </Flex>
          {/* <Button size="md" variant="primary" bgColor="dark_green" mt={4} onClick={onPushFilters}>
            <Text size="md">{t('validate')}</Text>
          </Button> */}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export default FilterDrawer
