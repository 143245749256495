import { Flex, FlexProps, Heading, Text } from '@chakra-ui/react'
import NextImage from 'next/image'
import { FC } from 'react'

interface Props {
  imageUrl: string
  title: string
  description: string
}

const Hero: FC<Props & FlexProps> = ({ imageUrl, title, description, ...rest }) => {
  return (
    <Flex direction="column" {...rest}>
      <Flex position="relative" width="100%" borderRadius="4px" overflow="hidden" height="536px">
        <NextImage
          src={imageUrl}
          fill
          style={{ objectFit: 'cover' }}
          alt="olive background"
          priority
          sizes="(max-width: 1400px) 100vw"
        />
      </Flex>
      <Flex
        direction={{ base: 'column', md: 'row' }}
        mt={10}
        align="flex-start"
        justifyContent={{ md: 'space-between' }}
      >
        <Heading size="xl" maxWidth={{ base: '414px', md: '625px' }}>
          {title}
        </Heading>
        <Text size="md" mt={{ base: 10, md: 0 }} textAlign="justify" maxWidth={{ base: '414px', md: '36%' }}>
          {description}
        </Text>
      </Flex>
    </Flex>
  )
}

export default Hero
