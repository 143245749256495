import { Flex, Heading, Box, Text, Button } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useContext } from 'react'

import type { CommentsType, CommentType } from '@miimosa/common/types'
import { UserContext } from '@miimosa/design-system'

import { Comment, CommentArea } from '@components/projects/project/comments'

const Comments = ({ slug, comments }: { slug: string; comments?: CommentsType }) => {
  const { t } = useTranslation('project', { keyPrefix: 'comments' })
  const { push, asPath } = useRouter()

  const user = useContext(UserContext)

  if (!user) {
    return (
      <Flex
        direction="column"
        w="full"
        minHeight="300px"
        justifyContent="center"
        bg="white"
        p={8}
        border="solid 1px"
        borderColor="light_gray"
      >
        <Flex direction="column" alignItems="center" w="full" rowGap={6} mt={6} mb={{ base: 6, md: 0 }}>
          <Text size="xl" textAlign="center">
            Pour avoir accès à ces informations, veuillez vous connecter.
          </Text>
          <Button as="a" href={`/authenticate?return_url=${asPath}`} variant="primary" size="md">
            Se connecter / S&apos;inscrire
          </Button>
        </Flex>
      </Flex>
    )
  }

  return (
    <Flex direction="column" w="full" minHeight="300px" bg="white" p={8} border="solid 1px" borderColor="light_gray">
      {comments?.can_comment && (
        <>
          <Heading size="md" mb="15px">
            {t('add')}
          </Heading>
          <CommentArea
            mb="15px"
            projectId={slug}
            onCommentsChange={() => push(`/projects/${slug}/comments`, undefined, { scroll: false })}
            commentId={null}
          />
        </>
      )}
      {comments?.comments && comments?.comments?.length > 0 ? (
        comments.comments.map((comment: CommentType, i) => (
          <Comment
            comment={comment}
            key={`comment_${i}`}
            isReply={false}
            onCommentsChange={() => push(`/projects/${slug}/comments`, undefined, { scroll: false })}
            comments={comments}
            projectId={slug}
          />
        ))
      ) : (
        <Box w="full" minH="300px" p={8} bg="white">
          {t('none')}
        </Box>
      )}
    </Flex>
  )
}

export default Comments
