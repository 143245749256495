import { Flex, Text, Button, Divider, useBreakpointValue } from '@chakra-ui/react'

import type { FrontAssetType } from '@miimosa/common'

interface Props {
  documents?: FrontAssetType[]
}

const Documents = ({ documents }: Props) => {
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: 'md' })
  const url = `${
    process.env.NEXT_PUBLIC_BUCKET_ASSET_URL ?? 'https://mii-bkt-project-assets-prod.s3.eu-central-1.amazonaws.com'
  }`

  if (!documents) {
    return (
      <Flex minHeight="300px" bg="white" w="full" p={8} border="solid 1px" borderColor="light_gray">
        Aucun documents disponible pour le moment.
      </Flex>
    )
  }

  return (
    <Flex
      w="full"
      direction="column"
      rowGap={3}
      minH={'300px'}
      p={8}
      bg="white"
      border="solid 1px"
      borderColor="light_gray"
    >
      {documents.map((document, i) => (
        <Flex
          key={`${document.file_key}_${i}`}
          w="full"
          direction={{ base: 'column', md: 'row' }}
          alignItems="center"
          columnGap={3}
          rowGap={3}
        >
          <Text w={{ base: 'full', md: '300px' }} textAlign="center">
            {document.name}
          </Text>
          <Button
            variant="primary"
            size="md"
            onClick={() => window.open(`${url}/${document.file_key}`, '_blank', 'noreferrer')}
          >
            Voir le document
          </Button>
          {isMobile && <Divider />}
        </Flex>
      ))}
    </Flex>
  )
}

export default Documents
