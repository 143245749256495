import { Flex, Heading } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'

import { FrontLendingProductKindType } from '@miimosa/common'
import type { FrontCollectGoalType, FrontCollectTypeType, FrontTermFrequencyType } from '@miimosa/common/types'

import { DotColor } from '@components/Header/DesktopMenuItem'

interface Props {
  collectType: FrontCollectTypeType
  goalType: FrontCollectGoalType
  termQuantity?: number | null
  termFrequency?: FrontTermFrequencyType | null
  interestsRatio?: number | null
  lendingProductKindType: FrontLendingProductKindType
  rewardsLength: number
}

const HeadingWithDot = ({
  collectType,
  goalType,
  termQuantity,
  termFrequency,
  interestsRatio,
  lendingProductKindType,
  rewardsLength,
}: Props) => {
  const { t } = useTranslation('project', { keyPrefix: 'collect' })
  const isDonation = collectType === 'donation'

  return (
    <Flex direction="row" alignItems="center">
      <DotColor dotColor={isDonation ? 'green' : 'blue'} />
      {/* Donation and goal type == quantity */}
      {isDonation && goalType == 'quantity' && <Heading>Prévente</Heading>}
      {/* Donation and goal type == amount */}
      {isDonation &&
        goalType != 'quantity' &&
        (rewardsLength > 0 ? <Heading>Don avec contrepartie</Heading> : <Heading>Don sans contrepartie</Heading>)}
      {/* Lending */}
      {!isDonation && (
        <Heading>{`${t(`lending.title.${lendingProductKindType}`)} - ${termQuantity} ${t(
          `lending.period_${termFrequency}`
        )} - ${((interestsRatio || 0) * 100).toFixed(2).replace(/\./g, ',')}%`}</Heading>
      )}
    </Flex>
  )
}

export default HeadingWithDot
