import '../styles/globals.css'
import '../styles/ck.css'
import { ChakraProvider, Container, Flex, Heading, Button } from '@chakra-ui/react'
import * as Sentry from '@sentry/nextjs'
import { appWithTranslation, useTranslation } from 'next-i18next'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import NextLink from 'next/link'
import NextNProgress from 'nextjs-progressbar'
import { useEffect } from 'react'
import TagManager from 'react-gtm-module'
import { register } from 'timeago.js'

import { FunnelAPI } from '@miimosa/api'
import { DOMAIN_TYPE, s3ImageURL } from '@miimosa/common'
import { Image } from '@miimosa/design-system'
import UserContext from '@miimosa/design-system/components/UserContext'
import { usePlatform, useV2User } from '@miimosa/design-system/lib/hooks'
import { timeagoFR } from '@miimosa/design-system/lib/i18n/timeago'

import '@styles/ck.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'rc-pagination/assets/index.css'
import { theme } from '../themes'

import Layout from '@components/Layout'
import FunnelProvider from '@components/pages/funnel/FunnelProvider'
import WalletProvider from '@components/WalletProvider'

function FallbackComponent() {
  const { t } = useTranslation('common')

  return (
    <Container display="flex" flexDirection="column" alignItems="center" width="100%" maxW="100%" padding="0">
      <Container
        display="flex"
        flexDirection="column"
        alignItems="center"
        px={{ base: 5, md: 'inherit' }}
        py={{ base: 12, md: 16 }}
        minW="min(90%, 1320px)"
        maxW="min(90%, 1320px)"
      >
        <Flex direction="column" w="100%" alignItems="center" rowGap="25px">
          <Flex maxH="400px" mb={5} position="relative" overflow="hidden" h="400px" w="100%">
            <Image src={s3ImageURL('bg-404.png')} alt="404 image" style={{ objectFit: 'contain' }} fill priority />
          </Flex>
          <Heading fontSize="6rem" lineHeight="5rem" fontFamily="AcidGrotesk" margin={0}>
            500
          </Heading>
          <Heading size="sm" textAlign="center" fontFamily="AcidGrotesk">
            {t('page_error')}
          </Heading>
          <NextLink href="/" passHref>
            <Button
              h="48px"
              padding="13px 24px"
              fontFamily="AcidGrotesk"
              borderRadius="40px"
              width="fit-content"
              border="0"
              outline="0"
              _focus={{
                outline: 0,
                boxShadow: 0,
              }}
              bg="#003F2C"
              color="white"
              _hover={{
                bg: '#002016',
                cursor: 'pointer',
              }}
            >
              {t('back_home')}
            </Button>
          </NextLink>
        </Flex>
      </Container>
    </Container>
  )
}

// register your locale with timeago
register('fr', timeagoFR)

function MyApp({ Component, pageProps }: AppProps & { domainName: DOMAIN_TYPE }) {
  const { platform } = usePlatform()
  const user = useV2User(`mii_${platform}`)

  useEffect(() => {
    if (platform) {
      TagManager.dataLayer({
        dataLayer: {
          'ecommerce.purchase.actionField.platformCountryName': platform,
        },
      })
    }
  }, [platform])

  useEffect(() => {
    if (user && user.is_lender) {
      let finalUser = user
      const funnelAPI = new FunnelAPI()
      const response = funnelAPI.fetchPSFPFunnel({})
      response.then((result) => {
        if (result.kind == 'success') {
          finalUser = {
            ...finalUser,
            ...{ is_completed: result.data['is_completed'], is_informed: result.data['is_informed'] },
          }
        }
        TagManager.dataLayer({
          dataLayer: {
            user: finalUser,
          },
        })
      })
    }
  }, [user])

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1" />
        <link rel="shortcut icon" href="/favicon.ico" />
        <link rel="icon" type="image/png" href="/favicon.png" sizes="64x64" />
        <title>MiiMOSA</title>
        <meta
          name="description"
          content="MiiMOSA est le 1er site de financement participatif de l'agriculture et de l'alimentation. Réalisez votre projet grâce au soutien de belles âmes."
        />
        <meta property="og:title" content="MiiMOSA" key="title" />
        <meta
          property="og:description"
          content="MiiMOSA est le 1er site de financement participatif de l'agriculture et de l'alimentation. Réalisez votre projet grâce au soutien de belles âmes."
          key="description"
        />
        <meta property="og:image" content={s3ImageURL('home/hero-V4-home-4.png')} key="image" />
      </Head>
      <Sentry.ErrorBoundary fallback={FallbackComponent()} showDialog={false}>
        <UserContext.Provider value={user}>
          <ChakraProvider theme={theme}>
            <NextNProgress
              color={theme.colors.green}
              startPosition={0.3}
              stopDelayMs={200}
              height={4}
              showOnShallow={true}
            />
            <FunnelProvider>
              {user ? (
                <WalletProvider>
                  <Layout>{<Component {...pageProps} />}</Layout>
                </WalletProvider>
              ) : (
                <Layout>{<Component {...pageProps} />}</Layout>
              )}
            </FunnelProvider>
          </ChakraProvider>
        </UserContext.Provider>
      </Sentry.ErrorBoundary>
    </>
  )
}

// withTranslation make the t params available in the components/page
// this is a HOC (higher order component)
export default appWithTranslation(MyApp)
