import { Link as OutsideLink, LinkProps } from '@chakra-ui/react'

import { Link as InsideLink } from '@miimosa/design-system/components'

interface Props extends LinkProps {
  href?: string
  route?: string
}

const Link = ({ href, route, ...props }: Props) => {
  const params = { ...props, href: route ?? href ?? '' }
  if (route) {
    return <InsideLink {...params} />
  }

  if (href) {
    return <OutsideLink {...params} />
  }

  return null
}

export default Link
