import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'Info',
  viewBox: '0 0 24 24',
  path: (
    <>
      <path
        d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7363 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM12 21C10.22 21 8.47991 20.4722 6.99987 19.4832C5.51983 18.4943 4.36628 17.0887 3.68509 15.4442C3.0039 13.7996 2.82567 11.99 3.17294 10.2442C3.5202 8.49836 4.37737 6.89471 5.63604 5.63604C6.89472 4.37737 8.49836 3.5202 10.2442 3.17293C11.99 2.82567 13.7996 3.0039 15.4442 3.68508C17.0887 4.36627 18.4943 5.51983 19.4832 6.99987C20.4722 8.47991 21 10.22 21 12C21 14.3869 20.0518 16.6761 18.364 18.364C16.6761 20.0518 14.387 21 12 21Z"
        fill="currentColor"
      />
      <path
        d="M12 11.44C11.8674 11.44 11.7402 11.4927 11.6464 11.5864C11.5527 11.6802 11.5 11.8074 11.5 11.94V16.27C11.5026 16.4018 11.5561 16.5275 11.6493 16.6207C11.7425 16.7139 11.8682 16.7674 12 16.77C12.1326 16.77 12.2598 16.7173 12.3536 16.6236C12.4473 16.5298 12.5 16.4026 12.5 16.27V11.94C12.5 11.8074 12.4473 11.6802 12.3536 11.5864C12.2598 11.4927 12.1326 11.44 12 11.44Z"
        fill="currentColor"
      />
      <path
        d="M11.98 9.51999C12.4274 9.51999 12.79 9.15734 12.79 8.70999C12.79 8.26264 12.4274 7.89999 11.98 7.89999C11.5327 7.89999 11.17 8.26264 11.17 8.70999C11.17 9.15734 11.5327 9.51999 11.98 9.51999Z"
        fill="currentColor"
      />
    </>
  ),
})
