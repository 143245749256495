import { Flex, BoxProps, Text } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'

import Externals from './Externals'

const DisclaimerWExternals = (props: BoxProps) => {
  const { t } = useTranslation()
  return (
    <Flex direction="column" {...props}>
      <Text whiteSpace="pre-line" textAlign="justify" size="xs" color="white">
        {t('footer.disclaimer')}
      </Text>
      <Externals
        mt={8}
        alignItems="center"
        justifyContent={{ base: undefined, md: 'space-between' }}
        columnGap={{ base: 8, md: 0 }}
      />
    </Flex>
  )
}

export default DisclaimerWExternals
