import { Box, BoxProps, Heading, Link, Text, Flex, IconButton, useBreakpointValue } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import { useEffect, useState } from 'react'

import ChevronLeft from '@miimosa/design-system/components/icons/ChevronLeft'
import ChevronRight from '@miimosa/design-system/components/icons/ChevronRight'

interface TestimonyData {
  name: string
  job: string
  description: string
  href?: string
  label?: string
}

export const Testimony = ({ name, job, description, href, label, ...props }: TestimonyData & BoxProps) => {
  const { t } = useTranslation('common')
  return (
    <Box textAlign="left" {...props}>
      <Heading as="h3" size="md">
        {name}
      </Heading>
      <Text size="lg" mb={6}>
        {job}
      </Text>

      <Box position="relative" pl={2}>
        <Text
          mb={4}
          size="lg"
          _before={{
            content: '"\'\'"',
            position: 'absolute',
            top: '60px',
            left: '-5px',
            lineHeight: '0px',
            color: 'gray.200',
            fontSize: '160px',
            zIndex: '-1',
            textColor: 'main_gray_lighten',
          }}
        >
          {description}
        </Text>

        {href && (
          <Link variant="primaryWithoutUnderline" href={href}>
            {label ?? t('view_project')}
          </Link>
        )}
      </Box>
    </Box>
  )
}

interface CarouselProps extends BoxProps {
  data: TestimonyData[]
}

const Carousel = ({ data, ...props }: CarouselProps) => {
  const [isLeftDisabled, setLeftDisabled] = useState(true)
  const [isRightDisabled, setRightDisabled] = useState(false)
  const [pageCount, setPageCount] = useState(1)
  const [page, setPage] = useState(0)
  const itemsPerPage = useBreakpointValue({ base: 1, md: 2 }, { fallback: 'md' })
  const { t } = useTranslation('manifest', { keyPrefix: 'testimony' })

  useEffect(() => {
    const pageCount = Math.ceil(data.length / (itemsPerPage ?? 2))
    setPageCount(pageCount)
    setLeftDisabled(page == 0)
    setRightDisabled(page == pageCount - 1)
  }, [page, itemsPerPage, data])

  const leftClick = () => setPage(Math.max(0, page - 1))
  const rightClick = () => setPage(Math.min(page + 1, pageCount - 1))

  return (
    <Flex position="relative" {...props} overflow="hidden" direction="column">
      <Heading as="h2" size="lg" mx="auto">
        {t('title')}
      </Heading>
      <Flex
        align="center"
        my={4}
        ml="auto"
        sx={{
          button: {
            w: 12,
            h: 12,
            p: 3,
            bg: 'dark_green',
            borderRadius: 'none',
            color: 'white',
          },
          'button svg': {
            w: 'full',
            h: 'full',
          },
        }}
      >
        <IconButton variant="square" aria-label="précédent" isDisabled={isLeftDisabled} onClick={leftClick}>
          <ChevronLeft />
        </IconButton>
        <IconButton variant="square" aria-label="suivant" isDisabled={isRightDisabled} onClick={rightClick}>
          <ChevronRight />
        </IconButton>
      </Flex>
      <Box
        as="ul"
        position="relative"
        float="left"
        overflow="hidden"
        w={[`calc(${pageCount} * 95%)`, `calc(${pageCount} * 100%)`]}
        mx="-1rem"
        transition="transform 0.5s"
        transform={[
          `translate(calc(-${page} * (${95 / pageCount}% + 1rem)),0)`,
          `translate(calc(-${page} * (${100 / pageCount}% + 2rem)),0)`,
        ]}
      >
        {data.map((d) => (
          <Box
            as="li"
            key={d.name}
            display="inline-block"
            position="relative"
            float="left"
            mx="1rem"
            w={[`calc(${95 / pageCount}% - 1rem)`, `calc(${50 / pageCount}% - 1rem)`]}
          >
            <Testimony {...d} />
          </Box>
        ))}
      </Box>
    </Flex>
  )
}

export default Carousel
