import { Button, Checkbox, Flex, Radio, RadioGroup, Text, UnorderedList, ListItem } from '@chakra-ui/react'
import { ErrorMessage } from '@hookform/error-message'
import { Dispatch, SetStateAction, useState } from 'react'
import { FieldPath, SubmitHandler, useForm } from 'react-hook-form'

import { Icon } from '@miimosa/design-system'
import { Leads } from '@miimosa/protocol'

import { HeadingIntro } from '@components/psfp'
import { formatData, updatePSFPFunnel } from '@helpers/psfp'
import previousPSFPFunnel from '@helpers/psfp/previous'

interface Props {
  funnelStep?: Leads.Step
  leadId: string
  setCurrentFunnel: Dispatch<SetStateAction<Leads.UpdateResponse>>
}

interface FormValues {
  INPUT_NAME_NATURAL_INCOME_THRESHOLD: boolean
  INPUT_NAME_NATURAL_FINANCIAL_ASSETS_THRESHOLD: boolean
  INPUT_NAME_NATURAL_FINANCIAL_JOB: boolean
  INPUT_NAME_NATURAL_EXECUTIVE_JOB: boolean
  INPUT_NAME_NATURAL_TRANSACTIONS_FREQUENCY_THRESHOLD: boolean
  INPUT_NAME_LEGAL_THRESHOLDS: boolean
  correct: boolean
}

// TODO use locals, factor this with existing funnel.json entries ?
const requiredMsg = 'Ce champ est requis'

const NaturalOrLegalWithQuestions = ({ funnelStep, leadId, setCurrentFunnel }: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>()
  const [checked, setChecked] = useState(false)

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    const formattedData = formatData({ data: data, leadId: leadId, funnelStep: funnelStep })
    const response = updatePSFPFunnel(formattedData)

    response.then((newData) => {
      if (newData) {
        setCurrentFunnel(newData)
      }
    })
  }

  const onPrevious = () => {
    const response = previousPSFPFunnel({
      funnel_type: 'FUNNEL_TYPE_PSFP',
      lead_id: leadId,
    })

    response.then((newData) => {
      if (newData) {
        const createResp: Leads.UpdateResponse = {
          step: newData.step,
          progress: newData.progress,
          lead_id: leadId,
          salesforce_ref: '',
          outputs: [],
        }

        setCurrentFunnel(createResp)
      }
    })
  }

  if (!funnelStep) return null

  const bulletList = (
    <UnorderedList paddingLeft={2}>
      <ListItem>capitaux propres d&apos;au moins 100 000€</ListItem>
      <ListItem>chiffre d’affaires d&apos;au moins 2 000 000€</ListItem>
      <ListItem>taille de son bilan d’au moins 1 000 000€</ListItem>
    </UnorderedList>
  )

  const inputs = {
    INPUT_NAME_NATURAL_INCOME_THRESHOLD: (
      <Text as="b" size={{ base: 'sm', md: 'md' }}>
        Percevez-vous un revenu annuel brut d’au moins 60 000€ ?
      </Text>
    ),
    INPUT_NAME_NATURAL_FINANCIAL_ASSETS_THRESHOLD: (
      <Text as="b" size={{ base: 'sm', md: 'md' }}>
        Possédez-vous des actifs financiers d’une valeur de 100 000€ ou plus ? (Résidence principale exclue)
      </Text>
    ),
    INPUT_NAME_NATURAL_TRANSACTIONS_FREQUENCY_THRESHOLD: (
      <Text as="b" size={{ base: 'sm', md: 'md' }}>
        Au cours de la dernière année, avez-vous effectué 10 transactions par trimestre sur les marchés de capitaux ?
      </Text>
    ),
    INPUT_NAME_NATURAL_FINANCIAL_JOB: (
      <Text as="b" size={{ base: 'sm', md: 'md' }}>
        Avez-vous travaillé au moins une année dans le secteur financier ?
      </Text>
    ),
    INPUT_NAME_NATURAL_EXECUTIVE_JOB: (
      <Flex direction="column" rowGap={2}>
        <Text as="b" size={{ base: 'sm', md: 'md' }}>
          Êtes-vous cadre depuis 12 mois dans une entreprise répondant à au moins deux de ces critères :{' '}
        </Text>
        {bulletList}
      </Flex>
    ),
    INPUT_NAME_LEGAL_THRESHOLDS: (
      <Flex direction="column" rowGap={2}>
        <Text as="b" size={{ base: 'sm', md: 'md' }}>
          L’entreprise pour le compte de laquelle vous investissez répond-elle à au moins UN des critères suivants ?{' '}
        </Text>{' '}
        {bulletList}
      </Flex>
    ),
  }

  // TODO factor "Ces quelques questions" with other steps
  return (
    <Flex direction="column" alignItems="center" rowGap={10} as="form" w="full" justifyContent="space-between">
      <Flex direction="column" w="full" rowGap={8}>
        <HeadingIntro
          texts={['Investisseur averti ou non-averti ?']}
          subTexts={[
            <Flex direction="column" key="subtext1" rowGap={3}>
              <Text size="md" color="dark_green" fontWeight="700">
                Ces quelques questions vont nous permettre de déterminer votre qualification.
              </Text>
              <Flex direction="row" alignItems="center" columnGap={2}>
                <Icon size="4xs" name="Time" color="dark_green" />
                <Text color="dark_green">Y répondre vous prendra quelques secondes</Text>
              </Flex>
            </Flex>,
          ]}
        />
        <Flex direction="column" rowGap={6} h="full" justifyContent="space-between">
          {funnelStep.input_descriptors.map((input, i: number) => (
            <Flex direction="column" key={i} rowGap={3} columnGap={2}>
              <>{inputs[input.name as keyof typeof inputs]}</>
              <RadioGroup
                name={input.name}
                {...(input.default_value?.boolean != undefined && {
                  defaultValue: String(input.default_value.boolean),
                })}
              >
                <Flex columnGap={14} direction="row">
                  <Radio
                    value="true"
                    {...register(input.name as FieldPath<FormValues>, { required: requiredMsg })}
                    colorScheme="radio"
                  >
                    Oui
                  </Radio>
                  <Radio
                    value="false"
                    {...register(input.name as FieldPath<FormValues>, { required: requiredMsg })}
                    colorScheme="radio"
                  >
                    Non
                  </Radio>
                </Flex>
                <ErrorMessage errors={errors} name={input.name as keyof FormValues} as={<Text color="red" />} />
              </RadioGroup>
            </Flex>
          ))}
        </Flex>
        <Flex direction="column" w="full" justifyContent="center" bg="white" rowGap={6} alignItems="center">
          <Checkbox
            colorScheme="checkbox"
            {...register('correct', { required: requiredMsg, onChange: () => setChecked(!checked) })}
            display="flex"
          >
            Je déclare que les informations renseignées ci-dessus sont exactes
          </Checkbox>
          <Flex direction="row" columnGap={8}>
            <Button variant="secondary" size="md" onClick={onPrevious}>
              Précédent
            </Button>
            <Button isDisabled={!checked} variant="primary" size="md" onClick={handleSubmit(onSubmit)}>
              Suivant
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default NaturalOrLegalWithQuestions
