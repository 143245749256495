import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'Lending',
  viewBox: '0 0 80 80',
  path: (
    <g>
      <path
        d="M25.76 52.64H8.48C8.24 52.64 8 52.88 8 53.12V71.52C8 71.76 8.24 72 8.48 72H25.76C26 72 26.24 71.76 26.24 71.52V53.12C26.32 52.88 26.08 52.64 25.76 52.64ZM24.72 70.4H9.6V54.24H24.72V70.4Z"
        fill="#2A2A2A"
      />
      <path
        d="M48.64 43.04H31.36C31.12 43.04 30.88 43.28 30.88 43.52V71.52C30.88 71.76 31.12 72 31.36 72H48.64C48.88 72 49.12 71.76 49.12 71.52V43.52C49.12 43.28 48.88 43.04 48.64 43.04ZM32.48 70.4V44.64H47.6V70.4H32.48Z"
        fill="#2A2A2A"
      />
      <path
        d="M71.52 8H54.24C54 8 53.76 8.24 53.76 8.48V71.52C53.76 71.76 54 72 54.24 72H71.52C71.76 72 72 71.76 72 71.52V8.48C72 8.24 71.76 8 71.52 8ZM70.4 70.4H55.28V9.6H70.4V70.4Z"
        fill="#2A2A2A"
      />
      <path
        d="M22.96 37.84C14.72 37.84 8.08 31.2 8.08 22.96C8.08 14.72 14.72 8.07998 22.96 8.07998C31.2 8.07998 37.84 14.72 37.84 22.96C37.84 31.12 31.12 37.84 22.96 37.84ZM22.96 9.67998C15.6 9.67998 9.68 15.6 9.68 22.96C9.68 30.32 15.6 36.24 22.96 36.24C30.32 36.24 36.24 30.32 36.24 22.96C36.24 15.6 30.24 9.67998 22.96 9.67998Z"
        fill="#2A2A2A"
      />
      <path
        d="M26.2399 17.52C25.5999 17.28 24.88 17.12 24.16 17.12C21.92 17.12 19.92 18.56 19.04 20.56H18C17.52 20.56 17.2 20.88 17.2 21.36C17.2 21.84 17.52 22.16 18 22.16H18.56C18.56 22.4 18.48 22.72 18.48 22.96C18.48 23.2 18.48 23.52 18.56 23.76H18C17.52 23.76 17.2 24.08 17.2 24.56C17.2 25.04 17.52 25.36 18 25.36H19.04C19.92 27.36 21.84 28.8 24.16 28.8C24.72 28.8 25.2799 28.72 25.8399 28.56C26.2399 28.4 26.48 28 26.32 27.52C26.16 27.12 25.76 26.88 25.28 27.04C24.88 27.2 24.48 27.2 24.08 27.2C22.72 27.2 21.52 26.48 20.8 25.36H22.48C22.96 25.36 23.28 25.04 23.28 24.56C23.28 24.08 22.96 23.76 22.48 23.76H20.16C20.08 23.52 20.08 23.2 20.08 22.96C20.08 22.72 20.08 22.4 20.16 22.16H22.48C22.96 22.16 23.28 21.84 23.28 21.36C23.28 20.88 22.96 20.56 22.48 20.56H20.8C21.52 19.44 22.72 18.72 24.08 18.72C24.56 18.72 25.0399 18.8 25.5199 19.04C25.9199 19.2 26.4 19.04 26.56 18.64C26.88 18.16 26.6399 17.68 26.2399 17.52Z"
        fill="#FF6C4A"
      />
    </g>
  ),
  defaultProps: {
    w: '80px',
    h: '80px',
  },
})
