import { BoxProps, Text, Flex } from '@chakra-ui/react'

interface HighlightedStat extends BoxProps {
  value: number
  text: string
}

const HighlightedStat = ({ value, text, ...props }: HighlightedStat) => {
  return (
    <Flex direction="column" maxW="350px" align="center" textAlign="center" {...props}>
      <Text size="xl"> {value.toLocaleString('fr')} </Text>
      <Text size="xl" maxW="256px">
        {text}
      </Text>
    </Flex>
  )
}

export default HighlightedStat
