import { Button, Flex, FlexProps, Textarea } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import { Dispatch, SetStateAction, useState } from 'react'

const PostComment = async (
  projectId: string,
  content: string,
  commentId: number | null,
  onCommentsChange: () => void,
  setContent: Dispatch<SetStateAction<string>>
) => {
  const res = await fetch(`/nextapi/projects/${projectId}/comments`, {
    credentials: 'same-origin',
    method: 'POST',
    body: JSON.stringify({ commentId: commentId, content: content }),
  })
  if (res.status === 200) {
    onCommentsChange()
    setContent('')
  }
}

const CommentArea = ({
  projectId,
  onCommentsChange,
  commentId,
  ...props
}: {
  projectId: string
  onCommentsChange: () => void
  commentId: number | null
} & FlexProps) => {
  const { t } = useTranslation('project', { keyPrefix: 'comments' })
  const [content, setContent] = useState('')

  return (
    <Flex direction="column" alignItems="flex-start" {...props}>
      <Textarea
        placeholder={t('add_comment')}
        p="16px"
        mb="15px"
        size="sm"
        value={content}
        onChange={(e) => setContent(e.target.value)}
      />
      <Button
        variant="secondary"
        size="sm"
        onClick={() => PostComment(projectId, content, commentId, onCommentsChange, setContent)}
      >
        {t('validate')}
      </Button>
    </Flex>
  )
}

export default CommentArea
