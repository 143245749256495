import { Search } from '@miimosa/protocol'
import type { Projects } from '@miimosa/protocol'
import { ProductKind } from '@miimosa/protocol/dist/miimosa/common/v1/product_kind'

import { ImageProps, buildV2ImagePath } from '../../helpers'
import type { FrontProjectType, FrontSearchProjectType, FullProject as v2ProjectType } from '../../types'

import { v3StringCategoryToFront } from './category'
import { v2CollectToFront, v3CollectToFront, v3ToFrontSearchCollect } from './collect'
import { v2DescriptionToFront, v3DescriptionToFront } from './description'
import { v3LabelGroupToFront, v3SearchLabelToFront } from './label'
import { v3PartnerLogosToFront } from './partnerLogos'
import { v3ReservationToFront } from './reservation'
import { v3RewardToFront } from './reward'
import { v3StateToFront, v3StringStateToFront } from './state'

export const v3toFrontSearchProject = ({
  project_id,
  category_ids,
  labels,
  image_file_name,
  short_description,
  state,
  owner_data,
  collect,
  wish_ends_at,
  labelGroupId,
  imageFileSize,
  ...rest
}: Search.SearchProject & { labelGroupId?: number; imageFileSize?: string }): FrontSearchProjectType => {
  let formattedCollect = null
  if (collect) formattedCollect = v3ToFrontSearchCollect(collect)

  return {
    projectId: project_id,
    imageFileName: image_file_name
      ? buildV2ImagePath({
          kind: 'project',
          filename: image_file_name,
          id: project_id,
          size: (imageFileSize || 'high') as ImageProps['size'],
        })
      : null,
    // to delete when Story component removed from projects index page
    highImageFileName: image_file_name
      ? buildV2ImagePath({
          kind: 'project',
          filename: image_file_name,
          id: project_id,
          size: 'high' as ImageProps['size'],
        })
      : null,
    labels: v3SearchLabelToFront(labels, labelGroupId),
    shortDescription: short_description,
    state: v3StateToFront(state),
    categoryIds: category_ids,
    // TODO: Use fullname from description
    userFullName: owner_data?.full_name || null,
    avatarFileName: owner_data?.avatar_file_name
      ? buildV2ImagePath({
          kind: 'user',
          filename: owner_data?.avatar_file_name,
          id: owner_data?.user_id || 0,
          size: 'thumb',
        })
      : null,
    userId: owner_data?.user_id || null,
    collect: formattedCollect,
    wishEndsAt: wish_ends_at || null,
    ...rest,
  }
}

export const v3toFrontProject = (projectResponse: Projects.FetchProjectResponse): FrontProjectType | null => {
  if (!projectResponse.project || !projectResponse.collect || !projectResponse.description || !projectResponse.owner) {
    return null
  }

  const projectId = projectResponse.project?.id || 0

  const descriptionWithProjectId = {
    ...projectResponse.description,
    projectId,
  }

  return {
    id: projectId,
    slug: projectResponse.project.slug || null,
    title: projectResponse.project?.title || '',
    state: v3StringStateToFront(projectResponse.project.state),
    category: v3StringCategoryToFront(projectResponse.project?.category),
    teasing: projectResponse.project?.teasing || false,
    collect: v3CollectToFront(projectResponse.collect),
    description: v3DescriptionToFront(descriptionWithProjectId),
    userFullName: projectResponse.description.owner_name || projectResponse.owner.full_name,
    avatarFileName: projectResponse.owner.avatar_file_name
      ? buildV2ImagePath({
          kind: 'user',
          filename: projectResponse.owner.avatar_file_name,
          id: projectResponse.owner.id,
          size: 'thumb',
        })
      : null,
    userId: projectResponse.owner.id,
    rewards: projectResponse.rewards.map((reward) => v3RewardToFront({ ...reward })),
    labels: projectResponse.label_groups.map((labelGroup) => v3LabelGroupToFront(labelGroup)),
    contributorsCount: projectResponse.project_aggregate?.contributors_count || 0,
    articlesCount: projectResponse.project.articles_count || 0,
    commentsCount: projectResponse.project.comments_count || 0,
    reservations: v3ReservationToFront({
      reservations: projectResponse.reservations,
      reservationOpenForUser: projectResponse.reservation_open_for_user,
    }),
    latitude: null,
    longitude: null,
    siret: projectResponse.organization?.siret || null,
    bce: projectResponse.organization?.vat_number || null,
    partnerLogos: projectResponse.partner_logos.map(v3PartnerLogosToFront),
    regionalCode: projectResponse.regional_code || '',
    assets: projectResponse.assets || [],
    ...(projectResponse.collect.type === 'COLLECT_TYPE_LENDING'
      ? { lendingProductKind: getFrontLendingProductKind(projectResponse.product_kinds) }
      : {}),
    ...(projectResponse.collect.type === 'COLLECT_TYPE_LENDING' ? { isCip: isCip(projectResponse.product_kinds) } : {}),
  }
}

export const v2ToFrontProject = (projectResponse: v2ProjectType): FrontProjectType => {
  return {
    slug: projectResponse.slug,
    id: projectResponse.id,
    title: projectResponse.title,
    state: projectResponse.status,
    category: v3StringCategoryToFront(projectResponse.category),
    teasing: projectResponse.teasing,
    collect: v2CollectToFront(projectResponse),
    description: v2DescriptionToFront(projectResponse),
    userFullName: projectResponse.owner_full_name,
    avatarFileName: projectResponse.avatar_url,
    userId: 0,
    rewards: [],
    labels: [],
    contributorsCount: 0,
    articlesCount: 0,
    commentsCount: 0,
    reservations: null,
    latitude: projectResponse.latitude || null,
    longitude: projectResponse.longitude || null,
    siret: null,
    bce: null,
    partnerLogos: [],
    regionalCode: '',
  }
}

export type FrontLendingProductKindType = 'participatory_loan' | 'minibon' | 'bond' | 'convertible_bond' | 'undefined'

/*
PRODUCT_KIND_PARTICIPATORY_LOAN -> Prêt rémunéré
PRODUCT_KIND_BOND -> Obligations simples
PRODUCT_KIND_MINIBON -> Minibons
PRODUCT_KIND_DONATION
PRODUCT_KIND_BELGIUM_LOAN -> Prêt rémunéré
PRODUCT_KIND_PRIVATE_DEBT
PRODUCT_KIND_MERCHANT
PRODUCT_KIND_CONVERTIBLE_BOND -> Obligations convertibles

Si tu as plus de un product kind associé au projet -> prêt rémunéré
 */
export function getFrontLendingProductKind(productKinds: ProductKind[]): FrontLendingProductKindType {
  if (productKinds.length === 0) {
    return 'undefined'
  }

  if (productKinds.length === 1) {
    if (productKinds[0] === ProductKind.PRODUCT_KIND_CONVERTIBLE_BOND) {
      return 'convertible_bond'
    }

    if (productKinds[0] === ProductKind.PRODUCT_KIND_BOND) {
      return 'bond'
    }

    if (productKinds[0] === ProductKind.PRODUCT_KIND_MINIBON) {
      return 'minibon'
    }

    if (
      productKinds[0] === ProductKind.PRODUCT_KIND_PARTICIPATORY_LOAN ||
      productKinds[0] === ProductKind.PRODUCT_KIND_BELGIUM_LOAN
    ) {
      return 'participatory_loan'
    }
  }

  return 'participatory_loan'
}

const isCip = (productKinds: ProductKind[]): boolean => {
  if (productKinds.length === 0) {
    return false
  }

  if (
    productKinds.includes(ProductKind.PRODUCT_KIND_CONVERTIBLE_BOND) ||
    productKinds.includes(ProductKind.PRODUCT_KIND_BOND) ||
    productKinds.includes(ProductKind.PRODUCT_KIND_MINIBON)
  ) {
    return true
  }
  return false
}
