import { useEffect, useState } from 'react'

import { MIIMOSA_FLASH } from '@miimosa/common'
import { V2Session } from '@miimosa/protocol'

import { getJSONCookie, setOrDestroyJSONCookie } from '../cookies'

function useV2Flash(): { flash: V2Session.Flash[]; clearFlash: () => void } {
  // used only to update view on flash loaded
  const [flash, setFlash] = useState<V2Session.Flash[]>([])

  useEffect(() => {
    const flashes = getJSONCookie<V2Session.Flash[]>(MIIMOSA_FLASH)
    setFlash(flashes ?? [])
  }, [])

  return {
    flash,
    clearFlash: () => {
      setOrDestroyJSONCookie(MIIMOSA_FLASH, null)
      setFlash([])
    },
  }
}

export default useV2Flash
