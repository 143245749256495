import { SystemStyleObject } from '@chakra-ui/react'

const sizes: Record<string, SystemStyleObject> = {
  md: {
    field: {
      fontSize: '1rem',
      lineHeight: '1.375rem',
    },
  },
}

const Input = {
  sizes,
  defaultProps: {
    field: {
      size: 'md',
      fontFamily: ['AcidGrotesk', 'sans-serif'],
      w: 'auto',
    },
  },
}

export default Input
