import { Dispatch, SetStateAction, createContext, useState } from 'react'

import { FunnelStateType } from './useFunnel'

const initialState: FunnelStateType = {
  lead_id: '',
  funnel_type: 'FUNNEL_TYPE_UNSPECIFIED',
  step: undefined,
  salesforce_ref: '',
  progress: { current_step: 0, step_count: 0, steps: [] },
}

export const FunnelContext = createContext<{
  funnelState: FunnelStateType
  setFunnelState: Dispatch<SetStateAction<FunnelStateType>>
}>({ funnelState: initialState, setFunnelState: () => undefined })

const FunnelProvider = ({ children }: { children: JSX.Element }) => {
  const [funnelState, setFunnelState] = useState(initialState)

  return <FunnelContext.Provider value={{ funnelState, setFunnelState }}>{children}</FunnelContext.Provider>
}

export default FunnelProvider
