import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'Success',
  viewBox: '0 0 24 24',
  path: (
    <>
      <path
        d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7363 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM12 21C10.22 21 8.47991 20.4722 6.99987 19.4832C5.51983 18.4943 4.36628 17.0887 3.68509 15.4442C3.0039 13.7996 2.82567 11.99 3.17294 10.2442C3.5202 8.49836 4.37737 6.89471 5.63604 5.63604C6.89472 4.37737 8.49836 3.5202 10.2442 3.17293C11.99 2.82567 13.7996 3.0039 15.4442 3.68508C17.0887 4.36627 18.4943 5.51983 19.4832 6.99987C20.4722 8.47991 21 10.22 21 12C21 14.3869 20.0518 16.6761 18.364 18.364C16.6761 20.0518 14.387 21 12 21Z"
        fill="#003F2C"
      />
      <path
        d="M17.21 9.64L11.07 15.15L7.85 11.52C7.80601 11.4708 7.75275 11.4307 7.69326 11.402C7.63377 11.3733 7.56922 11.3567 7.50329 11.353C7.43736 11.3492 7.37134 11.3585 7.30901 11.3803C7.24668 11.4021 7.18925 11.436 7.14 11.48C7.04055 11.5688 6.98046 11.6936 6.97296 11.8267C6.96924 11.8926 6.97855 11.9587 7.00035 12.021C7.02215 12.0833 7.05601 12.1408 7.1 12.19L10.66 16.19C10.7505 16.2823 10.8712 16.3391 11 16.35C11.1237 16.3501 11.2438 16.3078 11.34 16.23L17.85 10.39C17.8993 10.346 17.9393 10.2927 17.968 10.2333C17.9967 10.1738 18.0133 10.1092 18.017 10.0433C18.0208 9.97736 18.0115 9.91134 17.9897 9.84901C17.9679 9.78667 17.934 9.72925 17.89 9.68C17.846 9.63075 17.7927 9.59066 17.7333 9.56199C17.6738 9.53333 17.6092 9.51667 17.5433 9.51295C17.4774 9.50924 17.4113 9.51855 17.349 9.54035C17.2867 9.56214 17.2292 9.59601 17.18 9.64H17.21Z"
        fill="#003F2C"
      />
      <path
        d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7363 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM12 21C10.22 21 8.47991 20.4722 6.99987 19.4832C5.51983 18.4943 4.36628 17.0887 3.68509 15.4442C3.0039 13.7996 2.82567 11.99 3.17294 10.2442C3.5202 8.49836 4.37737 6.89471 5.63604 5.63604C6.89472 4.37737 8.49836 3.5202 10.2442 3.17293C11.99 2.82567 13.7996 3.0039 15.4442 3.68508C17.0887 4.36627 18.4943 5.51983 19.4832 6.99987C20.4722 8.47991 21 10.22 21 12C21 14.3869 20.0518 16.6761 18.364 18.364C16.6761 20.0518 14.387 21 12 21Z"
        fill="#2A2A2A"
      />
      <path
        d="M17.21 9.63999L11.07 15.15L7.85001 11.52C7.80602 11.4707 7.75276 11.4306 7.69327 11.402C7.63378 11.3733 7.56922 11.3567 7.50329 11.3529C7.43736 11.3492 7.37135 11.3585 7.30902 11.3803C7.24668 11.4021 7.18926 11.436 7.14001 11.48C7.04055 11.5688 6.98047 11.6936 6.97296 11.8267C6.96925 11.8926 6.97856 11.9586 7.00036 12.021C7.02215 12.0833 7.05602 12.1407 7.10001 12.19L10.66 16.19C10.7505 16.2823 10.8712 16.3391 11 16.35C11.1237 16.3501 11.2438 16.3078 11.34 16.23L17.85 10.39C17.8993 10.346 17.9394 10.2927 17.968 10.2332C17.9967 10.1738 18.0133 10.1092 18.0171 10.0433C18.0208 9.97734 18.0115 9.91133 17.9897 9.849C17.9679 9.78666 17.934 9.72923 17.89 9.67999C17.846 9.63074 17.7928 9.59064 17.7333 9.56198C17.6738 9.53332 17.6092 9.51666 17.5433 9.51294C17.4774 9.50923 17.4113 9.51854 17.349 9.54033C17.2867 9.56213 17.2293 9.596 17.18 9.63999H17.21Z"
        fill="#2A2A2A"
      />
    </>
  ),
})
