import { Flex, Button, Text } from '@chakra-ui/react'
import { Dispatch, SetStateAction } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'

import { Leads } from '@miimosa/protocol'

import { AmountInput, CheckboxInput, HeadingIntro, RadioInput } from '@components'
import { formatData, previousPSFPFunnel, updatePSFPFunnel } from '@helpers/psfp'
import { PSFPFormValues } from '@helpers/psfp/types'

interface Props {
  funnelStep?: Leads.Step
  leadId: string
  setCurrentFunnel: Dispatch<SetStateAction<Leads.UpdateResponse>>
}

const InvestmentExperiences = ({ funnelStep, leadId, setCurrentFunnel }: Props) => {
  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
  } = useForm<PSFPFormValues>({
    defaultValues: {
      INPUT_NAME_CROWDFUNDING_EXPERIENCE:
        funnelStep?.input_descriptors[0].default_value != undefined
          ? String(funnelStep?.input_descriptors[0].default_value?.boolean)
          : undefined,
      INPUT_NAME_OTHER_INVESTMENT_EXPERIENCE: funnelStep?.input_descriptors[1].default_value?.str
        ? funnelStep?.input_descriptors[1].default_value?.str.split(',')
        : [],
      INPUT_NAME_LAST_YEAR_INVESTED_AMOUNT: String(funnelStep?.input_descriptors[2].default_value?.number),
    },
  })

  const onSubmit: SubmitHandler<PSFPFormValues> = (data) => {
    const formattedData = formatData({ data: data, leadId: leadId, funnelStep: funnelStep })
    const response = updatePSFPFunnel(formattedData)
    response.then((newData) => {
      if (newData) {
        setCurrentFunnel(newData)
      }
    })
  }

  const onPrevious = () => {
    const response = previousPSFPFunnel({
      funnel_type: 'FUNNEL_TYPE_PSFP',
      lead_id: leadId,
    })

    response.then((newData) => {
      if (newData) {
        const createResp: Leads.UpdateResponse = {
          step: newData.step,
          progress: newData.progress,
          lead_id: leadId,
          salesforce_ref: '',
          outputs: [],
        }

        setCurrentFunnel(createResp)
      }
    })
  }

  if (!funnelStep) return null

  return (
    <Flex direction="column" as="form" w="full" justifyContent="space-between">
      <Flex direction="column" rowGap={8}>
        <HeadingIntro texts={['Votre expérience et vos connaissances en investissement']} />
        <Flex direction="column" rowGap={4}>
          <RadioInput
            title="Avez-vous déjà investi sur une plateforme de financement participatif ?"
            control={control}
            inputName="INPUT_NAME_CROWDFUNDING_EXPERIENCE"
            isRequired={funnelStep.input_descriptors[0].validation?.required}
            errors={errors}
            radioValues={[
              { value: 'true', trad: 'Oui' },
              { value: 'false', trad: 'Non' },
            ]}
            radioDirection="row"
          />
        </Flex>
        <Flex direction="column" rowGap={4}>
          <CheckboxInput
            title="Indiquez, le cas échéants, d’autres produits financiers sur lesquels vous avez déjà investi :"
            inputName="INPUT_NAME_OTHER_INVESTMENT_EXPERIENCE"
            register={register}
            values={[
              { value: 'public_shares_and_bonds', trad: 'Actions et obligations cotées' },
              { value: 'private_share_bonds', trad: 'Actions et obligations non cotées' },
              { value: 'ucits', trad: 'Organismes de Placement Collectif en Valeurs Mobilières (OPCVM)' },
              { value: 'private_equity', trad: 'Fonds d’investissement (FIP, FCP, etc.)' },
              { value: 'live_insurance', trad: 'Assurance vie' },
              { value: 'other', trad: 'Autre' },
            ]}
            errors={errors}
            isRequired={funnelStep.input_descriptors[1].validation?.required}
          />
        </Flex>
        <Flex direction="column" rowGap={2}>
          <AmountInput
            title={
              "Au cours de l'année passée, quel montant avez-vous investi sur l'ensemble de ces produits financiers (financement participatif compris) ?"
            }
            errors={errors}
            inputName="INPUT_NAME_LAST_YEAR_INVESTED_AMOUNT"
            control={control}
            isRequired={funnelStep.input_descriptors[2].validation?.required}
            placeholder="Ex : 10 000"
            min={0}
            max={2000000000}
          />
          {errors['INPUT_NAME_LAST_YEAR_INVESTED_AMOUNT']?.type === 'tooHigh' && (
            <Text color="red">Le montant ne doit pas être supérieur à 2 000 000 000</Text>
          )}
        </Flex>
        <Flex w="full" justifyContent="center" columnGap={8}>
          <Button variant="secondary" size="md" onClick={onPrevious}>
            Précédent
          </Button>
          <Button variant="primary" size="md" onClick={handleSubmit(onSubmit)}>
            Suivant
          </Button>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default InvestmentExperiences
