import { Flex, Divider, Text, Link, useBreakpointValue, Heading, Grid, GridItem } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useContext } from 'react'

import {
  buildV2ImagePath,
  FrontDescriptionType,
  type FrontCollectType,
  type FrontProjectType,
  type FrontTermFrequencyType,
} from '@miimosa/common'
import { Carousel, IconName, IconSize, toV2Link, usePlatform, UserContext } from '@miimosa/design-system'
import Icon from '@miimosa/design-system/components/Icon'

type ImpactsAndLendingInfosProps = {
  project: FrontProjectType
}

export const termFrequencyInMonth = (termFrequency: FrontTermFrequencyType) => {
  switch (termFrequency) {
    case 'monthly':
      return 1
    case 'quarterly':
      return 3
    case 'half_yearly':
      return 6
    case 'yearly':
      return 12
    default:
      return 0
  }
}

export const isInFine = (collect: FrontCollectType): boolean => {
  return collect.termQuantity! - collect.amortizationDelay! - collect.repaymentDelay! === 1
}

export const calculateDelayed = ({
  termQuantity,
  amortizationDelay,
  repaymentDelay,
  termFrequency,
}: {
  termQuantity?: number | null
  amortizationDelay?: number | null
  repaymentDelay?: number | null
  termFrequency?: FrontTermFrequencyType | null
}) => {
  if (termQuantity! - amortizationDelay! - repaymentDelay! === 1) {
    return 1
  } else if (amortizationDelay === 0 && repaymentDelay === 0) {
    return 0
  } else {
    return amortizationDelay! * termFrequencyInMonth(termFrequency!)
  }
}

const ImpactsAndLendingInfos = ({ project }: ImpactsAndLendingInfosProps) => {
  const isMobile = useBreakpointValue({ base: true, lg: false }, { fallback: 'lg' })
  const impactsProps = buildImpactProps(project.description)

  if (isMobile) {
    const datas: { kind: 'InvestmentDetail' | 'FinancialPlan' | 'ProjectImpact' }[] = [
      { kind: 'InvestmentDetail' },
      { kind: 'FinancialPlan' },
    ]

    if (impactsProps.length > 0) {
      datas.push({ kind: 'ProjectImpact' })
    }

    const renderMobileInfo = (data: { kind: 'InvestmentDetail' | 'FinancialPlan' | 'ProjectImpact' }) => {
      const components = {
        InvestmentDetail: <InvestmentDetail project={project} />,
        FinancialPlan: <FinancialPlan project={project} />,
        ProjectImpact: <ProjectImpact impacts={impactsProps} />,
      }

      return (
        <Flex mx={{ base: 1, lg: 0 }} h="250px" border="1px solid light_gray" borderRadius="4px" bg="white" py={4}>
          {components[data.kind]}
        </Flex>
      )
    }

    return (
      <Flex direction="column" alignItems="center" rowGap={8} my={4} w="full">
        <Carousel
          isDark
          SlideComponent={renderMobileInfo}
          data={datas}
          draggable
          navBottom
          boxedControls={true}
          infinite={false}
          hasDots={false}
          withArrow={false}
          centerMode={true}
          centerPadding="15px"
        />
      </Flex>
    )
  }

  return (
    <Flex w="full" h="full" direction="row">
      {impactsProps.length > 0 && (
        <>
          <ProjectImpact impacts={impactsProps} />
          <Divider orientation="vertical" bg="light_gray" w="1px" height={'auto'} />
        </>
      )}
      <InvestmentDetail project={project} />
      <>
        <Divider orientation="vertical" bg="light_gray" w="1px" height={'auto'} />
        <FinancialPlan project={project} />
      </>
    </Flex>
  )
}

type ProjectImpactKind = 'jobs' | 'total_area_in_hectare' | 'household_equivalent'

interface ProjectImpactProps {
  impacts: {
    kind: ProjectImpactKind
    value: number
  }[]
}

const ProjectImpact = ({ impacts }: ProjectImpactProps) => {
  const isMobile = useBreakpointValue({ base: true, lg: false }, { fallback: 'lg' })

  const sizes = [
    {
      heightAndWidth: '80px',
      sizeFromDesign: 'md' as IconSize,
      textSize: 'md',
      headingSize: 'sm',
      width: 'full',
      maxW: '350',
      gridColumns: 'repeat(1, 1fr)',
      gridRows: 'repeat(1, 1fr)',
    },
    {
      heightAndWidth: '50px',
      sizeFromDesign: 'xs' as IconSize,
      textSize: 'sm',
      headingSize: 'sm',
      width: '136px',
      maxW: '350',
      gridColumns: 'repeat(2, 1fr)',
      gridRows: 'repeat(1, 1fr)',
    },
    {
      heightAndWidth: '40px',
      sizeFromDesign: '2xs' as IconSize,
      textSize: 'xs',
      headingSize: '1rem',
      width: '128px',
      maxW: '350',
      gridColumns: 'repeat(3, 1fr)',
      gridRows: isMobile ? 'repeat(1, 1fr)' : 'repeat(2, 1fr)',
    },
  ]

  const kindMap = {
    jobs: {
      icon: 'SocialCohesionBig' as IconName,
      title: (value: number) => `${new Intl.NumberFormat('fr-FR').format(value)} emploi` + (value > 1 ? 's' : ''),
      description: 'directs et indirects',
    },
    total_area_in_hectare: {
      icon: 'LocalDevelopmentBig' as IconName,
      title: (value: number) => `${new Intl.NumberFormat('fr-FR').format(value)} hectare` + (value > 1 ? 's' : ''),
      description: 'de surface agricole préservés',
    },
    household_equivalent: {
      icon: 'RenewableEnergyBig' as IconName,
      title: (value: number) => `${new Intl.NumberFormat('fr-FR').format(value)} foyer` + (value > 1 ? 's' : ''),
      description: 'alimentés en énergie renouvelable',
    },
  }

  const calcPosition = (
    index: number,
    arraySize: number
  ): { rowStart: number; rowEnd: number; colStart: number; colEnd: number } | undefined => {
    // 1 mean an item can be placed
    // 0 mean an item can't be placed
    const differentPositions = [
      [[1]],
      [[1, 1]],
      isMobile
        ? [[1, 1, 1]]
        : [
            [0, 1, 0],
            [1, 0, 1],
          ],
    ]

    const positioner = differentPositions[arraySize - 1]

    // get the rowSpan and colSpan from the positioner
    let skippedPosition = 0
    for (let row = 0; row < positioner.length; row++) {
      for (let col = 0; col < positioner[row].length; col++) {
        if (positioner[row][col] === 1) {
          if (skippedPosition == index) {
            return {
              rowStart: row + 1,
              rowEnd: row + 1,
              colStart: col + 1,
              colEnd: col + 1,
            }
          }

          skippedPosition++
        }
      }
    }
  }

  const sizer = sizes[impacts.length - 1]

  return (
    <Flex
      maxW={isMobile ? 'none' : sizer.maxW}
      minW={sizer.maxW}
      w={'full'}
      direction="column"
      h="full"
      // width={'full'}
      px={6}
    >
      <Text size="xl" fontWeight="bold" pb={4}>
        Impact du projet
      </Text>
      <Flex h={'full'} width={'full'} alignItems={'center'}>
        <Grid id={'grid'} width={'full'} templateRows={sizer.gridRows} templateColumns={sizer.gridColumns}>
          {impacts.map((impact, index) => {
            return (
              <GridItem key={index} {...(calcPosition(index, impacts.length) || {})}>
                <Flex w={'full'} alignItems={'center'} justifyContent={'center'}>
                  <Flex w={sizer.heightAndWidth} h={sizer.heightAndWidth}>
                    <Icon name={kindMap[impact.kind].icon} size={sizer.sizeFromDesign} color="orange" />
                  </Flex>
                </Flex>
                <Heading textAlign={'center'} fontSize={sizer.headingSize}>
                  {kindMap[impact.kind].title(impact.value)}
                </Heading>
                <Text textAlign={'center'} size={sizer.textSize}>
                  {kindMap[impact.kind].description}
                </Text>
              </GridItem>
            )
          })}
        </Grid>
      </Flex>
    </Flex>
  )
}

const InvestmentDetail = ({ project }: { project: FrontProjectType }) => {
  const { t } = useTranslation('project', { keyPrefix: 'collect' })

  const delayedResult = calculateDelayed({
    termQuantity: project.collect.termQuantity,
    amortizationDelay: project.collect.amortizationDelay,
    repaymentDelay: project.collect.repaymentDelay,
    termFrequency: project.collect.termFrequency,
  })

  const schedulingValue = isInFine(project.collect)
    ? t('lending.in_fine')
    : `${t('lending.depreciation')} ${t(`lending.${project.collect.termFrequency}`)}`

  let delayedValue = t('lending.none')
  if (delayedResult === 1) {
    delayedValue = t('lending.in_fine')
  } else if (delayedResult > 1) {
    delayedValue = t('lending.delayed_calc', {
      val: project.collect.amortizationDelay! * termFrequencyInMonth(project.collect.termFrequency!),
    })
  }

  const interestRateValue = `${((project.collect.interestsRatio || 0) * 100).toFixed(2).replace(/\./g, ',')} ${t(
    'lending.rate_percent'
  )}`

  const { platform, lang } = usePlatform()

  const user = useContext(UserContext)

  const { asPath } = useRouter()

  // {t('loanItem.duration')} {term_quantity} {t(`loanItem.unit.${interests_repayment_frequency}`)}

  return (
    <Flex minW={'350px'} direction="column" h="full" px={6} rowGap={3} w="full">
      <Flex direction="column" rowGap={3}>
        <Text size="xl" fontWeight="bold" pb={4}>
          Détails de l’investissement
        </Text>
        <Flex w="full" direction="column" rowGap={1}>
          <InvestmentLine text={t('lending.interests_rate')} value={interestRateValue} />
          <InvestmentLine text={t('lending.scheduling')} value={schedulingValue} />
          {delayedResult != 1 && <InvestmentLine text={t('lending.delayed')} value={delayedValue} />}
          <InvestmentLine text={t(`lending.interest_payment`)} value={t(`lending.${project.collect.termFrequency}`)} />
          <InvestmentLine
            text={t('lending.duration')}
            value={`${project.collect.termQuantity} ${t(`lending.period_${project.collect.termFrequency}`)}`}
          />
          <InvestmentLine text={t('lending.rank')} value={t(`lending.${project.collect!.financialRank}`)} />
          <InvestmentLine
            text={t('lending.minmax_amount')}
            value={`${project.collect.minPledgeAmount} € / Pas de limite`}
          />
        </Flex>
        {project.collect.dirFileName && (
          <Link
            size="xs"
            href={
              user
                ? buildV2ImagePath({
                    kind: 'lending',
                    filename: project.collect.dirFileName,
                    id: project.collect.id,
                    size: 'original',
                  })
                : toV2Link(`/authenticate?return_url=${asPath}`, platform, lang)
            }
            target="_blank"
            variant="primaryUnderline"
            color="dark_green"
            textAlign="center"
          >
            Télécharger la fiche d&apos;informations clés sur l&apos;investissement
          </Link>
        )}
      </Flex>
      <Link
        href={`${toV2Link('/help_center', platform, lang)}#pret-remunere`}
        target="_blank"
        textDecoration="underline"
      >
        <Text size="xs" w="full" align="left">
          *Voir les modalités de définition du taux d’intérêt
        </Text>
      </Link>
    </Flex>
  )
}

const InvestmentLine = ({ text, value }: { text: string; value: string }) => {
  return (
    <Flex direction="column" w="full">
      <Flex w="full" h="full" direction="row" justifyContent="space-between">
        <Text size="sm" fontWeight="bold">
          {text}
        </Text>
        <Text textAlign={'right'} size="sm">
          {value}
        </Text>
      </Flex>
      <Divider />
    </Flex>
  )
}

const FinancialPlan = ({ project }: { project: FrontProjectType }) => {
  const { locale } = useRouter()
  const { t } = useTranslation('project', { keyPrefix: 'collect' })
  const isMobile = useBreakpointValue({ base: true, lg: false }, { fallback: 'lg' })

  const lines = [
    'depositAmount',
    'equityAmount',
    'mezzanineAmount',
    'bankLoanAmount',
    'grantAmount',
    'selfFinancingAmount',
    'otherFundingAmount',
  ]

  const linesToAlwaysShow = ['depositAmount', 'grantAmount', 'bankLoanAmount']

  const containingLines = Object.entries(project.description).filter(([key]) => lines.includes(key))

  const totalNeededForProject = project.description.totalFundingAmount + project.collect.goalAmount

  const emptyFinancial =
    project.description.totalFundingAmount + project.collect.goalAmount === project.collect.goalAmount

  return (
    <Flex
      direction="column"
      h="full"
      px={6}
      w="full"
      maxW={isMobile ? 'none' : '350px'}
      minW={'310px'}
      // minW={{ base: 'full', lg: '345px' }}
      rowGap={4}
    >
      <Text size="xl" fontWeight="bold" pb={4}>
        Plan de financement
      </Text>
      <Flex direction="column" rowGap={3}>
        <Flex direction="row" w="full" justifyContent="space-between" alignItems="center">
          <Text size="sm" fontWeight="bold">
            Montant financé sur MiiMOSA
          </Text>
          <Text size="sm">{project.collect.goalAmount.toLocaleString(locale, { maximumFractionDigits: 0 })} €</Text>
        </Flex>
        <Flex direction="column" w="full" rowGap={2}>
          {emptyFinancial &&
            linesToAlwaysShow.map((line, index) => (
              <Flex direction="row" w="full" justifyContent="space-between" alignItems="center" key={index}>
                <Text size="sm">+ {t(`lending.financial_plan.${line}`)}</Text>
                <Text size="sm">-</Text>
              </Flex>
            ))}
          {!emptyFinancial &&
            containingLines.map(
              ([line, value], index) =>
                value != null &&
                (value as number) > 0 && (
                  <Flex direction="row" w="full" justifyContent="space-between" alignItems="center" key={index}>
                    <Text size="sm">+ {t(`lending.financial_plan.${line}`)}</Text>
                    <Text size="sm">
                      {(value as number).toLocaleString(locale, {
                        maximumFractionDigits: 0,
                        style: 'currency',
                        currency: 'EUR',
                      })}
                    </Text>
                  </Flex>
                )
            )}
          <Divider />
          <Flex direction="row" w="full" justifyContent="space-between" alignItems="center">
            <Text size="sm">= Total nécessaire au projet</Text>
            <Text size="sm">{totalNeededForProject.toLocaleString(locale, { maximumFractionDigits: 0 })} €</Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

function buildImpactProps(description: FrontDescriptionType) {
  const impactProps: { kind: ProjectImpactKind; value: number }[] = []

  if (description.impactJobs !== 0) {
    impactProps.push({ kind: 'jobs', value: description.impactJobs })
  }

  if (description.impactSurface !== 0) {
    impactProps.push({ kind: 'total_area_in_hectare', value: description.impactSurface })
  }

  if (description.impactHome !== 0) {
    impactProps.push({ kind: 'household_equivalent', value: description.impactHome })
  }

  // sort by value desc

  impactProps.sort((a, b) => {
    return b.value - a.value
  })

  return impactProps
}

export default ImpactsAndLendingInfos
