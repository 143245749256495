import { type GetServerSidePropsContext } from 'next'

import { Leads } from '@miimosa/protocol'

import { BaseAPI, Result } from '../api'

import { ErrorV3, createV3Client, toV3Result } from './base'

export class FunnelAPI extends BaseAPI {
  constructor(ctx?: GetServerSidePropsContext) {
    super(createV3Client(ctx), toV3Result)
  }

  async createFunnel({ funnelType }: { funnelType: Leads.FunnelType }): Promise<Result<Leads.CreateResponse, ErrorV3>> {
    return await this.call<Leads.CreateResponse, ErrorV3>((c) => c.post('/leads/v1/Create', { funnelType }))
  }

  async updateFunnel(params: Leads.UpdateRequest): Promise<Result<Leads.UpdateResponse, ErrorV3>> {
    return await this.call<Leads.UpdateResponse, ErrorV3>((c) => c.post('/leads/v1/Update', params))
  }

  async previousFunnel(params: Leads.PreviousRequest): Promise<Result<Leads.PreviousResponse, ErrorV3>> {
    return await this.call<Leads.PreviousResponse, ErrorV3>((c) => c.post('/leads/v1/Previous', params))
  }

  async fetchPSFPFunnel(params: Leads.FetchPSFPRequest): Promise<Result<Leads.FetchPSFPResponse, ErrorV3>> {
    return await this.call<Leads.FetchPSFPResponse, ErrorV3>((c) => c.post('/leads/v1/FetchPSFP', params))
  }

  async fetchLead(params: Leads.FetchRequest): Promise<Result<Leads.FetchResponse, ErrorV3>> {
    return await this.call<Leads.FetchResponse, ErrorV3>((c) => c.post('/leads/v1/Fetch', params))
  }
}
