import { Button, Flex, useDisclosure } from '@chakra-ui/react'
import { Dispatch, SetStateAction, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'

import { Leads } from '@miimosa/protocol'

import { WarningModal } from '@components'
import { formatData, previousPSFPFunnel, updatePSFPFunnel } from '@helpers/psfp'
import { PSFPFormValues } from '@helpers/psfp/types'

import HeadingIntro from './HeadingIntro'
import RadioInput from './RadioInput'

interface Props {
  funnelStep?: Leads.Step
  leadId: string
  setCurrentFunnel: Dispatch<SetStateAction<Leads.UpdateResponse>>
}

const InvestmentComprehension = ({ funnelStep, leadId, setCurrentFunnel }: Props) => {
  const [modalTitle, setModalTitle] = useState('')
  const [modalContent, setModalContent] = useState('')
  const [inputToUpdate, setInputToUpdate] = useState<keyof PSFPFormValues>(
    'INPUT_NAME_NOT_SAVING_ACCOUNT_ACKNOWLEDGMENT'
  )

  const { isOpen, onOpen, onClose } = useDisclosure()

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<PSFPFormValues>({
    defaultValues: {
      INPUT_NAME_NOT_SAVING_ACCOUNT_ACKNOWLEDGMENT:
        funnelStep?.input_descriptors[0].default_value != undefined
          ? String(funnelStep?.input_descriptors[0].default_value?.boolean)
          : undefined,
      INPUT_NAME_DEFAULT_RISK_ACKNOWLEDGMENT:
        funnelStep?.input_descriptors[1].default_value != undefined
          ? String(funnelStep?.input_descriptors[1].default_value?.boolean)
          : undefined,
      INPUT_NAME_LIQUIDITY_RISK_ACKNOWLEDGMENT:
        funnelStep?.input_descriptors[2].default_value != undefined
          ? String(funnelStep?.input_descriptors[2].default_value?.boolean)
          : undefined,
      INPUT_NAME_MAX_RATIO_ACKNOWLEDGMENT:
        funnelStep?.input_descriptors[3].default_value != undefined
          ? String(funnelStep?.input_descriptors[3].default_value?.boolean)
          : undefined,
    },
  })

  const onSubmit: SubmitHandler<PSFPFormValues> = (data) => {
    if (data['INPUT_NAME_NOT_SAVING_ACCOUNT_ACKNOWLEDGMENT'] == 'false') {
      setModalTitle(
        'Attention : investir sur des projets de financement participatif est plus risqué que de placer son argent sur des livrets d’épargne'
      )
      setModalContent(
        'Les livrets d’épargne comme le Livret A ou le Livret Développement Durable ou les Fonds Euro des contrats d’assurance vie offrent un rendement relativement faible mais ont l’avantage de garantir votre capital. \n\nSi l’investissement sur des projets de financement participatif est potentiellement plus rémunérateur, il est aussi plus risqué car vous êtes exposé aux éventuels défauts des emprunteurs.'
      )
      setInputToUpdate('INPUT_NAME_NOT_SAVING_ACCOUNT_ACKNOWLEDGMENT')
      onOpen()
    } else if (data['INPUT_NAME_DEFAULT_RISK_ACKNOWLEDGMENT'] == 'false') {
      setModalTitle(
        'Attention : en investissant sur des projets de financement participatif, vous êtes exposé au risque de défaut des entreprises qui les portent'
      )
      setModalContent(
        'MiiMOSA procède à une analyse approfondie des opportunités d’investissement proposées sur la plateforme. Mais les entreprises restent soumises à un ensemble d’aléas qui peuvent perturber leur activité, jusqu’à entrainer un défaut. \n\nEn cas de défaut de l’entreprise, il est possible que vous perdiez tout ou partie de votre investissement.'
      )
      setInputToUpdate('INPUT_NAME_DEFAULT_RISK_ACKNOWLEDGMENT')
      onOpen()
    } else if (data['INPUT_NAME_LIQUIDITY_RISK_ACKNOWLEDGMENT'] == 'false') {
      setModalTitle(
        'Attention : gardez à l’esprit que votre argent sera immobilisé pendant la durée de votre investissement'
      )
      setModalContent(
        'Contrairement aux livrets d’épargne qui vous permettent de retirer votre argent à tout moment, ou à des investissements sur des produits financiers côtés, il est difficile (voire impossible) de revendre des titres de créance ou de capital détenus dans le cadre du financement participatif.'
      )
      setInputToUpdate('INPUT_NAME_LIQUIDITY_RISK_ACKNOWLEDGMENT')
      onOpen()
    } else if (data['INPUT_NAME_MAX_RATIO_ACKNOWLEDGMENT'] == 'false') {
      setModalTitle(
        'Attention : le financement participatif n’est pas un produit d’épargne et nous vous conseillons de ne pas investir plus de 10 % de votre patrimoine net'
      )
      setModalContent('Nous vous aiderons à calculer votre patrimoine financier net à la prochaine étape.')
      setInputToUpdate('INPUT_NAME_MAX_RATIO_ACKNOWLEDGMENT')
      onOpen()
    } else {
      const formattedData = formatData({ data: data, leadId: leadId, funnelStep: funnelStep })
      const response = updatePSFPFunnel(formattedData)
      response.then((newData) => {
        if (newData) {
          setCurrentFunnel(newData)
        }
      })
    }
  }

  const onPrevious = () => {
    const response = previousPSFPFunnel({
      funnel_type: 'FUNNEL_TYPE_PSFP',
      lead_id: leadId,
    })

    response.then((newData) => {
      if (newData) {
        const createResp: Leads.UpdateResponse = {
          step: newData.step,
          progress: newData.progress,
          lead_id: leadId,
          salesforce_ref: '',
          outputs: [],
        }

        setCurrentFunnel(createResp)
      }
    })
  }

  if (!funnelStep) return null

  return (
    <Flex direction="column" as="form" w="full" justifyContent="space-between">
      <WarningModal
        isOpen={isOpen}
        onClose={onClose}
        setValue={setValue}
        modalTitle={modalTitle}
        modalContent={modalContent}
        inputToUpdate={inputToUpdate}
        valueToUpdate="true"
      />
      <Flex direction="column" rowGap={8}>
        <HeadingIntro texts={['Compréhension des risques liés au financement participatif']} />
        <Flex direction="column" rowGap={4}>
          <RadioInput
            title="Investir sur des projets de financement participatif est-il plus risqué que de placer son argent sur des livrets d’épargne ?"
            control={control}
            inputName="INPUT_NAME_NOT_SAVING_ACCOUNT_ACKNOWLEDGMENT"
            isRequired={funnelStep.input_descriptors[0].validation?.required}
            errors={errors}
            radioValues={[
              { value: 'true', trad: 'Oui' },
              { value: 'false', trad: 'Non' },
            ]}
            radioDirection="row"
          />
        </Flex>
        <Flex direction="column" rowGap={4}>
          <RadioInput
            title="En cas de défaut de l’entreprise qui porte le projet, est-il possible que vous perdiez tout ou partie de votre investissement ?"
            control={control}
            inputName="INPUT_NAME_DEFAULT_RISK_ACKNOWLEDGMENT"
            isRequired={funnelStep.input_descriptors[1].validation?.required}
            errors={errors}
            radioValues={[
              { value: 'true', trad: 'Oui' },
              { value: 'false', trad: 'Non' },
            ]}
            radioDirection="row"
          />
        </Flex>
        <Flex direction="column" rowGap={4}>
          <RadioInput
            title="Savez-vous que la revente de vos titres n'est pas garantie et peut donc être partielle voire impossible ?"
            control={control}
            inputName="INPUT_NAME_LIQUIDITY_RISK_ACKNOWLEDGMENT"
            isRequired={funnelStep.input_descriptors[2].validation?.required}
            errors={errors}
            radioValues={[
              { value: 'true', trad: 'Oui' },
              { value: 'false', trad: 'Non' },
            ]}
            radioDirection="row"
          />
        </Flex>
        <Flex direction="column" rowGap={4}>
          <RadioInput
            title="Avez-vous conscience du fait que vous ne devez pas investir plus de 10% de votre patrimoine sur des projets de financement participatif ?"
            control={control}
            inputName="INPUT_NAME_MAX_RATIO_ACKNOWLEDGMENT"
            isRequired={funnelStep.input_descriptors[2].validation?.required}
            errors={errors}
            radioValues={[
              { value: 'true', trad: 'Oui' },
              { value: 'false', trad: 'Non' },
            ]}
            radioDirection="row"
          />
        </Flex>
        <Flex w="full" justifyContent="center" columnGap={8}>
          <Button variant="secondary" size="md" onClick={onPrevious}>
            Précédent
          </Button>
          <Button variant="primary" size="md" onClick={handleSubmit(onSubmit)}>
            Suivant
          </Button>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default InvestmentComprehension
