import { Flex, Text, Heading, Button } from '@chakra-ui/react'
import NextLink from 'next/link'

import { s3ImageURL } from '@miimosa/common'
import { Image, toV2Link } from '@miimosa/design-system'

const Ended = ({
  heading,
  text,
  subTexts,
  redirectUrl,
}: {
  heading: string
  text: string
  subTexts?: string
  redirectUrl: string | null
}) => {
  return (
    <Flex width="100%" direction="column" align="center" justify="center" rowGap={6} alignItems="center">
      <Flex direction="column" alignItems="center" w="full" h="100%">
        <Flex width={{ base: '100%', md: '615px' }} height="316px" position="relative">
          <Image
            mt="10"
            src={s3ImageURL('funnel/illus_confirmation.png')}
            fill
            style={{ objectFit: 'cover' }}
            alt="confirmation"
            priority
          />
        </Flex>
        <Flex direction="column" rowGap={4} alignItems="center" px={4}>
          <Heading textAlign="center" whiteSpace="pre-line" width={{ base: '100%', md: '736px' }} size="md">
            {heading}
          </Heading>
          <Text textAlign="center" width={{ base: '100%', md: '736px' }} size="md">
            {text}
          </Text>
          {subTexts && (
            <Heading textAlign="center" whiteSpace="pre-line" width={{ base: '100%', md: '736px' }} size="md">
              {subTexts}
            </Heading>
          )}
        </Flex>
      </Flex>
      <NextLink href={redirectUrl ? toV2Link(redirectUrl, 'fr', 'fr') : '/projects?type=lending'}>
        <Button size="md" variant="primary">
          {redirectUrl ? 'Continuer' : 'Découvrir les projets'}
        </Button>
      </NextLink>
    </Flex>
  )
}

export default Ended
