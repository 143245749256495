import axios, { AxiosResponse } from 'axios'
import { GetServerSidePropsContext } from 'next'
import { setCookie, destroyCookie } from 'nookies'
import qs from 'qs'

import { MIIMOSA_SESSION, MIIMOSA_SESSION_V3 } from '@miimosa/common'
import { getJSONCookie } from '@miimosa/design-system/lib/cookies'
import { V2Session } from '@miimosa/protocol'

import { Result, toResult } from '../api'
import { Client } from '../client'

import { openV2SessionWithoutAxios } from './v2sessionRuntimed'

export type ErrorV3 = {
  code: string
  meta: Record<string, string>
  message: string
}

export const toV3Result: toResult = <T, E>(res?: AxiosResponse<any>, ex?: any): Result<T, E> => {
  if (res !== undefined) {
    if (res.data.hasOwnProperty('code')) {
      return {
        kind: 'error',
        error: res.data,
      }
    } else {
      return {
        kind: 'success',
        data: res.data,
      }
    }
  } else {
    return {
      kind: 'error',
      error: ex?.response?.data || {
        code: 'client_error',
        message: 'client error  + ' + ex?.message,
      },
    }
  }
}

const handleV3JWT = async (fullToken: V2Session.JWT, ctx?: GetServerSidePropsContext, miiV2Cookie?: string) => {
  // if jwt is expired
  if (Date.parse(fullToken.expires_at) < new Date().getTime()) {
    const response = await fetch(`${process.env.NEXT_PUBLIC_SERVER_ENDPOINT_V3}/v2session/v1/RefreshJWT`, {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        refresh_token: fullToken.refresh_token,
      }),
    })
    // if refresh token is valid
    if (response.status == 200) {
      const result: { jwt: V2Session.JWT } = await response.json()
      const newToken = result.jwt
      setCookie(ctx, MIIMOSA_SESSION_V3, JSON.stringify(newToken), {
        path: '/',
        sameSite: 'strict',
        secure: process.env.NODE_ENV === 'production',
      })
      return newToken
    } else {
      // if refresh token is not valid but user still have a v2 session, just ask for a new jwt from v3
      if (miiV2Cookie) {
        const session = await openV2SessionWithoutAxios(decodeURIComponent(miiV2Cookie), undefined)
        if (session && session.jwt) {
          ctx?.res.setHeader(MIIMOSA_SESSION_V3, JSON.stringify(session.jwt))
          setCookie(ctx, MIIMOSA_SESSION_V3, JSON.stringify(session.jwt), {
            path: '/',
            sameSite: 'strict',
            secure: process.env.NODE_ENV === 'production',
          })
          return session.jwt
        }
        // if refresh token was not valid and user does not have v2 session just destroy v3 session
      } else {
        ctx?.res.setHeader(MIIMOSA_SESSION_V3, '')
        destroyCookie(ctx, MIIMOSA_SESSION_V3)
        return undefined
      }
    }
  } else {
    return fullToken
  }
}

function parseHeadersV3Cookie(cookies: string | undefined): V2Session.JWT | undefined {
  let returnedCookie = undefined
  const splittedCookies = cookies?.split(';')
  splittedCookies &&
    splittedCookies.map((cookie) => {
      const splitedCookie = cookie.split('=')
      const splitedCookieCleaned = splitedCookie[0].trim()
      if (splitedCookieCleaned === MIIMOSA_SESSION_V3) {
        returnedCookie = JSON.parse(decodeURIComponent(splitedCookie[1]))
      }
    })
  return returnedCookie
}

function parseHeadersV2Cookie(cookies: string | undefined): string | undefined {
  let returnedCookie = undefined
  const splittedCookies = cookies?.split(';')
  splittedCookies &&
    splittedCookies.map((cookie) => {
      const splitedCookie = cookie.split('=')
      const splitedCookieCleaned = splitedCookie[0].trim()
      if (splitedCookieCleaned === MIIMOSA_SESSION) {
        returnedCookie = splitedCookie[1]
      }
    })
  return returnedCookie
}

export function createV3Client(ctx?: GetServerSidePropsContext): Client {
  let tokenFromServer = parseHeadersV3Cookie(ctx?.req.headers['cookie'])
  if (ctx && ctx.req && ctx.req.headers && ctx.req.headers && ctx.req.headers.jwt_v3) {
    const JWT = ctx.req.headers['jwt_v3'].toString()
    tokenFromServer = JSON.parse(JWT)
  }
  let tokenFromClient = undefined
  if (!tokenFromServer) {
    tokenFromClient = getJSONCookie<V2Session.JWT>(MIIMOSA_SESSION_V3)
  }

  const v2SessionFromServer = parseHeadersV2Cookie(ctx?.req.headers['cookie'])

  let v2SessionFromClient = undefined
  if (!v2SessionFromServer) v2SessionFromClient = getJSONCookie<string>(MIIMOSA_SESSION)

  const v2Session = v2SessionFromServer ? v2SessionFromServer : v2SessionFromClient

  let fullToken: V2Session.JWT | undefined = tokenFromServer ? tokenFromServer : tokenFromClient

  const client: Client = axios.create({
    baseURL: process.env.NEXT_PUBLIC_SERVER_ENDPOINT_V3 || process.env.SERVER_ENDPOINT_V3,
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: 'repeat' })
    },
  }) as Client

  client.metadata = { ctx }

  client.interceptors.request.use(async (config) => {
    if (fullToken) {
      try {
        const response = await handleV3JWT(fullToken, ctx, v2Session)
        fullToken = response
      } catch (error) {
        fullToken = undefined
      }
    }
    if (config.headers) {
      config.headers['Authorization'] = fullToken ? `Bearer ${fullToken.access_token}` : ''
      config.headers['Accept'] = 'application/json'
      config.headers['Content-Type'] = 'application/json'
    }
    return config
  })

  return client
}
