export { default as NaturalOrLegal } from './NaturalOrLegal'
export { default as NaturalOrLegalWithQuestions } from './NaturalOrLegalWithQuestions'
export { default as HeadingIntro } from './HeadingIntro'
export { default as Ended } from './Ended'
export { default as RightBarInformation } from './RightBarInformation'
export { default as RightBarSteps } from './RightBarSteps'
export { default as InvestmentObjectives } from './InvestmentObjectives'
export { default as InvestmentExperiences } from './InvestmentExperiences'
export { default as InvestmentComprehension } from './InvestmentComprehension'
export { default as InvestmentSimulation } from './InvestmentSimulation'
export { default as WarningModal } from './WarningModal'
export { default as RadioInput } from './RadioInput'
export { default as CheckboxInput } from './CheckboxInput'
export { default as AmountInput } from './AmountInput'
