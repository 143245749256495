import { Flex, Heading, Text } from '@chakra-ui/react'
import { ErrorMessage } from '@hookform/error-message'
import { Control, Controller, FieldErrorsImpl } from 'react-hook-form'
import NumberFormat from 'react-number-format'

import { PSFPFormValues } from '@helpers/psfp/types'

interface Props {
  title: string
  text?: string
  errors: Partial<FieldErrorsImpl<PSFPFormValues>>
  inputName: keyof PSFPFormValues
  isRequired?: boolean
  control: Control<PSFPFormValues>
  placeholder: string
  min: number
  max: number
}

const AmountInput = ({ title, text, errors, inputName, isRequired, control, placeholder, min, max }: Props) => {
  const requiredMsg = "Ce champ est requis pour passer à l'étape suivante"

  const isTooHigh = (value: string | string[]) => {
    let savedValue = value as string
    savedValue = savedValue.replace(/\s/g, '')
    return Number(savedValue) < Number(max)
  }
  return (
    <>
      <Flex direction="column" rowGap={1}>
        <Heading size="2xs">{title}</Heading>
        {text && <Text size="sm">{text}</Text>}
      </Flex>
      <Controller
        control={control}
        name={inputName}
        rules={{
          required: isRequired && requiredMsg,
          min: {
            value: min,
            message: `Le montant doit être supérieur à ${min}`,
          },
          // max can not be used because of number format sending value as string instead of number...
          validate: { isTooHigh },
        }}
        render={({ field }) => (
          <Flex
            direction="row"
            maxW="400px"
            borderBottom="1px solid black"
            justifyContent="space-between"
            alignItems="center"
            ref={field.ref}
          >
            <NumberFormat
              {...field}
              value={field.value as string}
              decimalScale={0}
              thousandSeparator={' '}
              displayType="input"
              placeholder={placeholder}
              style={{ width: '100%', outline: 'none' }}
              allowNegative={false}
              inputMode="numeric"
            />
            <Text color="dark_green">€</Text>
          </Flex>
        )}
      />
      <ErrorMessage errors={errors} name={inputName} as={<Text color="red" />} />
      {errors && errors[inputName] && errors[inputName]?.type === 'isTooHigh' && (
        <Text color="red">Le montant doit être inférieur à {max}</Text>
      )}
    </>
  )
}

export default AmountInput
