import { Flex, Heading, Text } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'

import { FrontProjectStateType } from '@miimosa/common'
import { Icon } from '@miimosa/design-system'

interface Props {
  projectStatus: FrontProjectStateType
  endsAt: string
}

const CollectEndedInfo = ({ projectStatus, endsAt }: Props) => {
  const { t } = useTranslation('project')
  if (projectStatus == 'funded') {
    return (
      <>
        <Flex direction="column" alignItems="center" my={3}>
          <Flex direction="row" alignItems="center" justifyContent="center">
            <Icon name="MiddleBrush3" size="xs" />
            <Heading size="sm" color="dark_green">
              {t('funded_project')}
            </Heading>
            <Icon name="MiddleBrush3" size="xs" transform="rotate(180deg)" />
          </Flex>
          <Text size="sm" color="main_gray_lighten">{`Le ${new Intl.DateTimeFormat('fr-FR', {
            dateStyle: 'long',
          }).format(new Date(endsAt))}`}</Text>
        </Flex>
      </>
    )
  }
  if (projectStatus == 'failed') {
    return (
      <Flex direction="column" textAlign="center">
        <Heading size="sm" color="lightgray">
          {t('ended_project')}
        </Heading>
        <Text size="xs" color="lightgray">
          {t('ended_refund')}
        </Text>
      </Flex>
    )
  }
  return null
}

export default CollectEndedInfo
