import { Flex, BoxProps, Text, Button } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import { SyntheticEvent, FC } from 'react'

import { toV2Link } from '@miimosa/design-system'
import { usePlatform } from '@miimosa/design-system'

import { Image } from '@components'

interface Props {
  onClick?: (e: SyntheticEvent) => void
  onClickCard?: (e: SyntheticEvent) => void
  amount: number
  quantity: number
  description: string
  imageFileName?: string | null
  rewardId: number
  title: string
  isAvailable: boolean
  isPreview?: boolean
  cartQuantity?: number
  linkTo?: string
}

const Reward: FC<Props & BoxProps> = ({
  amount,
  description,
  imageFileName,
  rewardId,
  title,
  onClickCard,
  onClick,
  isPreview,
  isAvailable,
  linkTo,
}) => {
  const { t } = useTranslation('common')
  const { platform, lang } = usePlatform()

  const ctaString = (!isAvailable && t('outOfStock')) || t('addToCart')

  const setHrefForReward = () => {
    if (linkTo) {
      const href =
        rewardId > 0 ? toV2Link(`${linkTo}?reward_id=${rewardId}`, platform, lang) : toV2Link(linkTo, platform, lang)
      return (location.href = href)
    } else {
      return null
    }
  }

  return (
    <Flex
      cursor={(onClickCard && 'pointer') || ((isAvailable || isPreview) && 'default') || 'not-allowed'}
      direction="column"
      position="relative"
      border="solid 1px"
      borderRadius="4px"
      borderColor="light_gray"
      bgColor="white"
      width={{ base: '100%', md: '390px', lg: '390px' }}
      height={{ base: 'fit-content', md: 'fit-content' }}
      px="8"
      py="8"
      {...(onClickCard && { onClick: onClickCard })}
    >
      {imageFileName && (
        <Flex width="100%" bgColor="green" position="relative" minHeight="254px" height="254px">
          <Image src={imageFileName} alt="" layout="fill" objectFit="cover" />
        </Flex>
      )}
      <Text size="2xl" mt="6" maxH="60px" minH="60px" overflow="hidden">
        {title}
      </Text>
      <Text size="md" mt="4" whiteSpace="pre-wrap" maxH="90px" minH="90px" overflow="hidden" textOverflow="ellipsis">
        {description}
      </Text>
      <Flex direction={{ base: 'column', md: 'row' }} mt="6" rowGap="2" align="center" justify="space-around">
        {amount > 0 && <Text size="lg">{amount} €</Text>}
        {linkTo ? (
          <Button onClick={() => setHrefForReward()} variant="primary" disabled={!isAvailable || isPreview} size="sm">
            {ctaString}
          </Button>
        ) : (
          <Button variant="primary" onClick={onClick} disabled={!isAvailable || isPreview} size="sm">
            {ctaString}
          </Button>
        )}
      </Flex>
    </Flex>
  )
}

export default Reward
