import { Divider, Flex, Heading, ListItem, Text, UnorderedList } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'

type Props = {
  benefitsList?: string | null
  risksList?: string | null
}

const LendingRisk = ({ benefitsList, risksList }: Props) => {
  const { t } = useTranslation('project')
  const shouldDisplayLendingRisk = benefitsList || risksList

  if (!shouldDisplayLendingRisk) {
    return null
  }

  return (
    <Flex
      direction="column"
      px="6"
      py="8"
      mt={5}
      rowGap={6}
      bgColor="white"
      w="full"
      minW="330px"
      maxW={{ base: 'full', lg: '330px' }}
      border="solid 1px"
      borderRadius="4px"
      borderColor="light_gray"
    >
      <Heading size="sm">Analyse MiiMOSA</Heading>
      {benefitsList &&
        riskNode({
          title: t('collect.lending.benefits'),
          values: benefitsList?.split(';'),
        })}
      {risksList &&
        riskNode({
          title: t('collect.lending.risks'),
          values: risksList?.split(';'),
        })}
    </Flex>
  )
}

const riskNode = ({ title, values }: { title: string; values: string[] }) => {
  return (
    <Flex direction="column" width="100%" height="fit-content">
      <Text size="md" fontWeight="bold">
        {title}
      </Text>
      <Divider mb={4} borderColor="main_gray" />
      <Flex direction="column" rowGap={3}>
        <UnorderedList>
          {values.map((value, i) => (
            <ListItem key={i}>
              <Text size="md">{value}</Text>
            </ListItem>
          ))}
        </UnorderedList>
      </Flex>
    </Flex>
  )
}

export default LendingRisk
