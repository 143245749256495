import type { Projects } from '@miimosa/protocol'

import { buildV2ImagePath } from '../../helpers'
import type { FrontDescriptionType, FullProject } from '../../types'

export const v3DescriptionToFront = ({
  short_description,
  usage_description,
  image_file_name,
  financial_analysis,
  inc_exp,
  financial_introduction,
  risks_list,
  benefits_list,
  fees_list,
  video_url,
  facebook_url,
  instagram_url,
  twitter_url,
  linkedin_url,
  website_url,
  projectId,
  total_funding_amount,
  deposit_amount,
  equity_amount,
  mezzanine_amount,
  bank_loan_amount,
  grant_amount,
  self_financing_amount,
  other_funding_amount,
  impact_jobs,
  impact_home,
  impact_surface,
  ...rest
}: Projects.Description & { projectId: number }): FrontDescriptionType => {
  return {
    shortDescription: short_description,
    usageDescription: usage_description,
    imageFileName: buildV2ImagePath({
      kind: 'project',
      filename: image_file_name || '',
      id: projectId,
      size: 'high',
    }),
    smallImageFileName: buildV2ImagePath({
      kind: 'project',
      filename: image_file_name || '',
      id: projectId,
      size: 'card',
    }),
    financialAnalysis: financial_analysis,
    incExp: inc_exp,
    financialIntroduction: financial_introduction,
    risksList: risks_list,
    benefitsList: benefits_list,
    feesList: fees_list,
    videoUrl: video_url || null,
    facebookUrl: facebook_url || null,
    instagramUrl: instagram_url || null,
    twitterUrl: twitter_url || null,
    linkedinUrl: linkedin_url || null,
    websiteUrl: website_url || null,
    totalFundingAmount: total_funding_amount,
    depositAmount: deposit_amount || null,
    equityAmount: equity_amount || null,
    mezzanineAmount: mezzanine_amount || null,
    bankLoanAmount: bank_loan_amount || null,
    grantAmount: grant_amount || null,
    selfFinancingAmount: self_financing_amount || null,
    otherFundingAmount: other_funding_amount || null,
    impactJobs: impact_jobs || 0,
    impactSurface: impact_surface || 0,
    impactHome: impact_home || 0,
    ...rest,
  }
}

export const v2DescriptionToFront = (projectResponse: FullProject): FrontDescriptionType => {
  return {
    shortDescription: projectResponse.short_description,
    description: '',
    usageDescription: '',
    place: projectResponse.place,
    imageFileName: projectResponse.image_url,
    financialAnalysis: null,
    pnl: null,
    balance: null,
    incExp: null,
    financialIntroduction: null,
    risksList: null,
    benefitsList: null,
    feesList: null,
    videoUrl: null,
    facebookUrl: null,
    twitterUrl: null,
    instagramUrl: null,

    depositAmount: null,
    equityAmount: null,
    mezzanineAmount: null,
    bankLoanAmount: null,
    grantAmount: null,
    selfFinancingAmount: null,
    otherFundingAmount: null,
    totalFundingAmount: 0,
    impactJobs: 0,
    impactSurface: 0,
    impactHome: 0,
  }
}
