import { BoxProps, Menu, MenuButton, useBreakpointValue, useDisclosure } from '@chakra-ui/react'
import { FC, useContext } from 'react'

import type { FrontRewardType } from '@miimosa/common/types'
import { useV2User } from '@miimosa/design-system'

import CartButton from './CartButton'
import { CartContext } from './CartContext'
import CartDrawer from './CartDrawer'
import CartMenuData from './CartMenuData'

interface Props {
  title: string
  rewardsData: FrontRewardType[]
  slug: string
}

const Cart: FC<Props & BoxProps> = ({ title, slug, rewardsData }) => {
  const { cartData } = useContext(CartContext)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const user = useV2User('mii_fr')
  const totalItems = cartData?.rewards?.reduce((acc, { quantity }) => acc + quantity, 0)
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: 'md' })

  if (isMobile) {
    return (
      <>
        {isOpen && (
          <CartDrawer
            slug={slug}
            rewardsData={rewardsData}
            user={user!}
            title={title}
            isOpen={isOpen}
            onClose={onClose}
          />
        )}
        <CartButton onClick={onOpen} totalItems={totalItems} />
      </>
    )
  }

  return (
    <Menu>
      {({ isOpen }) => (
        <>
          <MenuButton as={CartButton} totalItems={totalItems} />
          {isOpen && <CartMenuData slug={slug} rewardsData={rewardsData} user={user!} title={title} />}
        </>
      )}
    </Menu>
  )
}

export default Cart
