import {
  Modal,
  Flex,
  Button,
  ModalOverlay,
  ModalContent,
  Text,
  ModalBody,
  ModalCloseButton,
  BoxProps,
  IconButton,
  Input,
} from '@chakra-ui/react'
import { captureException } from '@sentry/nextjs'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { FC, useContext, useEffect, useRef, useState } from 'react'

import type { FrontRewardType } from '@miimosa/common/types'
import { Icon, usePrevious } from '@miimosa/design-system'
import { UserContext } from '@miimosa/design-system/components'

import { Info, Image } from '@components'
import { CartContext } from '@components/Cart/CartContext'
interface Props {
  isOpen: boolean
  onClose: () => void
  isPreview?: boolean
  reward: FrontRewardType
}

const RewardModal: FC<Props & BoxProps> = ({ isOpen, isPreview, reward, onClose }) => {
  const { t } = useTranslation('merchant')
  const { t: tCommon } = useTranslation('common')
  const inputRef = useRef<HTMLInputElement>(null)
  const { cartData, getRewardInCart, addToCart } = useContext(CartContext)

  const {
    imageFileName,
    title,
    id: rewardId,
    description,
    amount,
    shippingDetails,
    availableQuantity,
    takenQuantity,
    shippingMode,
    deliveryAt,
  } = reward || {}

  const cartReward = getRewardInCart(rewardId)
  const [quantity, setQuantity] = useState<number>(cartReward ? cartReward.quantity : 1)
  const willDeleteArticle = cartReward && cartReward.quantity && quantity === 0
  const { push, asPath } = useRouter()

  const user = useContext(UserContext)
  const previousCartReward = usePrevious(cartReward)

  useEffect(() => {
    // initialize quantity when available only when cartReward is defined
    if (!previousCartReward && cartReward) {
      setQuantity(cartReward.quantity)
    }
  }, [cartReward, previousCartReward])

  const submitRewardQuantity = () => {
    if (!user) {
      const url = { pathname: '/authenticate', query: { return_url: asPath } }
      return push(url)
    }

    addToCart({ cart_id: cartData?.uuid, reward_id: rewardId, quantity }).then(
      () => onClose(),
      (error: string) => captureException(error)
    )
  }

  const handleNumberInput = (e: { preventDefault: () => void }) => {
    e.preventDefault()
    const quantityInput = inputRef?.current

    if (!quantityInput) return null

    const valueAsNum = quantityInput.valueAsNumber
    if (isNaN(valueAsNum)) {
      return setQuantity(0)
    }

    return setQuantity(valueAsNum)
  }

  if (!reward || !isOpen) {
    return null
  }

  const data = [
    ...(availableQuantity > 0 ? [{ title: tCommon('left_quantity'), value: [availableQuantity - takenQuantity] }] : []),
    ...(deliveryAt
      ? [
          {
            title: tCommon('estimated_shipping'),
            value: [new Intl.DateTimeFormat('fr-FR', { month: 'long', year: 'numeric' }).format(new Date(deliveryAt))],
          },
        ]
      : []),
    ...(shippingMode && shippingMode !== 'no_shipping'
      ? [{ title: tCommon('shipping'), value: [tCommon(shippingMode)], withIcon: true, iconDetails: shippingDetails }]
      : []),
  ]

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay bg="rgba(0, 63, 44, 0.25)" />
      <ModalContent minWidth={{ base: '100%', md: imageFileName ? '1060px' : 'fit-content' }}>
        <ModalCloseButton />
        <ModalBody px={{ base: 5, md: '8' }} py={{ base: 12, md: '8' }}>
          <Flex direction={{ base: 'column', md: 'row' }} columnGap="8" align="center">
            {imageFileName && (
              <Flex position="relative" width={{ base: '100%', md: '500px' }} height={{ base: '280px', md: '500px' }}>
                <Image src={imageFileName} alt="" objectFit="cover" layout="fill" />
              </Flex>
            )}
            <Flex direction="column" mt={{ base: 6, md: 0 }} width={{ base: '100%', md: '440px' }}>
              <Text size="2xl">{title}</Text>
              <Text size="md" mt="4" whiteSpace="pre-line">
                {description}
              </Text>
              <Flex align="center" mt="8">
                <Text size="md">{t('quantity')}</Text>
                <Flex bgColor="light_gray" align="center" ml="6" p="1" borderRadius="100px">
                  <IconButton
                    aria-label="subtract"
                    isDisabled={quantity === 0}
                    onClick={() => setQuantity(quantity - 1)}
                  >
                    <Icon cursor="pointer" name="Minus" bgColor="dark_green" color="white" size="5xs" />
                  </IconButton>
                  <Input
                    ref={inputRef}
                    value={quantity}
                    type="number"
                    width={quantity > 99 ? '40px' : '30px'}
                    textAlign="center"
                    variant="flushed"
                    onKeyDown={(evt) => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
                    onChange={handleNumberInput}
                    mx="2"
                  />
                  <IconButton aria-label="add" onClick={() => setQuantity(quantity + 1)}>
                    <Icon cursor="pointer" name="Add" bgColor="dark_green" color="white" size="5xs" />
                  </IconButton>
                </Flex>
              </Flex>
              <Info mt="8" data={data} isGrey />
              <Flex mt="8" align="center" gap={6} direction={{ base: 'column', md: 'row' }} justify="space-between">
                <Text size="lg" minWidth="10%">
                  {amount} €
                </Text>
                <Button
                  variant="primary"
                  bgColor={willDeleteArticle ? 'orange' : 'dark_green'}
                  width="350px"
                  size="md"
                  onClick={submitRewardQuantity}
                  disabled={isPreview}
                  isDisabled={isPreview}
                >
                  {willDeleteArticle ? tCommon('deleteFromCart') : tCommon('addToCart')}
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default RewardModal
