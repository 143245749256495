import { Box, Text, Button, Flex, BoxProps } from '@chakra-ui/react'
import NextLink from 'next/link'
import { useRouter } from 'next/router'

import { ChevronRight, BrushHeading, ChevronDown } from '@miimosa/design-system/components/icons'

import Link from './Link'
import { NavItem } from './utils'

interface MenuItemProps extends NavItem {
  isOpen?: boolean
  currentRoute: string
}

export const DotColor = ({ dotColor, ...rest }: { dotColor: string } & BoxProps) => (
  <Flex width="11px" height="11px" mr="7px" borderRadius="50%" bgColor={dotColor} {...rest} />
)

const DesktopMenuItem = ({
  label,
  dotColor,
  markColor,
  isOpen,
  route,
  rightDropdown,
  Icon,
  description,
  links,
  currentRoute,
  ...props
}: MenuItemProps) => {
  const router = useRouter()

  return (
    <Box {...props} zIndex="1">
      {!links ? (
        <NextLink href={route}>
          <Button
            ml="40px"
            alignItems="center"
            px="4px"
            h="100%"
            textAlign="left"
            whiteSpace="normal"
            lineHeight="22px"
            variant="ghost"
          >
            <Flex position="relative">
              <Text size="sm" mr="4px">
                {label}
              </Text>
              {markColor && <BrushHeading position="absolute" top="15px" left="0px" width="100%" color={markColor} />}
            </Flex>
          </Button>
        </NextLink>
      ) : (
        <>
          <Button
            ml="40px"
            alignItems="center"
            px="4px"
            h="100%"
            textAlign="left"
            whiteSpace="normal"
            lineHeight="22px"
            variant="ghost"
            onClick={() => router.push(route)}
          >
            {dotColor && <DotColor dotColor={dotColor} />}
            <Flex position="relative">
              <Text size="sm" mr="4px">
                {label}
              </Text>
              {markColor && <BrushHeading position="absolute" top="15px" left="0px" width="100%" color={markColor} />}
            </Flex>

            {links && <ChevronDown width="7px" />}
          </Button>

          {links && (
            <Flex
              w="610px"
              bg="bg.0"
              top="calc(100% - 10px)"
              boxShadow="0px 4px 4px rgba(42, 42, 42, 0.12)"
              right={rightDropdown ? '0' : undefined}
              left={rightDropdown ? undefined : '40px'}
              justify="space-between"
              pos="absolute"
              visibility={isOpen ? 'visible' : 'hidden'}
              pt="29px"
              px="38px"
              pb="34px"
              direction="row"
              bgColor="white"
              borderRadius="4px"
              zIndex={1002}
            >
              <Flex direction="column" justify="flex-start">
                {links.map(({ href, text, route, withBrush }) => {
                  const isActive = route === currentRoute || href === currentRoute

                  return (
                    <Link
                      href={href}
                      route={route}
                      _hover={{ color: dotColor }}
                      key={text}
                      size="md"
                      lineHeight="1.75rem"
                      _focus={{
                        outline: 0,
                        fontWeight: 'bold',
                        boxShadow: 'none',
                      }}
                      color="second_gray"
                      my="1"
                    >
                      <Flex align="center">
                        {isActive && <ChevronRight width="10px" height="8px" color={dotColor} mt="1" mr="6px" />}
                        <Text
                          size="md"
                          _after={{
                            content: '""',
                            backgroundImage: '/images/brush-heading.svg',
                            backgroundRepeat: 'no-repeat',
                            height: '0.5em',
                            display: withBrush ? 'block' : 'none',
                            backgroundSize: 'contain',
                            width: '100%',
                            position: 'relative',
                          }}
                        >
                          {text}
                        </Text>
                      </Flex>
                    </Link>
                  )
                })}
              </Flex>
              <Flex direction="column" justify="flex-start">
                {Icon && <Icon mb="18px" />}
                <Flex w="230px" direction="column">
                  <Link
                    href={links[0].href}
                    route={links[0].route}
                    mb="6px"
                    size="sm"
                    fontSize="0.75rem"
                    lineHeight="15.6px"
                    _focus={{
                      outline: 0,
                      boxShadow: 'none',
                    }}
                    color="second_gray"
                  >
                    {description}
                  </Link>
                </Flex>
              </Flex>
            </Flex>
          )}
        </>
      )}
    </Box>
  )
}

export default DesktopMenuItem
