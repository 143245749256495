import { Box, Flex, FlexProps } from '@chakra-ui/react'
import { ReactElement } from 'react'

import { Image } from './index'

interface Props extends FlexProps {
  imageSize?: number
  imageSrc?: string
  children: ReactElement
}

const AvatarCard = ({ imageSize = 190, imageSrc, children, ...props }: Props) => {
  return (
    <Flex {...props} direction={{ base: 'column', md: 'row' }} align="center">
      <Box w={`${imageSize}px`} h={`${imageSize}px`} flex="none" rounded="full" my="8px" zIndex="1" position="relative">
        {imageSrc && <Image src={imageSrc} alt="" fill />}
      </Box>
      <Box
        ml={{ md: `-${imageSize / 2}px` }}
        mt={{ base: `-${imageSize / 2}px`, md: 0 }}
        pl={{ base: '8px', md: `${32 + imageSize / 2}px` }}
        pt={{ base: `${24 + imageSize / 2}px`, md: '32px' }}
        pb={[6, 8]}
        pr={[4, 24]}
        bg="white"
        boxShadow="md"
      >
        {children}
      </Box>
    </Flex>
  )
}

export default AvatarCard
