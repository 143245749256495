import { GetServerSidePropsContext } from 'next'

import { FunnelAPI } from '@miimosa/api'
import { Leads } from '@miimosa/protocol'

const previousPSFPFunnel = async (params: Leads.PreviousRequest, context?: GetServerSidePropsContext) => {
  const funnelAPI = new FunnelAPI(context)
  const response = await funnelAPI.previousFunnel(params)
  if (response.kind == 'success') {
    return response.data
  }
}

export default previousPSFPFunnel
