import { BoxProps } from '@chakra-ui/react'
import { Globals } from '@stitches/react/types/css'
import NextImage from 'next/image'
import { FC } from 'react'

const myLoader = ({ src }: { src: string; width: number; quality?: number }) => src

interface Props extends BoxProps {
  src: string
  alt: string
  width?: number | `${number}`
  height?: number | `${number}`
  quality?: number
  objectFit?: Globals | 'contain' | 'cover' | 'fill' | 'none' | 'scale-down'
  objectPosition?: string
  className?: string
  priority?: boolean
  layout?: 'fixed' | 'fill' | 'intrinsic' | 'responsive' | undefined
  color?: any
  placeholder?: any
  fill?: any
}

const Image: FC<Props> = (props) => <NextImage loader={myLoader} unoptimized={true} {...props} />

export default Image
