import { FlexProps, Flex } from '@chakra-ui/react'
import { FC, ReactNode } from 'react'

interface Props {
  children: ReactNode
}

const FullWidthLeftColumn: FC<Props & FlexProps> = ({ children, ...props }) => {
  return (
    <Flex
      h={{ base: '100%', lg: 'calc(100vh - 64px)' }}
      p={10}
      align="flex-end"
      flex={'2 1 auto'}
      bgColor="white"
      direction="column"
      justify="space-between"
      maxW={'1000px'}
      overflowY="scroll"
      {...props}
    >
      {children}
    </Flex>
  )
}

export default FullWidthLeftColumn
