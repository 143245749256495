import { Flex, Heading, Link } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import NextLink from 'next/link'

import type { FrontSearchProjectType } from '@miimosa/common/types'
import { Carousel } from '@miimosa/design-system'

import ProjectCard from '@components/ProjectCard'

type Props = {
  projects: FrontSearchProjectType[]
  itemsPerPage: number
}

const NewProjectList = ({ projects, itemsPerPage }: Props) => {
  const { t } = useTranslation('project')

  return (
    <Flex direction="column" w="full" my={20} px={10} justifyContent="center">
      <Flex w="full" direction="row" alignItems="center" justifyContent="space-between" mb={10}>
        <Heading>{t('new_project')}</Heading>
        <NextLink href="/projects">
          <Link as="div" variant="primary">
            {t('see_all')}
          </Link>
        </NextLink>
      </Flex>
      <Carousel
        isDark
        draggable
        navBottom
        data={projects}
        SlideComponent={renderCards}
        itemsPerPage={itemsPerPage}
        slidesToScroll={1}
        centerMode={false}
      />
    </Flex>
  )
}

const renderCards = (p: FrontSearchProjectType, i: number) => (
  <Flex key={i} mx={{ base: 2, md: 0 }}>
    <ProjectCard
      imageFileName={p.imageFileName}
      slug={p.slug}
      title={p.title}
      teasing={p.teasing}
      place={p.place}
      collectType={p.collect?.type || 'donation'}
      progress={p.collect?.progress || 0}
      collectedAmount={p.collect?.collectedAmount || 0}
      state={p.state}
      goalAmount={p.collect?.goalAmount || 0}
      endsAt={p.collect?.endsAt}
      goalQuantity={p.collect?.goalQuantity || 0}
      collectedQuantity={p.collect?.collectedQuantity || 0}
      interestsRatio={p.collect?.interestsRatio}
      goalType={p.collect?.goalType || 'amount'}
      userFullName={p.userFullName}
      avatarFileName={p.avatarFileName}
      labels={p.labels}
    />
  </Flex>
)

export default NewProjectList
