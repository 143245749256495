import { Flex, Heading, RadioGroup, Radio, Text } from '@chakra-ui/react'
import { ErrorMessage } from '@hookform/error-message'
import { Control, Controller, FieldErrorsImpl } from 'react-hook-form'

import { PSFPFormValues } from '@helpers/psfp/types'

interface RadioProps {
  title: string
  control: Control<PSFPFormValues>
  isRequired?: boolean
  inputName: keyof PSFPFormValues
  radioValues: { value: string; trad: string }[]
  errors: Partial<
    FieldErrorsImpl<{
      INPUT_NAME_SECTORS_OF_INTEREST: string[]
      INPUT_NAME_RISK_APPETITE: string
      INPUT_NAME_INVESTMENT_HORIZON: string[]
      INPUT_NAME_STRESS_FOR_CASH: string
      INPUT_NAME_CROWDFUNDING_EXPERIENCE: string
    }>
  >
  radioDirection?: 'column' | 'row'
}

const RadioInput = ({
  title,
  control,
  isRequired,
  inputName,
  errors,
  radioValues,
  radioDirection = 'column',
}: RadioProps) => {
  const requiredMsg = "Ce champ est requis pour passer à l'étape suivante"

  return (
    <Flex direction="column" rowGap={2}>
      <Flex direction="column" rowGap={1}>
        <Heading size="2xs">{title}</Heading>
      </Flex>
      <Flex direction="column" rowGap={3}>
        {/* Controller Needed to setValue from Modal */}
        <Controller
          control={control}
          name={inputName}
          rules={{
            required: isRequired && requiredMsg,
          }}
          render={({ field }) => (
            <RadioGroup {...field} value={field.value as string}>
              <Flex direction={radioDirection} rowGap={3} columnGap={3}>
                {radioValues.map((radio) => (
                  <Radio value={radio.value} colorScheme="radio" key={`radio_${radio.value}`}>
                    {radio.trad}
                  </Radio>
                ))}
              </Flex>
            </RadioGroup>
          )}
        />
        <ErrorMessage errors={errors} name={inputName} as={<Text color="red" />} />
      </Flex>
    </Flex>
  )
}

export default RadioInput
