import { Flex, Text, BoxProps, Tooltip, Divider } from '@chakra-ui/react'
import { FC, ReactNode } from 'react'

import { Icon } from '@miimosa/design-system'
interface Props {
  isGrey?: boolean
  data: { title: string; value: ReactNode[]; withIcon?: boolean; iconDetails?: string }[]
}

const Info: FC<Props & BoxProps> = ({ isGrey = false, data, ...props }) => {
  return (
    <Flex direction="column" width="100%" {...props}>
      {data &&
        data.map((d, i) => (
          <Flex direction="column" key={`title_${i}`} w="full" rowGap={2} columnGap={2}>
            <Flex
              direction="row"
              align="center"
              width="100%"
              justify="space-between"
              color={isGrey ? 'main_gray_lighten' : 'main_gray'}
            >
              <Text size="sm">{d.title}</Text>

              <Flex direction="column" alignItems="flex-end">
                {d.value?.map((v, i) => (
                  <Flex direction="row" key={`value_${i}`} columnGap={2} alignItems="center">
                    {d.withIcon && d.iconDetails && (
                      <Tooltip
                        label={d.iconDetails}
                        fontSize="sm"
                        hasArrow
                        bg="white"
                        border="1px solid"
                        borderColor="dark_green"
                        placement="top"
                        arrowShadowColor="#003F2C"
                        color="black"
                        borderRadius="4px"
                      >
                        <span>
                          <Icon name="InformationFilled" color="white" stroke="main_gray_lighten" size="5xs" />
                        </span>
                      </Tooltip>
                    )}
                    <Text size="sm">{v}</Text>
                  </Flex>
                ))}
              </Flex>
            </Flex>
            <Divider borderColor="border" opacity={1} />
          </Flex>
        ))}
    </Flex>
  )
}
export default Info
