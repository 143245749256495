import { AspectRatio, Box, Flex } from '@chakra-ui/react'
import { useState } from 'react'
import { FaPlay } from 'react-icons/fa'

import {
  buildV2ImagePath,
  getValidVideoId,
  vimeoBaseUrl,
  vimeoVideoRegex,
  youtubeBaseUrl,
  youtubeIdVideoRegex,
  youtubeVideoRegex,
} from '@miimosa/common'
import { Image } from '@miimosa/design-system'

interface Props {
  videoUrl?: string | null
  imageFileName?: string | null
  projectId: number
}

const ProjectImageOrVideo = ({ videoUrl, imageFileName, projectId }: Props) => {
  const isYoutubeVideo = youtubeVideoRegex.test(videoUrl || '')

  const validVideoId = getValidVideoId({
    videoUrl: videoUrl || '',
    regexp: isYoutubeVideo ? youtubeIdVideoRegex : vimeoVideoRegex,
  })

  const youtubeVideo = `${youtubeBaseUrl}${validVideoId}?autoplay=1`
  const vimeoVideo = `${vimeoBaseUrl}${validVideoId}?autoplay=1`

  const [videoIsPlaying, setVideoIsPlaying] = useState(false)

  return (
    <>
      {videoUrl && validVideoId && videoIsPlaying && (
        <AspectRatio ratio={4 / 3} w="100%">
          <Box
            as="iframe"
            src={isYoutubeVideo ? youtubeVideo : vimeoVideo}
            allowFullScreen
            zIndex={2}
            w="100%"
            allow="autoplay"
          />
        </AspectRatio>
      )}
      {imageFileName && !videoIsPlaying && (
        <Flex alignItems="center" justifyContent="center" direction="column" w="full">
          <Image
            style={{ objectFit: 'cover' }}
            aspectRatio={4 / 3}
            bg="dark_green"
            w="100%"
            maxW={{ base: '800px', lg: '100%' }}
            fill
            alt=""
            priority
            src={buildV2ImagePath({
              filename: imageFileName || '',
              id: projectId,
              kind: 'project',
              size: 'high',
            })}
          />
          {videoUrl && validVideoId && (
            <Flex
              w="50px"
              h="50px"
              backgroundColor="black"
              zIndex={2}
              alignItems="center"
              justifyContent="center"
              _hover={{ backgroundColor: 'dark_green', cursor: 'pointer' }}
              onClick={() => setVideoIsPlaying(true)}
              position="absolute"
            >
              <FaPlay color="white" />
            </Flex>
          )}
        </Flex>
      )}
    </>
  )
}

export default ProjectImageOrVideo
