import { Flex, Box, Text } from '@chakra-ui/react'
import NextLink from 'next/link'
import { FC } from 'react'

import { Image } from '@miimosa/design-system/components'

interface Props {
  href: string
  imageUrl: string
  title: string
  height?: number
  isLight?: boolean
}

const ImageWithTitle: FC<Props> = ({ imageUrl, title, height = 225, isLight, href }) => {
  const color = isLight ? 'white' : 'main_gray'

  return (
    <NextLink href={href}>
      <Flex
        position="relative"
        width="auto"
        minHeight={height}
        direction="column"
        alignItems="flex-start"
        overflow="hidden"
        justifyContent="flex-end"
        textAlign="left"
        borderRadius="4px"
      >
        <Image src={imageUrl} fill style={{ objectFit: 'cover' }} alt={title} quality={100} />
        <Box zIndex="1">
          <Text pb="12px" textTransform="uppercase" size="xl" color={color} px="24px">
            {title}
          </Text>
        </Box>
      </Flex>
    </NextLink>
  )
}

export default ImageWithTitle
