import { Flex, BoxProps } from '@chakra-ui/react'

import { s3ImageURL } from '@miimosa/common'
import { Image } from '@miimosa/design-system'
import { BCorp } from '@miimosa/design-system/components/icons'

const Externals = (props: BoxProps) => (
  <Flex direction="row" {...props}>
    <BCorp color="white" />
    <Flex w="44px" h="44px" position="relative">
      <Image
        src={s3ImageURL('externals/financement_participatif.png')}
        alt="Plateforme financement participatif"
        fill
        style={{ objectFit: 'contain' }}
      />
    </Flex>
    <Flex w="44px" h="44px" position="relative">
      <Image
        src={s3ImageURL('externals/croissance_verte.png')}
        alt="Croissance Verte"
        fill
        style={{ objectFit: 'contain' }}
      />
    </Flex>
    <Flex w="70px" h="24px" position="relative">
      <Image src={s3ImageURL('externals/lfd.png')} alt="la ferme digital logo" fill style={{ objectFit: 'contain' }} />
    </Flex>
  </Flex>
)

export default Externals
