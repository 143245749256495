import { Box, BoxProps, Flex, Heading, Text, FlexProps } from '@chakra-ui/react'
import { useTranslation, Trans } from 'next-i18next'

import Carrot from '@miimosa/design-system/components/icons/manifest/Carrot'
import Pin from '@miimosa/design-system/components/icons/manifest/Pin'
import { DirectusManifestResponse } from '@miimosa/directus'

// HLine is a line that is horizontal on desktop and vertical on mobile
const HLine = ({ ...props }: BoxProps) => (
  <Box
    h={{ base: '80px', md: '2px' }}
    w={{ base: '2px', md: 'auto' }}
    bg="dark_green"
    flex={{ base: 'auto', md: '1 1 0%' }}
    mx={{ base: 0, md: 8 }}
    my={{ base: 8, md: 0 }}
    {...props}
  />
)

// VLine is a vertical line on desktop that has absolute positioning
// It is not displayed on mobile
const VLine = ({ ...props }: BoxProps) => (
  <Box
    display={{ base: 'none', md: 'block' }}
    position="absolute"
    w="2px"
    bg="dark_green"
    h="100%"
    top={0}
    {...props}
  />
)

const lineProps: FlexProps = {
  direction: { base: 'column', md: 'row' },
  align: 'center',
  w: 'full',
}

const whitespaceProps: BoxProps = {
  whiteSpace: { base: 'normal', md: 'pre' },
}

interface Props extends BoxProps {
  directusData: Partial<DirectusManifestResponse>
}

const Team = ({ directusData, ...props }: Props) => {
  const { t } = useTranslation('manifest', { keyPrefix: 'team' })

  return (
    <Flex direction="column" align="center" width="100%" {...props}>
      <Heading as="h2" size="lg" mb={{ base: 16, md: 0 }}>
        {t('staff')}
      </Heading>
      <Flex {...lineProps} mt={{ base: 0, md: 16 }}>
        <Flex align="center">
          <Text fontSize="96px" fontWeight="bold">
            {directusData.teamFervent || 43}
          </Text>
          <Text size="lg" fontWeight="500" {...whitespaceProps}>
            <Trans t={t} i18nKey="employees" />
          </Text>
        </Flex>

        <HLine />

        <Box position="relative" pl="60px">
          <Text
            transform="translate(0,-50%) rotate(-90deg)"
            color="green"
            fontSize="36px"
            fontWeight="bold"
            position="absolute"
            left="0"
            top="50%"
          >
            {directusData.teamHoursPhone || '160h'}
          </Text>
          <Text size="lg" fontWeight="500" {...whitespaceProps}>
            <Trans t={t} i18nKey="phone" />
          </Text>
        </Box>
      </Flex>

      <Flex
        {...lineProps}
        position="relative"
        direction={{ base: 'column-reverse', md: 'row' }}
        mt={{ base: 0, md: 16 }}
      >
        <HLine display={{ base: 'none', md: 'block' }} />
        <Flex maxWidth="190px">
          <Carrot color="#FF6C4A" w="50px" h="90px" />
          <Flex direction="column" align="start">
            <Text fontSize="56px" lineHeight={1} fontWeight="bold">
              {directusData.teamWorkFarms || '46'}%
            </Text>
            <Text size="lg" fontWeight="500">
              <Trans t={t} i18nKey="farm" />
            </Text>
          </Flex>
        </Flex>
        <HLine />
        <Flex direction="column" alignItems="end">
          <Text fontSize="56px" lineHeight={1} fontWeight="bold">
            {directusData.teamFamilyFarmers || '21'}%
          </Text>
          <Text size="lg" fontWeight="500" textAlign="right">
            <Trans t={t} i18nKey="farmers" />
          </Text>
        </Flex>
        <HLine />
        <VLine right={8} transform="translate(0,-50%)" />
        <VLine left={8} transform="translate(0,50%)" />
      </Flex>

      <Flex {...lineProps} position="relative" mt={{ base: 0, md: 16 }}>
        <VLine left={8} transform="translate(0,-50%)" />
        <HLine />
        <Flex direction="column" align="start">
          <Text fontSize="56px" lineHeight={1} fontWeight="bold">
            {directusData.teamBike || 71} %
          </Text>
          <Text size="lg" fontWeight="500">
            <Trans t={t} i18nKey="bike" />
          </Text>
        </Flex>
        <HLine />

        <Flex maxWidth="190px" align="center">
          <Pin color="#3CB591" w="70px" h="93px" />
          <Flex direction="column" align="start" ml="5px">
            <Text fontSize="56px" lineHeight={1} fontWeight="bold" textColor="green">
              {directusData.teamEatLocal || '100'}%<br />
            </Text>
            <Text size="lg" fontWeight="500">
              {t('localFood')}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
export default Team
