import { Flex, Text } from '@chakra-ui/react'
import { FC } from 'react'

import Icon, { IconName } from '@miimosa/design-system/components/Icon'

interface Props {
  iconName: IconName
  title: string
  keyInfo?: string | number
  color: string
}

const KeyInfoItem: FC<Props> = ({ iconName, color, title, keyInfo }) => {
  return (
    <Flex direction="column" align="left">
      <Icon name={iconName} color={color} size="4xs" />
      <Text mt="6px" size="md" color={color} align="left">
        {title}
      </Text>
      <Text color={color} size="lg" align="left">
        {keyInfo}
      </Text>
    </Flex>
  )
}

export default KeyInfoItem
