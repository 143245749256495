import { Box, BoxProps, Flex, Text } from '@chakra-ui/react'
import { ReactNode } from 'react'
import { default as NumberFormat } from 'react-number-format'

interface Props extends BoxProps {
  icon: ReactNode
  title: string
  number: number
}

const CardIndicator = ({ icon, title, number, ...rest }: Props) => {
  return (
    <Flex
      bg="white"
      direction="row"
      borderRadius="4px"
      p="32px"
      justifyContent="space-between"
      alignItems="center"
      {...rest}
    >
      <Box w={12} h={12} flex="none">
        {icon}
      </Box>
      <Text fontSize="lg" fontWeight="bold" pl="20px" flex="1 1 0%">
        {title}
      </Text>
      <Text fontSize="41px" color="dark_green" fontWeight="bold">
        <NumberFormat
          value={number}
          displayType={'text'}
          decimalScale={1}
          decimalSeparator=","
          thousandSeparator={' '}
          suffix={'%'}
        />
      </Text>
    </Flex>
  )
}

export default CardIndicator
