import { useBreakpointValue } from '@chakra-ui/react'
import { useRouter } from 'next/router'
import { ReactElement, useEffect, useState } from 'react'

import { useScroll } from '@miimosa/design-system/lib/hooks'

import { Alert } from '@components'

import Footer from './Footer'
import Header from './Header'

const noBgRoutes: string[] = []
const fullWidthRoutes = ['/funnel', '/discovery', '/informations-reglementaires-psfp']

function shouldAnimateOpacity(route: string): boolean {
  return noBgRoutes.includes(route)
}

function shouldHideHeadAndFooter(route: string): boolean {
  return fullWidthRoutes.includes(route)
}

const Layout = ({ children }: { children: ReactElement }) => {
  const { route } = useRouter()
  const scroll = useScroll()
  const isMd = useBreakpointValue({ base: false, md: true }, { fallback: 'md' })
  const [bgOpacity, setBGOpacity] = useState(shouldAnimateOpacity(route) && isMd ? 0 : 1)

  useEffect(() => {
    if (shouldAnimateOpacity(route) && isMd) {
      // bg animation will start when the user has scrolled 10 px and will be at the max at 10+100px
      setBGOpacity(Math.min(Math.max((scroll.ty - 10) / 100, 0), 1))
    }
  }, [scroll, setBGOpacity, route, isMd])

  const hideHeadAndFooter = shouldHideHeadAndFooter(route)

  const showHeader = !hideHeadAndFooter
  const showFooter = !hideHeadAndFooter

  return (
    <>
      {showHeader && <Header bgOpacity={bgOpacity} />}
      <Alert w="full" />
      <main>{children}</main>
      {showFooter && <Footer />}
    </>
  )
}

export default Layout
