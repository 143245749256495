import { IncomingHttpHeaders } from 'http'

import axios from 'axios'
import qs from 'qs'

import { MIIMOSA_SESSION_V3 } from '@miimosa/common/constants'
import { V2Session } from '@miimosa/protocol'

function getBaseRoute(): string {
  return `${process.env.NEXT_PUBLIC_SERVER_ENDPOINT_V2 || process.env.SERVER_ENDPOINT_V2}/apiv2`
}

function parseHeadersV3Cookie(cookies: string | undefined): V2Session.JWT | undefined {
  let returnedCookie = undefined
  const splittedCookies = cookies?.split(';')
  splittedCookies &&
    splittedCookies.map((cookie) => {
      const splitedCookie = cookie.split('=')
      const splitedCookieCleaned = splitedCookie[0].trim()
      if (splitedCookieCleaned === MIIMOSA_SESSION_V3) {
        returnedCookie = JSON.parse(decodeURIComponent(splitedCookie[1]))
      }
    })
  return returnedCookie
}

function create(headers: Record<string, string | undefined>, headersFromRequest?: IncomingHttpHeaders) {
  let tokenFromServer = headersFromRequest && parseHeadersV3Cookie(headersFromRequest['cookie'])
  if (headersFromRequest && headersFromRequest.jwt_v3) {
    const JWT = headersFromRequest['jwt_v3'].toString()
    tokenFromServer = JSON.parse(JWT)
  }

  return axios.create({
    baseURL: getBaseRoute(),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.miimosa+json; version=2',
      AuthorizationV3: tokenFromServer ? `Bearer ${tokenFromServer.access_token}` : '',
      ...headers,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' })
    },
  })
}

const client = (headers?: IncomingHttpHeaders) => {
  return create({ Authorization: process.env.V2_API_KEY ? `Token ${process.env.V2_API_KEY}` : undefined }, headers)
}

export default client
