import { Box, Flex, Heading, Text } from '@chakra-ui/react'

const RightBarInformation = () => {
  return (
    <Flex>
      <Flex direction="row" columnGap={3} w="full" h="fit-content">
        <Box w="6px" minW="6px" bg="green" borderTopLeftRadius="4px" borderBottomLeftRadius="4px" />
        <Flex direction="column" rowGap={2}>
          <Heading size="sm">A savoir</Heading>
          <Flex direction="column" rowGap={2}>
            <Text size={{ base: 'sm', md: 'md' }}>
              Vos réponses à toutes les questions qui vont suivre resteront <b>strictement confidentielles</b>.
            </Text>
            <Text size={{ base: 'sm', md: 'md' }}>
              Elles sont <b>requises par la règlementation européenne</b> et notre qualité de Prestataire de Services de
              Financement Participatif.
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default RightBarInformation
