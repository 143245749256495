import { SystemStyleObject } from '@chakra-ui/react'

const sizes: Record<string, SystemStyleObject> = {
  xs: {
    fontSize: { base: '1rem' }, //16px
    lineHeight: { base: '1.25rem' },
  },
  sm: {
    fontSize: { base: '1.25rem' }, //20px
    lineHeight: { base: '1.5rem' },
  },
  md: {
    fontSize: { base: '1.375rem' }, //22px
    lineHeight: { base: '1.65rem' },
  },
  lg: {
    fontSize: { base: '1.75rem' }, //28px
    lineHeight: { base: '2.1rem' },
    fontWeight: '400',
  },
  xl: {
    fontSize: { base: '1.75rem', md: '2.125rem' }, //34px
    lineHeight: { base: '2.1rem', md: '2.55rem' },
  },
  '2xl': {
    fontSize: { base: '2.6rem', md: '3.2rem' },
    lineHeight: { base: '3rem', md: '3.6rem' },
    fontWeight: '400',
  },
}

export const Heading = {
  sizes,
  variants: {
    withBackline: {
      d: 'inline-block',
      bgGradient: 'linear(to-t, transparent 5%, green 0%, green 40%, transparent 0%)',
      px: '10px',
    },
  },
  // The default size and variant values
  defaultProps: {
    size: 'md',
  },
}
