import { Flex, Text } from '@chakra-ui/react'
import { FC, ReactNode } from 'react'

import { Image } from '@miimosa/design-system/components'

import Info from '../Info'
import Person from '../Person'

interface Props {
  imageUrl: string
  title: string
  ownerFullName: string
  projectTitle: string
  avatarUrl: string
  data: { title: string; value: ReactNode[] }[]
}

const FocusStory: FC<Props> = ({ imageUrl, title, ownerFullName, projectTitle, avatarUrl, data }) => {
  return (
    <Flex zIndex={1} direction={{ base: 'column', md: 'row' }} align="center" justify="center" height={{ md: '450px' }}>
      <Flex
        width={{ base: '100%', md: '423px' }}
        overflow="hidden"
        borderTopLeftRadius="4px"
        borderTopRightRadius={{ base: '4px', md: 0 }}
        borderBottomLeftRadius={{ base: 0, md: '4px' }}
        borderBottomRightRadius={0}
        height={{ base: '400px', md: '100%' }}
        position="relative"
      >
        <Image src={imageUrl} fill style={{ objectFit: 'cover' }} alt="" quality={100} />
      </Flex>
      <Flex
        px="6"
        bgColor="white"
        borderTopRightRadius={{ base: 0, md: '4px' }}
        borderBottomLeftRadius={{ base: '4px', md: 0 }}
        borderBottomRightRadius="4px"
        align="flex-start"
        justify="center"
        direction="column"
        width={{ base: '100%', md: '357px' }}
        height="100%"
        py={8}
      >
        <Text size="sm" lineHeight="1.25rem">
          {title}
        </Text>
        <Person mt="6" isSmall imageUrl={avatarUrl ?? undefined} fullName={ownerFullName} place={projectTitle} />
        <Flex direction="column" mt="6" width="100%">
          <Info data={data} rowGap={2} />
        </Flex>
      </Flex>
    </Flex>
  )
}

export default FocusStory
