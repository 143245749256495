import { Box, BoxProps, Text, Flex, useBreakpointValue } from '@chakra-ui/react'
import NextLink from 'next/link'
import { FC } from 'react'

import type { FrontSearchProjectType } from '@miimosa/common/types'
import { Close } from '@miimosa/design-system'
import { getProjectStatus } from '@miimosa/design-system/helpers/projects'

import { Image, Tag, ProjectProgressBar, ProjectProgress } from '@components'

interface Props extends BoxProps {
  project: FrontSearchProjectType
  showDescription?: boolean
  isSelected?: boolean
  onClose?: () => void
}

const ProjectCardMap: FC<Props> = ({ onClose, project, isSelected, ...props }) => {
  const { projectId, imageFileName, slug, title, teasing, labels } = project
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: 'md' })
  const { isSuccess, isSoon, isDone } = getProjectStatus({
    status: project.state,
    teasing: teasing,
  })

  return (
    <>
      <NextLink href={teasing ? '' : `/projects/${slug}`}>
        <Flex
          cursor="pointer"
          id={`card_list_${projectId}`}
          minWidth="100%"
          pointerEvents="all"
          zIndex="1"
          position="relative"
          {...props}
          height={{ base: '220px', md: '220px' }}
          {...(isSelected &&
            !isMobile && {
              borderRight: 'solid 4px',
              borderColor: 'orange',
              paddingRight: '4px',
              borderTopRightRadius: '4px',
              borderBottomRightRadius: 4,
            })}
        >
          {onClose && (
            <Flex
              position="absolute"
              width="25px"
              height="25px"
              top="-8px"
              right="-7px"
              bgColor="dark_green"
              align="center"
              justify="center"
              borderRadius="50%"
              onClick={onClose}
              zIndex="2"
            >
              <Close color="white" width="10px" height="10px" />
            </Flex>
          )}
          <Flex
            position="relative"
            overflow="hidden"
            borderRadius="4px"
            borderTopRightRadius={0}
            borderBottomRightRadius={0}
            width={{ base: '130px', md: '295px' }}
            bgColor="dark_green"
            height="100%"
            borderLeftWidth="1px"
            borderTopWidth="1px"
            borderBottomWidth="1px"
            borderRightWidth="0"
            borderColor="border"
          >
            {imageFileName && <Image src={imageFileName} layout="fill" objectFit="cover" alt="" quality={100} />}
          </Flex>
          <Flex
            direction="column"
            bgColor="white"
            minWidth={{ base: 'calc(100% - 130px)', md: '400px' }}
            width={{ base: 'calc(100% - 130px)', md: '400px' }}
            py={{ base: 3, md: '2' }}
            px="4"
            borderTopRightRadius="4px"
            borderBottomRightRadius="4px"
            borderLeftWidth="0"
            borderTopWidth="1px"
            borderBottomWidth="1px"
            borderRightWidth="1px"
            borderColor="border"
            justify="space-between"
          >
            <ProjectProgress
              isDone={isDone}
              endsAt={project.collect?.endsAt}
              isSoon={isSoon}
              isSuccess={isSuccess}
              collectType={project.collect?.type || 'donation'}
              progress={project.collect?.progress || 0}
              interestsRatio={project.collect?.interestsRatio || 0}
            />
            <Text
              size="lg"
              overflow="hidden"
              height="fit-content"
              maxH="fit-content"
              textOverflow="ellipsis"
              my={isMobile ? 2 : '3'}
            >
              {title}
            </Text>
            <Flex
              flexWrap="wrap"
              maxHeight={isMobile ? '0' : 'fit-content'}
              minH={isMobile ? '0' : '22px'}
              mb={4}
              columnGap={1}
              rowGap={1}
            >
              {labels &&
                !isMobile &&
                labels.map(({ value }, index) => <Tag key={`_${index}`} title={value} height="20px" />)}
            </Flex>
            {!teasing && (
              <ProjectProgressBar
                progress={project.collect?.progress || 0}
                goalType={project.collect?.goalType}
                collectType={project.collect?.type || 'donation'}
                isSuccess={isSuccess}
                goalAmount={project.collect?.goalAmount || 0}
                isDone={isDone}
                collectedAmount={project.collect?.collectedAmount || 0}
                collectedQuantity={project.collect?.collectedQuantity || 0}
                goalQuantity={project.collect?.goalQuantity || 0}
              />
            )}
          </Flex>
        </Flex>
      </NextLink>

      {!isMobile && <Box height="1px" bgColor="border" my="1" width="100%" />}
    </>
  )
}
export default ProjectCardMap
