import { ArrowBackIcon } from '@chakra-ui/icons'
import { Flex, Container, BoxProps, useDisclosure, Grid, Heading, Text } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { FC, useContext, useState } from 'react'

import type { FrontRewardType } from '@miimosa/common/types'
import { toV2Link } from '@miimosa/design-system'
import { usePlatform } from '@miimosa/design-system'
import { UserContext } from '@miimosa/design-system/components'

import Reward from '../Reward'
import RewardModal from '../RewardModal'

import { Cart } from '@components'
import { CartContext } from '@components/Cart/CartContext'

interface Props {
  rewards: FrontRewardType[]
  title: string
  isPreview: boolean
  slug: string
}

const RewardsList: FC<Props & BoxProps> = ({ title, slug, isPreview, rewards }) => {
  const { t } = useTranslation('merchant')
  const { platform, lang } = usePlatform()
  const [selectedReward, setSelectedReward] = useState<FrontRewardType | null>(null)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const { cartData, getRewardInCart, addToCart } = useContext(CartContext)

  const user = useContext(UserContext)

  const handleAddReward = (rewardId: number) => {
    if (!user) {
      return (location.href = `${toV2Link('/authenticate', platform, lang)}&return_url=/projects/${slug}`)
    }

    const cartReward = getRewardInCart(rewardId)

    return addToCart({
      cart_id: cartData?.uuid,
      reward_id: rewardId,
      quantity: cartReward ? cartReward.quantity + 1 : 1,
    })
  }

  return (
    <>
      {isOpen && (
        <RewardModal
          isOpen={isOpen}
          reward={selectedReward!}
          isPreview={isPreview}
          onClose={() => {
            onClose()
            setSelectedReward(null)
          }}
        />
      )}
      <Container
        mt="8"
        variant="full"
        position="sticky"
        borderBottom="solid 1px"
        borderTop="solid 1px"
        borderColor="light_gray"
        top="72px"
        bgColor="white"
        zIndex="1"
      >
        <Container
          variant="boxed"
          flexDirection={{ base: 'column', md: 'row' }}
          alignItems="space-between"
          justifyContent="space-between"
          py={8}
        >
          <Flex direction="column" align="flex-start" mb={{ base: 6 }}>
            <Heading fontWeight="400" fontSize={{ base: '1.8em', md: '2em' }}>
              {t('products')}
            </Heading>
            <Heading fontWeight="400" size={{ base: 'xs', md: 'sm' }} mt="4">
              {t('delivery')}
            </Heading>
            <Link href="/noel">
              <Text size="sm" mt="3" cursor="pointer">
                <ArrowBackIcon mr="1" /> {t('return')}
              </Text>
            </Link>
          </Flex>
          <Cart title={title} slug={slug} rewardsData={rewards} />
        </Container>
      </Container>
      <Container variant="full" bgColor="creamy">
        <Container variant="boxed">
          <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 3fr)' }} gap="16">
            {rewards.map((reward: FrontRewardType, index: number) => {
              const { amount, quantity, description, imageFileName, id: rewardId, title, isAvailable } = reward
              const cartReward = getRewardInCart(rewardId)

              return (
                <Reward
                  {...((isAvailable || isPreview) && {
                    onClickCard: (e) => {
                      e.stopPropagation()
                      setSelectedReward(reward)
                      onOpen()
                    },
                  })}
                  onClick={(e) => {
                    e.stopPropagation()
                    handleAddReward(rewardId)
                  }}
                  key={`reward_${index}`}
                  amount={amount}
                  quantity={quantity}
                  description={description}
                  imageFileName={imageFileName}
                  rewardId={rewardId}
                  title={title}
                  cartQuantity={cartReward?.quantity}
                  isAvailable={isAvailable}
                  isPreview={isPreview}
                />
              )
            })}
          </Grid>
        </Container>
      </Container>
    </>
  )
}

export default RewardsList
