import {
  getProjectCategories,
  getImpactCategories,
  getRegionsFR,
  // getRegionsBE,
  // getRewardKindCategories,
  CountryCode,
  getProjectThematics,
} from '@miimosa/common'

const getFilters = (t: (key: string) => string, platform: CountryCode, collectType?: string, fromMap = false) => {
  const impacts = getImpactCategories(t).map(({ slug, title }) => ({ value: slug, label: title as string }))
  const thematics = getProjectThematics().map(({ slug, title }) => ({ value: slug, label: title as string }))
  const categories = getProjectCategories(t).map(({ slug, title }) => ({ value: slug, label: title as string }))
  const region = getRegionsFR(t).map(({ slug, title }) => ({ value: slug, label: title as string }))
  // platform == 'fr'
  // ? getRegionsFR(t).map(({ slug, title }) => ({ value: slug, label: title as string }))
  // : getRegionsBE(t).map(({ slug, title }) => ({ value: slug, label: title as string }))
  // const rewards = getRewardKindCategories(t).map(({ slug, title }) => ({ value: slug, label: title as string }))

  const lendingCollectsType = [{ value: 'lending', label: t('lending_label') }]

  const donationCollectsType = [
    { value: 'donation', label: 'Tous' },
    { value: 'rewards', label: 'Don avec contrepartie' },
    { value: 'presales', label: 'Prévente' },
  ]

  const order = [
    { value: 'newest', label: t('queryFilter.sort.newest') },
    { value: 'soon_finished', label: t('queryFilter.sort.soon_finished') },
    { value: 'popular', label: t('queryFilter.sort.popular') },
  ]

  const filters = [
    {
      id: 'impact',
      title: t('queryFilter.impact'),
      filters: impacts,
      isRadio: false,
    },
    // {
    //   id: 'rewards',
    //   title: t('queryFilter.rewards'),
    //   filters: rewards,
    //   isRadio: false,
    // },
  ]

  if (!fromMap) {
    filters.push({
      id: 'order',
      title: t('queryFilter.sort_by'),
      filters: order,
      isRadio: true,
    })
  }

  if (platform == 'fr') {
    filters.push({
      id: 'region',
      title: t('queryFilter.region'),
      filters: region,
      isRadio: false,
    })
    filters.sort((a, b) => a.title.toLowerCase().charCodeAt(0) - b.title.toLowerCase().charCodeAt(0))
  }

  // it means collectType is lending
  if (collectType == undefined) {
    filters.unshift({
      id: 'thematic',
      title: 'Thematique',
      filters: thematics,
      isRadio: false,
    })
  }

  if (collectType == 'donation') {
    filters.unshift({
      id: 'category',
      title: t('queryFilter.activity'),
      filters: categories,
      isRadio: false,
    })
    filters.splice(1, 0, {
      id: 'type',
      title: t('queryFilter.collect_type'),
      filters: collectType == 'donation' ? donationCollectsType : lendingCollectsType,
      isRadio: true,
    })
  }

  return filters
}

export const formatQueryFilters = (
  { impact, category, regionBE, region, type, order, rewards, thematic }: Record<string, string | string[] | undefined>,
  platform: string
): Record<string, string[] | []> => {
  return {
    impact: impact ? (Array.isArray(impact) ? impact : [impact]) : [],
    category: category ? (Array.isArray(category) ? category : [category]) : [],
    ...(platform === 'fr' && { region: region ? (Array.isArray(region) ? region : [region]) : [] }),
    ...(platform !== 'fr' && { region: regionBE ? (Array.isArray(regionBE) ? regionBE : [regionBE]) : [] }),
    type: type ? (Array.isArray(type) ? type : [type]) : ['lending'],
    rewards: rewards ? (Array.isArray(rewards) ? rewards : [rewards]) : [],
    order: order ? (Array.isArray(order) ? order : [order]) : [],
    thematic: thematic ? (Array.isArray(thematic) ? thematic : [thematic]) : [],
  }
}

export default getFilters
