import { Flex, Text } from '@chakra-ui/react'
import { FC } from 'react'

import { Image } from '@miimosa/design-system/components'
import Icon, { IconName } from '@miimosa/design-system/components/Icon'

interface Props {
  title: string
  iconName: IconName
  imageUrl: string
  href?: string
}

const Impact: FC<Props> = ({ href, title, imageUrl, iconName }) => {
  return (
    <Flex direction="column">
      <Flex
        as="a"
        href={href}
        position="relative"
        width={{ base: '100%', md: '291px' }}
        height="400px"
        borderRadius="4px"
        overflow="hidden"
      >
        <Image src={imageUrl} alt="impact" fill style={{ objectFit: 'cover' }} />
      </Flex>
      <Flex mt="4" align="center">
        <Icon name={iconName} mr="2" size="4xs" />
        <Text size="xl">{title}</Text>
      </Flex>
    </Flex>
  )
}

export default Impact
