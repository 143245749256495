import { Box, Text, Flex, Heading } from '@chakra-ui/react'

const Keywords = () => {
  return (
    <Flex direction={{ base: 'column', md: 'row' }} justifyContent={'center'} alignItems={'center'}>
      <Heading
        size="lg"
        zIndex={1}
        mr={{ base: 0, md: '-65px !important' }}
        mb={{ base: '-10px !important', md: 0 }}
        backgroundColor={'secondary.400'}
        py={{ base: 12, md: 20 }}
        px={{ base: 6, md: 10 }}
        maxW={{ base: '70%', md: 'min(40%, 350px)' }}
        bg="light_gray"
        fontWeight="bold"
      >
        Chez MiiMOSA, nous mettons la finance au service de la transition agricole avec…
      </Heading>
      <Box
        rounded={'base'}
        borderStyle={'solid'}
        borderColor={'light_gray'}
        borderWidth={'4px'}
        px={{ base: 4, md: 28 }}
        py={{ base: 12, md: 16 }}
        maxW={'730px'}
      >
        <Flex
          direction="column"
          alignItems={'baseline'}
          className="bolding"
          sx={{
            b: {
              fontWeight: 'bold',
              fontSize: ['lg', 'xl'],
            },
          }}
        >
          <Text size="xl" mt={10}>
            <b>Respect</b>, maître-mot de notre relation avec nos porteurs de projet, partenaires et membres, tous
            engagés dans notre mission.
          </Text>
          <Text size="xl" mt={10}>
            <b>Transparence</b>, clé de voûte d’une relation de confiance et pérenne pour bâtir notre avenir commun
            ensemble.
          </Text>
          <Text size="xl" mt={10}>
            <b>Résilience</b>, nous sommes déterminés et nous nous engageons à 200% pour réussir notre mission.
          </Text>
          <Text size="xl" mt={10}>
            <b>Expertise</b>, nous sommes une équipe passionnée et formée à la finance et aux enjeux agricoles et
            alimentaires.
          </Text>
        </Flex>
      </Box>
    </Flex>
  )
}

export default Keywords
