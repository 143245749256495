import { Flex, Link, FlexProps, Heading, Text, useBreakpointValue } from '@chakra-ui/react'
import { FC } from 'react'

import { Image } from '@miimosa/design-system/components'

interface Props {
  imageUrl: string
  title: string
  emphasis: string
  description: string
  href: string
  link: string
  isHorizontal?: boolean
}

const Discover: FC<Props & FlexProps> = ({
  imageUrl,
  title,
  emphasis,
  description,
  link,
  href,
  isHorizontal = true,
  ...rest
}) => {
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: 'md' })
  const desktopWidth = isHorizontal ? '523px' : '1120px'
  const width = isMobile ? '100%' : desktopWidth

  return (
    <Flex direction={isHorizontal ? 'row' : 'column'} align={isHorizontal ? 'center' : 'flex-start'} {...rest}>
      <Flex
        position="relative"
        minWidth={width}
        height={{ base: '490px', md: '500px' }}
        overflow="hidden"
        borderRadius="4px"
      >
        <Image src={imageUrl} alt="discover" fill style={{ objectFit: 'cover' }} />
      </Flex>
      <Flex direction="column" ml={isHorizontal ? '16' : 0} mt={isHorizontal ? 0 : '10'}>
        <Text variant="emphasis">{emphasis}</Text>
        <Heading mt="4" maxWidth={width} size="lg">
          {title}
        </Heading>
        <Text mt="4" maxWidth={width} size="lg">
          {description}
        </Text>
        <Link size="xs" variant="primaryUnderline" mt="4" href={href}>
          {link}
        </Link>
      </Flex>
    </Flex>
  )
}

export default Discover
