import { Flex, FlexProps } from '@chakra-ui/react'
import { FC, ReactNode } from 'react'

import { FullWidthContainer } from '@miimosa/design-system/components/Containers'

interface Props {
  children: ReactNode
}

const FullWidthLayout: FC<Props & FlexProps> = ({ children, ...props }) => {
  return (
    <FullWidthContainer h="full">
      <Flex direction={{ base: 'column', lg: 'row' }} width="100%" flex="1 1 0%" align="flex-start" {...props}>
        {children}
      </Flex>
    </FullWidthContainer>
  )
}

export default FullWidthLayout
