import { Avatar, Flex, Heading } from '@chakra-ui/react'
import React from 'react'

export interface AvatarProps {
  imageUrl?: string
  fullName?: string
  size?: string
  isLight?: boolean
}

const UserAvatar: React.FC<AvatarProps> = ({ imageUrl, fullName, size, isLight = false }) => {
  // TODO: handle different fullName styles
  const color = isLight ? 'white' : 'main_gray'

  return (
    <Flex align="center" direction="row">
      <Avatar size={size} name={fullName} src={imageUrl} />
      <Flex direction="column" align="flex-start" ml="8px">
        {fullName && (
          <Heading color={color} size="sm">
            {fullName}
          </Heading>
        )}
      </Flex>
    </Flex>
  )
}

export default UserAvatar
