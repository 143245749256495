import { Flex, useRadioGroup } from '@chakra-ui/react'
import { FC, useEffect, useState } from 'react'

import { IconName } from '@miimosa/design-system/components/Icon'

import SegmentedRadio from './SegmentedRadio'

// TODO: type ref
interface Props {
  name: string
  callback: (v: string) => void
  segments: {
    iconName?: IconName
    iconColor?: string
    label: string
    value: string
    segmentRef: any
  }[]
  defaultIndex: number
}

const SegmentedControl: FC<Props> = ({ name, callback, segments, defaultIndex = 0 }) => {
  // use Chakra dedicated custom radio buttons api
  const { getRootProps } = useRadioGroup({
    name: name,
    defaultValue: 'both',
    onChange: (value: string) => callback(value),
  })
  const [activeIndex, setActiveIndex] = useState(defaultIndex)
  const onInputChange = (value: string, index: number) => {
    setActiveIndex(index)
    callback(value)
  }
  const group = getRootProps()

  // handle the segment background highlight position
  useEffect(() => {
    const activeSegmentRef = segments[activeIndex].segmentRef
    const { offsetWidth, offsetLeft } = activeSegmentRef.current

    const { style } = document.documentElement

    style.setProperty('--highlight-width', `${offsetWidth}px`)
    style.setProperty('--highlight-x-pos', `${offsetLeft}px`)
  }, [activeIndex, callback, segments])

  return (
    <Flex
      justify="space-between"
      overflow="hidden"
      position="relative"
      height="auto"
      borderWidth="2px"
      borderColor="dark_green"
      borderRadius="3em"
      _before={{
        content: "''",
        background: 'dark_green',
        height: 'auto',
        borderRadius: '3em',
        width: 'var(--highlight-width)',
        transform: 'translateX(var(--highlight-x-pos))',
        position: 'absolute',
        top: '0px',
        bottom: '0px',
        left: 0,
        zIndex: 0,
        transition: 'transform 0.3s ease, width 0.3s ease',
      }}
      {...group}
    >
      {segments.map((item, i) => {
        const checked = i === activeIndex
        return (
          <Flex key={item.value} position="relative" textAlign="center" zIndex="1" ref={item.segmentRef}>
            <SegmentedRadio {...item} checked={checked} onInputChange={onInputChange} index={i} />
          </Flex>
        )
      })}
    </Flex>
  )
}

export default SegmentedControl
