import { Avatar, Flex, Grid, GridItem, Text, useBreakpointValue } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import { format } from 'timeago.js'

import type { contributorsType } from '@miimosa/common/types'

const Contributors = ({ contributors }: { contributors?: contributorsType }) => {
  const { t } = useTranslation('project', { keyPrefix: 'contributors' })
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: 'md' })

  if (!contributors || contributors.contributors?.length <= 0)
    return (
      <Flex minHeight="300px" bg="white" w="full" p={8} border="solid 1px" borderColor="light_gray">
        {t('none')}
      </Flex>
    )

  return (
    <Flex
      direction="column"
      rowGap={4}
      w="full"
      minHeight="300px"
      bg="white"
      p={8}
      border="solid 1px"
      borderColor="light_gray"
    >
      <Grid templateColumns="repeat(2, 1fr)" overflow="hidden" gap={{ base: 2, md: 4 }} minW="full">
        {contributors.contributors.map(({ avatar, full_name, pledged_at }, i) => (
          <GridItem w="full" h="80px" key={`contributor_${i}`}>
            <Flex w="full" h="auto" direction="row" alignItems="center">
              <Avatar size={isMobile ? 'sm' : 'xl'} name={full_name} src={avatar ?? undefined} mx={2} />
              <Flex direction="column" justifyContent="center" px={2} rowGap={1}>
                <Text size="md">{full_name}</Text>
                <Text size="xs" color="main_gray">
                  {format(pledged_at, 'fr')}
                </Text>
              </Flex>
            </Flex>
          </GridItem>
        ))}
      </Grid>
      {contributors.hidden_contributors > 0 && (
        <Text size="md" color="black">
          {t('hidden_count', { count: contributors.hidden_contributors })}
        </Text>
      )}
    </Flex>
  )
}

export default Contributors
