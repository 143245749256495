import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'Donation',
  viewBox: '0 0 80 80',
  path: (
    <g>
      <path
        d="M70.8 51.2C69.36 50 67.44 50.08 66.72 50.24C65.28 50.4 58 53.68 55.2 54.96C55.2 53.76 54.8 52.72 54.08 51.92C52.72 50.48 50.64 50.48 50.56 50.48C50.48 50.48 46.24 50.56 43.2 50.48C40.16 46.8 36.64 46.48 34.24 46.24C34 46.24 33.84 46.24 33.6 46.16C27.76 45.68 24.72 46.08 23.2 46.4C23.04 44.32 21.36 42.64 19.2 42.64H8.39998C7.91998 42.64 7.59998 42.96 7.59998 43.44C7.59998 43.92 7.91998 44.24 8.39998 44.24H19.12C20.48 44.24 21.52 45.36 21.52 46.64V61.6C21.52 62.96 20.4 64 19.12 64H14.08C13.6 64 13.28 64.32 13.28 64.8C13.28 65.28 13.6 65.6 14.08 65.6H19.12C20.72 65.6 22.08 64.64 22.72 63.28C28.64 65.92 37.12 68.16 41.28 69.04C42.72 69.36 43.84 69.44 44.72 69.44C46.32 69.44 47.2 69.04 47.36 68.96C48.16 68.56 68.16 58.8 69.36 58.24C70.56 57.68 72.16 57.04 72.24 55.04C72.32 53.44 71.84 52.08 70.8 51.2ZM68.8 56.88C67.6 57.44 47.6 67.2 46.72 67.68C46.72 67.68 45.36 68.4 41.76 67.6C37.6 66.64 29.04 64.4 23.28 61.84C23.28 61.76 23.28 61.76 23.28 61.68V48.08C24.32 47.84 27.2 47.36 33.52 47.84C33.76 47.84 33.92 47.92 34.16 47.92C36.48 48.16 39.6 48.4 42.24 51.84C42.4 52 42.64 52.16 42.88 52.16C46 52.24 50.64 52.16 50.72 52.16C50.72 52.16 52.24 52.16 53.12 53.12C53.6 53.6 53.84 54.4 53.84 55.28C53.84 55.68 53.68 56 53.36 56.32C52.48 57.04 50.64 57.04 49.92 57.04H38.24C37.76 57.04 37.44 57.44 37.44 57.84C37.44 58.24 37.76 58.64 38.24 58.64H49.84C49.92 58.64 50.16 58.64 50.56 58.64C51.52 58.64 53.28 58.48 54.32 57.52C54.48 57.36 54.64 57.2 54.72 57.04C54.8 57.04 54.88 57.04 54.96 56.96C59.04 55.04 65.92 52 67.04 51.84C67.44 51.76 68.88 51.68 69.84 52.48C70.48 53.04 70.88 54 70.88 55.2C70.72 56 70 56.4 68.8 56.88Z"
        fill="#2A2A2A"
      />
      <path
        d="M49.5606 38.631C49.4006 38.631 49.3206 38.631 49.1606 38.551C47.0006 37.431 36.2006 31.351 36.2006 22.551C36.2006 20.631 36.9206 18.871 38.2006 17.511C39.5606 16.151 41.4806 15.351 43.4006 15.431C45.8006 15.431 47.9606 16.631 49.4806 18.631C51.4006 16.071 54.3606 14.871 57.4006 15.671C60.6006 16.471 62.7606 19.271 62.7606 22.551C62.7606 30.551 53.7206 36.391 49.8006 38.551C49.8006 38.631 49.7206 38.631 49.5606 38.631ZM43.4806 17.031C41.9606 17.031 40.5206 17.591 39.4006 18.711C38.3606 19.751 37.8006 21.111 37.8006 22.551C37.8006 29.991 46.8406 35.431 49.4806 36.951C53.3206 34.791 61.2406 29.431 61.2406 22.551C61.2406 19.911 59.6406 17.831 57.0806 17.191C54.9206 16.631 52.0406 17.191 50.2806 20.471C49.9606 20.951 49.1606 20.951 48.8406 20.471C47.6406 18.311 45.7206 17.031 43.4806 17.031Z"
        fill="#FF6C4A"
      />
    </g>
  ),
  defaultProps: {
    w: '80px',
    h: '80px',
  },
})
