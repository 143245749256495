import { MIIMOSA_SESSION } from '@miimosa/common'
import { type V2Session } from '@miimosa/protocol'

import { post } from './client'

export function openV2Session(cookie: string) {
  return post<V2Session.OpenRequest, V2Session.OpenResponse>('v2session/v1/Open', {
    cookie,
    with_jwt: false,
  })
}

// this is a convenience method to use with NextRequest
export async function extractUserID({
  cookies,
}: {
  cookies: Partial<{
    [key: string]: string
  }>
}): Promise<number> {
  const session = cookies[MIIMOSA_SESSION]

  if (!session) {
    return 0
  }

  try {
    const {
      data: { user_id },
    } = await openV2Session(session)
    return user_id
  } catch {
    return 0
  }
}
