import { Flex, Heading, Button, Text } from '@chakra-ui/react'
import { Dispatch, SetStateAction, useState } from 'react'

import { Icon } from '@miimosa/design-system'
import { Leads } from '@miimosa/protocol'

import { IconCard } from '@components/IconCard'
import { HeadingIntro } from '@components/psfp'
import { formatData, updatePSFPFunnel } from '@helpers/psfp'

interface Props {
  funnelStep?: Leads.Step
  leadId: string
  setCurrentFunnel: Dispatch<SetStateAction<Leads.UpdateResponse>>
}

const NaturalOrLegal = ({ funnelStep, leadId, setCurrentFunnel }: Props) => {
  const [selectedAccountType, setSelectedAccountType] = useState<string | undefined>(
    funnelStep?.input_descriptors[0].default_value?.str
  )

  const onSubmit = () => {
    const formattedData = formatData({
      data: { INPUT_NAME_INVESTOR_TYPE: selectedAccountType },
      leadId: leadId,
      funnelStep: funnelStep,
    })
    const response = updatePSFPFunnel(formattedData)

    response.then((newData) => {
      if (newData) {
        setCurrentFunnel(newData)
      }
    })
  }

  if (!funnelStep) return null

  return (
    <Flex direction="column" alignItems="center" rowGap={10} as="form">
      <HeadingIntro
        texts={['Vous devez valider votre profil investisseur avant de pouvoir investir.']}
        subTexts={[
          <Flex direction="column" key="subtext1" rowGap={3}>
            <Text size="md" color="dark_green">
              Ces quelques questions vont d’abord nous permettre de déterminer si vous devez être considéré comme un
              investisseur « averti » ou « non-averti » (selon les critères de l’Autorité des Marchés Financiers).
            </Text>
            <Flex direction="row" alignItems="center" columnGap={2}>
              <Icon size="4xs" name="Time" color="dark_green" />
              <Text color="dark_green">Y répondre vous prendra quelques secondes</Text>
            </Flex>
          </Flex>,
        ]}
      />
      <Flex direction="column" w="100%" rowGap={8} h="full">
        <Flex direction="column" w="100%" rowGap={4}>
          <Heading w="full" textAlign="left" size={{ base: '2xs', md: 'xs' }}>
            Vous souhaitez investir en tant que :
          </Heading>
          <Flex direction={{ base: 'column', lg: 'row' }} columnGap={8} rowGap={8}>
            <IconCard
              value="NATURAL"
              setOption={setSelectedAccountType}
              iconName="Particulier"
              text="Particulier"
              width={{ base: '100%', md: '256px' }}
              height={{ base: '190px' }}
              isSelected={selectedAccountType === 'NATURAL'}
              as="div"
            />
            <IconCard
              value="LEGAL"
              setOption={setSelectedAccountType}
              iconName="Company"
              text="Entreprise"
              width={{ base: '100%', md: '256px' }}
              height={{ base: '190px' }}
              isSelected={selectedAccountType === 'LEGAL'}
              as="div"
            />
          </Flex>
        </Flex>
        <Flex w="full" justifyContent="center">
          <Button variant="primary" size="md" onClick={onSubmit}>
            Continuer
          </Button>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default NaturalOrLegal
