import { Flex, FlexProps } from '@chakra-ui/react'
import { FC, ReactNode } from 'react'

import { Image } from '@miimosa/design-system/components'

interface Props {
  children: ReactNode
  objectPosition?: string
  imageUrl: string
}

const Edito: FC<Props & FlexProps> = ({ imageUrl, objectPosition, children, ...rest }) => {
  return (
    <Flex direction="column" width={{ base: '100%', md: '100%' }} {...rest}>
      <Flex
        position="relative"
        width={{ base: '100%', md: '100%' }}
        borderRadius="4px"
        overflow="hidden"
        height="536px"
      >
        <Image src={imageUrl} fill style={{ objectFit: 'cover', objectPosition: objectPosition }} alt="" />
      </Flex>
      <Flex
        direction={{ base: 'column', md: 'row' }}
        mt={10}
        align="flex-start"
        justifyContent={{ md: 'space-between' }}
      >
        {children}
      </Flex>
    </Flex>
  )
}

export default Edito
