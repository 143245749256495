import { Box, Button, Checkbox, Divider, Flex, Heading, Text, useBreakpointValue } from '@chakra-ui/react'
import { Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon } from '@chakra-ui/react'
import { Dispatch, SetStateAction, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'

import { Leads } from '@miimosa/protocol'

import { AmountInput } from '@components'
import { formatData, previousPSFPFunnel, updatePSFPFunnel } from '@helpers/psfp'
import { PSFPFormValues } from '@helpers/psfp/types'

import HeadingIntro from './HeadingIntro'

interface Props {
  funnelStep?: Leads.Step
  leadId: string
  setCurrentFunnel: Dispatch<SetStateAction<Leads.UpdateResponse>>
}

const InvestmentSimulation = ({ funnelStep, leadId, setCurrentFunnel }: Props) => {
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: 'md' })
  const [isChecked, setIsChecked] = useState(false)

  const {
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm<PSFPFormValues>({
    defaultValues: {
      INPUT_NAME_YEARLY_NET_INCOME:
        funnelStep?.input_descriptors[0].default_value != undefined
          ? String(funnelStep?.input_descriptors[0].default_value?.number)
          : undefined,
      INPUT_NAME_TOTAL_LIQUID_ASSET:
        funnelStep?.input_descriptors[1].default_value != undefined
          ? String(funnelStep?.input_descriptors[1].default_value?.number)
          : undefined,
      INPUT_NAME_YEARLY_FINANCIAL_COMMITMENT:
        funnelStep?.input_descriptors[2].default_value != undefined
          ? String(funnelStep?.input_descriptors[2].default_value?.number)
          : undefined,
    },
  })

  const onSubmit: SubmitHandler<PSFPFormValues> = (data) => {
    const formattedData = formatData({ data: data, leadId: leadId, funnelStep: funnelStep })
    const response = updatePSFPFunnel(formattedData)
    response.then((newData) => {
      if (newData) {
        setCurrentFunnel(newData)
      }
    })
  }

  const onPrevious = () => {
    const response = previousPSFPFunnel({
      funnel_type: 'FUNNEL_TYPE_PSFP',
      lead_id: leadId,
    })

    response.then((newData) => {
      if (newData) {
        const createResp: Leads.UpdateResponse = {
          step: newData.step,
          progress: newData.progress,
          lead_id: leadId,
          salesforce_ref: '',
          outputs: [],
        }

        setCurrentFunnel(createResp)
      }
    })
  }

  if (!funnelStep) return null

  const watchIncome = watch('INPUT_NAME_YEARLY_NET_INCOME')
  const watchAssets = watch('INPUT_NAME_TOTAL_LIQUID_ASSET')
  const watchCommitments = watch('INPUT_NAME_YEARLY_FINANCIAL_COMMITMENT')

  const total =
    parseInt((watchIncome && watchIncome.replace(/\s/g, '')) || '0') +
    parseInt((watchAssets && watchAssets.replace(/\s/g, '')) || '0') -
    parseInt((watchCommitments && watchCommitments.replace(/\s/g, '')) || '0')

  return (
    <Flex direction="column" as="form" w="full" justifyContent="space-between">
      <Flex direction="column" rowGap={2}>
        <Flex direction="column">
          <HeadingIntro texts={['Simulateur de patrimoine']} />
          {isMobile && (
            <Accordion allowToggle>
              <AccordionItem border="none">
                <AccordionButton pl={0} justifyContent="space-between" _hover={{ bg: 'transparent' }}>
                  <Text size="md" fontWeight="700">
                    Pourquoi ces questions ?
                  </Text>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pl={0} pb={0}>
                  <Flex direction="row" columnGap={3} w="full">
                    <Box minW="6px" bg="green" minH="100%" borderTopLeftRadius="4px" borderBottomLeftRadius="4px" />
                    <Flex direction="column" rowGap={2}>
                      <Text size="sm">
                        Imposées par le régulateur européen, elles nous permettent d’estimer le montant de votre
                        patrimoine, et d’en{' '}
                        <b>
                          déduire le montant maximal que vous pouvez investir sur les plateformes de financement
                          participatif.
                        </b>
                      </Text>
                      <Text size="sm">
                        <b>Elles nous permettront également de vous alerter</b> lorsque vous vous apprêtez à investir
                        une somme trop importante sur un même projet.
                      </Text>
                    </Flex>
                  </Flex>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          )}
        </Flex>
        <Flex direction="column" rowGap={8}>
          <Flex direction="column" rowGap={3}>
            <AmountInput
              title={'Revenu annuel net total'}
              text={
                "Il s'agit du montant total des salaires (avant IR), allocations, pensions, dividendes, intérêts ou autres revenus immobiliers que vous percevez."
              }
              errors={errors}
              inputName="INPUT_NAME_YEARLY_NET_INCOME"
              control={control}
              isRequired={funnelStep.input_descriptors[0].validation?.required}
              placeholder="Ex : 40 000"
              min={0}
              max={2000000000}
            />
          </Flex>
          <Flex direction="column" rowGap={3}>
            <AmountInput
              title={'Total des actifs « liquides » '}
              text={
                'Indiquez ici le cumul des sommes dont vous disposez sur des comptes courants et comptes d’épargne, ainsi que les actifs financiers cotés sur un marché réglementé.'
              }
              errors={errors}
              inputName="INPUT_NAME_TOTAL_LIQUID_ASSET"
              control={control}
              isRequired={funnelStep.input_descriptors[1].validation?.required}
              placeholder="Ex : 50 000"
              min={0}
              max={2000000000}
            />
          </Flex>
          <Flex direction="column" rowGap={3}>
            <AmountInput
              title={'Charges et engagements financiers annuels'}
              text={
                'Remboursements de crédits (immobilier, consommation, etc.), loyers, charges d’habitation, taxes, pensions alimentaires ou versements de primes d’assurance.'
              }
              errors={errors}
              inputName="INPUT_NAME_YEARLY_FINANCIAL_COMMITMENT"
              control={control}
              isRequired={funnelStep.input_descriptors[2].validation?.required}
              placeholder="Ex : 20 000"
              min={0}
              max={2000000000}
            />
          </Flex>
          <Divider my={2} bg="green" h="2px" />
          <Flex direction="column" rowGap={8}>
            <Heading size="2xs">
              Votre patrimoine financier :{' '}
              {total > 0
                ? new Intl.NumberFormat('fr-FR', {
                    style: 'currency',
                    currency: 'EUR',
                    maximumFractionDigits: 0,
                  }).format(total)
                : '-'}
            </Heading>
            <Heading size="2xs">
              Au regard des éléments indiqués ci-dessus nous vous conseillons de ne pas investir plus de{' '}
              {total > 0
                ? new Intl.NumberFormat('fr-FR', {
                    style: 'currency',
                    currency: 'EUR',
                    maximumFractionDigits: 0,
                  }).format((total * 10) / 100)
                : '-'}{' '}
              sur 12 mois sur des projets de financement participatif
            </Heading>
          </Flex>
          <Flex direction="column" w="full" justifyContent="center" rowGap={6} alignItems="center">
            <Checkbox colorScheme="checkbox" display="flex" onChange={() => setIsChecked(!isChecked)}>
              <Text size={{ base: 'sm', md: 'md' }}>
                Je déclare que les informations renseignées ci-dessus sont exactes et avoir pris connaissance des
                résultats de la simulation de ma capacité à supporter des pertes
              </Text>
            </Checkbox>
            <Flex direction="row" columnGap={8}>
              <Button variant="secondary" size="md" onClick={onPrevious}>
                Précédent
              </Button>
              <Button isDisabled={!isChecked} variant="primary" size="md" onClick={handleSubmit(onSubmit)}>
                Suivant
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default InvestmentSimulation
