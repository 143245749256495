import { GetServerSidePropsContext } from 'next'

import type { keyValueFlattened, StatsComputeResponse } from '@miimosa/common'
import { Stats } from '@miimosa/protocol'

import { BaseAPI, Result } from '../api'

import { ErrorV3, createV3Client, toV3Result } from './base'

export class StatsAPI extends BaseAPI {
  constructor(ctx?: GetServerSidePropsContext) {
    super(createV3Client(ctx), toV3Result)
  }

  private orderRecordByValue(data: Record<string, number>): keyValueFlattened {
    const list = Object.keys(data).map((name) => ({
      name,
      value: data[name],
    }))
    list.sort((a, b) => b.value - a.value)
    return list
  }

  private formatDecimalResults = (data: number): number => {
    return Math.round(data)
  }

  private formatRateResults = (data: number): number => {
    return data * 100
  }

  private formatDecimalResultsInDays = (data: number): number => {
    return Math.round(data) / 87758
  }

  async compute(): Promise<Result<StatsComputeResponse, ErrorV3>> {
    const result = await this.call<StatsComputeResponse, ErrorV3>((c) => c.post('stats/v1/Compute', {}))
    if (result.kind === 'success') {
      result.data.projects_success_rate = this.formatRateResults(result.data.projects_success_rate)
      result.data.total_repaid_amount = this.formatDecimalResults(result.data.total_repaid_amount)
      result.data.average_collected_amount = this.formatDecimalResults(result.data.average_collected_amount)
      result.data.average_repay_term = this.formatDecimalResults(result.data.average_repay_term)
      result.data.average_interest_rate = this.formatRateResults(result.data.average_interest_rate)
      result.data.average_collect_duration = this.formatDecimalResultsInDays(result.data.average_collect_duration)
      result.data.average_pledge_amount = this.formatDecimalResults(result.data.average_pledge_amount)
      result.data.late_term_rate = this.formatRateResults(result.data.late_term_rate)
      result.data.legal_pledges_rate = this.formatRateResults(result.data.legal_pledges_rate)
      result.data.default_term_rate = this.formatRateResults(result.data.default_term_rate)

      result.data.ordered_by_category_value = this.orderRecordByValue(result.data.projects_number_by_category)
      result.data.ordered_by_region_value = this.orderRecordByValue(result.data.projects_number_by_region)
    }
    return result
  }

  async all(req: Stats.AllRequest): Promise<Result<Stats.AllResponse, ErrorV3>> {
    const result = await this.call<Stats.AllResponse, ErrorV3>((c) => c.post('stats/v1/All', req))

    return result
  }
}
