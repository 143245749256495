import { Avatar, Flex, FlexProps, Text, Box } from '@chakra-ui/react'
import { useState } from 'react'
import { FaReply, FaTimes } from 'react-icons/fa'
import { format } from 'timeago.js'

import type { CommentsType, CommentType } from '@miimosa/common/types'

import { CommentArea } from '@components/projects/project/comments'

const deleteComment = async (id: number, projectId: string, onCommentsChange: () => void) => {
  const res = await fetch(`/nextapi/projects/${projectId}/comments`, {
    credentials: 'same-origin',
    method: 'DELETE',
    body: JSON.stringify({ id: id }),
  })
  if (res.status === 200) {
    onCommentsChange()
  }
}

const Comment = ({
  comments,
  comment,
  isReply,
  onCommentsChange,
  projectId,
  ...props
}: {
  comments: CommentsType
  comment: CommentType
  isReply: boolean
  onCommentsChange: () => void
  projectId: string
} & FlexProps) => {
  const { id, user_full_name, user_avatar, content, created_at } = comment
  const [replyIsHidden, setReplyIsHidden] = useState(true)
  const [visiblityCommentArea, setVisiblityCommentArea] = useState(false)
  return (
    <>
      <Flex
        direction="column"
        mb="16px"
        {...props}
        onMouseEnter={() => setReplyIsHidden(isReply ? true : false)}
        onMouseLeave={() => setReplyIsHidden(true)}
      >
        <Flex direction="row" alignItems="flex-start">
          <Avatar size="md" name={user_full_name} src={user_avatar ?? undefined} />
          <Flex direction="row" w="full" justifyContent="space-between">
            <Flex direction="column" ml="8px">
              <Text size="lg">{user_full_name}</Text>
              <Text size="xs" color="main_gray_lighten">
                {format(created_at, 'fr')}
              </Text>
            </Flex>
            {comment.can_destroy && (
              <Box
                h="0px"
                _hover={{ cursor: 'pointer' }}
                onClick={() => deleteComment(id, projectId, onCommentsChange)}
              >
                <FaTimes width="15px" height="15px" />
              </Box>
            )}
          </Flex>
        </Flex>
        <Flex direction="column">
          <Flex direction="row" mt="8px" mb="16px">
            {comments.can_comment && (
              <Flex
                ml="15px"
                cursor="pointer"
                _hover={{ color: 'dark_green' }}
                visibility={replyIsHidden ? 'hidden' : 'visible'}
                onClick={() => setVisiblityCommentArea(!visiblityCommentArea)}
              >
                <FaReply height="15px" width="15px" />
              </Flex>
            )}
            <Text size="md" color={isReply ? 'main_gray_lighten' : ''} ml="15px">
              {content}
            </Text>
          </Flex>
          <CommentArea
            mt="15px"
            ml="40px"
            display={visiblityCommentArea ? 'block' : 'none'}
            commentId={id}
            projectId={projectId}
            onCommentsChange={onCommentsChange}
          />
        </Flex>
        {comment.comments?.map((comment: CommentType, index) => (
          <Comment
            comment={comment}
            key={index}
            ml="40px"
            isReply={true}
            onCommentsChange={onCommentsChange}
            comments={comments}
            projectId={projectId}
          />
        ))}
      </Flex>
    </>
  )
}

export default Comment
