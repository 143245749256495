/* eslint-disable @typescript-eslint/no-unused-vars */

import { Container, Box, Text, Flex, BoxProps, Heading, Avatar, useBreakpointValue } from '@chakra-ui/react'
import { sanitize } from 'isomorphic-dompurify'
import { useTranslation } from 'next-i18next'
import { FC } from 'react'

import { getImpactCategories } from '@miimosa/common'
import type { FrontLabelType } from '@miimosa/common/types'
import { Icon, Image, TadaBottomLeft, TadaTopLeft } from '@miimosa/design-system'
import { IconName } from '@miimosa/design-system'

import Tag from '@components/Tag'

interface Props {
  userId: number
  title: string
  projectId: number
  shortDescription: string
  imageFileName: string
  description: string
  impacts: FrontLabelType[]
  labels: FrontLabelType[]
  ownerFullName: string
  avatarFileName: string | null
  place: string
}

const Hero: FC<Props & BoxProps> = ({
  imageFileName,
  impacts,
  labels,
  avatarFileName,
  description,
  ownerFullName,
  shortDescription,
  place,
  userId,
  projectId,
  title,
}) => {
  const { t } = useTranslation('merchant')
  const impactCategoriesData = getImpactCategories(t)
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: 'md' })

  const projectImage = (
    <Flex
      width={{ base: '100%', md: '690px' }}
      position="relative"
      height={{ base: '380px', md: 'calc(100vh - 140px)' }}
      maxHeight={{ base: '380px', md: '750px' }}
      borderRadius="4px"
      mt={{ base: 6, md: 0 }}
      overflow="hidden"
    >
      {imageFileName && <Image fill style={{ objectFit: 'cover' }} zIndex="0" src={imageFileName} alt="" />}
      <Flex
        zIndex="1"
        height={{ base: '240px', md: '230px' }}
        position="absolute"
        bottom="0"
        left="0"
        width="100%"
        background="linear-gradient(180deg, rgba(1, 62, 39, 0) 0%, rgba(1, 62, 39, 0.84) 55.21%, #013E27 100%)"
      />
      <Flex
        zIndex={2}
        color="white"
        width="100%"
        align="center"
        justify="center"
        position="absolute"
        bottom="24px"
        left="0"
        columnGap={16}
        rowGap="22"
        flexWrap="wrap"
      >
        {impacts?.map(({ value, slug: slugImpact }, index) => {
          const iconName = impactCategoriesData.find(({ slug: slugImpactData }) => slugImpactData === slugImpact)?.icon

          return (
            <Flex direction="column" key={`impact_${index}`} justify="center" align="center">
              {iconName && <Icon name={iconName as IconName} size="xs" color="white" />}
              <Text mt="2" textAlign="center">
                {value}
              </Text>
            </Flex>
          )
        })}
      </Flex>
    </Flex>
  )

  return (
    <Container variant="full" bgColor="white">
      <Container variant="boxed" alignContent="center" py={4} flexDirection={{ base: 'column', md: 'row' }}>
        <Flex direction="column" pr={{ base: 0, md: '12' }} width={{ base: '100%', md: '630px' }}>
          <Avatar
            height="100px"
            width="100px"
            color="black"
            name={ownerFullName}
            src={avatarFileName ?? undefined}
            mr="4px"
          />
          <Heading fontSize="40px" lineHeight="42px" mt="6">
            {title}
          </Heading>
          <Text size="xl" mt="6">
            {shortDescription}
          </Text>
          <Text size="sm" mt="2">
            <Icon name="PinMap" size="5xs" mr="4px" color="#212721" /> {place}
          </Text>
          {isMobile && projectImage}
          <Flex position="relative" mt={{ base: 8, md: '14' }}>
            <TadaTopLeft display={{ base: 'none', md: 'block' }} position="absolute" top="-30px" left="-45px" />
            <Box fontSize="14px" dangerouslySetInnerHTML={{ __html: sanitize(description) }} />
            <TadaBottomLeft
              display={{ base: 'none', md: 'block' }}
              transform="scale(-1, 1) rotate(325deg)"
              position="absolute"
              bottom="-30px"
              left="none"
              right="-35px"
            />
          </Flex>
          <Flex mt="8" align="center" columnGap="4">
            {labels?.map(({ value }, index) => (
              <Tag title={value} key={`tag_${index}`} />
            ))}
          </Flex>
        </Flex>
        {!isMobile && projectImage}
      </Container>
    </Container>
  )
}

export default Hero
