import { Vote } from '@miimosa/protocol'

import { post } from './client'

export function vote(projectSlug: string, platform: string) {
  return post<Vote.VoteRequest, Vote.VoteResponse>('vote/v1/Vote', { platform, project_slug: projectSlug })
}

export function fetchProjectVoteCount(projectSlug: string, platform: string) {
  return post<Vote.FetchProjectVoteCountRequest, Vote.FetchProjectVoteCountResponse>('vote/v1/FetchProjectVoteCount', {
    project_slug: projectSlug,
    platform: platform,
  })
}

export function fetchPlatformVoteCount(platform: string) {
  return post<Vote.FetchPlatformVoteCountRequest, Vote.FetchPlatformVoteCountResponse>(
    'vote/v1/FetchPlatformVoteCount',
    {
      platform,
    }
  )
}

export function createVote(request: any) {
  return post<any, any>('vote/v1/CreateVote', request)
}

export function validateVote(request: any) {
  return post<any, any>('vote/v1/ValidateVote', request)
}
