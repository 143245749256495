import { Flex, Box, Text, useBreakpointValue } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import { useState } from 'react'

import { type Leads } from '@miimosa/protocol'

type Props = {
  currentStep: number
  stepCount: number
  steps: Leads.StepName[]
}

const RightBarSteps = ({ currentStep, stepCount, steps }: Props) => {
  const { t } = useTranslation('psfp')
  const [stepsWithoutFirstTwo] = useState(steps.slice(3) || [])
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: 'md' })

  return (
    <Flex direction="column">
      <Flex direction="column" w="full" rowGap={2}>
        <Box w="full" h="8px" bg="lighten" borderRadius="20px">
          <Box w={`${((currentStep - 2) / (stepCount - 2)) * 100}%`} bg="dark_green" h="full" borderRadius="20px" />
        </Box>
        {/* remove 2 step to act like the first two are not in the same funnel */}
        <Text textAlign="right" color="dark_green" size={{ base: 'md', md: 'sm' }}>
          <b>{currentStep - 2}</b>/{stepCount - 2} étapes
        </Text>
      </Flex>
      {!isMobile && (
        <Flex direction="column" rowGap={6}>
          <Text size="lg" fontWeight="700">
            Les étapes
          </Text>
          <Flex direction="column" rowGap={4}>
            {stepsWithoutFirstTwo.map((s, i) => (
              <Flex direction="row" alignItems="center" columnGap={3} key={s}>
                <Box w="8px" h="8px" borderRadius={'50%'} bg={currentStep - 3 >= i ? 'dark_green' : 'disabled'} />
                <Text
                  color={currentStep - 3 >= i ? 'dark_green' : 'disabled'}
                  size="lg"
                  fontWeight={currentStep - 3 >= i ? '700' : '400'}
                >
                  {t(`steps.${s}`)}
                </Text>
              </Flex>
            ))}
          </Flex>
        </Flex>
      )}
    </Flex>
  )
}

export default RightBarSteps
