import { type GetServerSidePropsContext } from 'next'

import { FunnelAPI } from '@miimosa/api'
import { type Leads } from '@miimosa/protocol'

const updatePSFPFunnel = async (params: Leads.UpdateRequest, context?: GetServerSidePropsContext) => {
  const funnelAPI = new FunnelAPI(context)
  const response = await funnelAPI.updateFunnel(params)
  if (response.kind == 'success') {
    return response.data
  }
}

export default updatePSFPFunnel
