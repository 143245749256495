import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'Error',
  viewBox: '0 0 24 24',
  path: (
    <>
      <path
        d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7363 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2V2ZM12 21C10.22 21 8.47991 20.4722 6.99987 19.4832C5.51983 18.4943 4.36628 17.0887 3.68509 15.4442C3.0039 13.7996 2.82567 11.99 3.17294 10.2442C3.5202 8.49836 4.37737 6.89471 5.63604 5.63604C6.89472 4.37737 8.49836 3.5202 10.2442 3.17293C11.99 2.82567 13.7996 3.0039 15.4442 3.68508C17.0887 4.36627 18.4943 5.51983 19.4832 6.99987C20.4722 8.47991 21 10.22 21 12C21 14.3869 20.0518 16.6761 18.364 18.364C16.6761 20.0518 14.387 21 12 21V21Z"
        fill="#2A2A2A"
      />
      <path
        d="M12 14C12.1326 14 12.2598 13.9473 12.3536 13.8536C12.4473 13.7598 12.5 13.6326 12.5 13.5V6.5C12.5 6.36739 12.4473 6.24021 12.3536 6.14645C12.2598 6.05268 12.1326 6 12 6C11.8682 6.00256 11.7425 6.05606 11.6493 6.14928C11.5561 6.2425 11.5026 6.36819 11.5 6.5V13.5C11.5 13.6326 11.5527 13.7598 11.6464 13.8536C11.7402 13.9473 11.8674 14 12 14Z"
        fill="#2A2A2A"
      />
      <path
        d="M12 15.44C11.8936 15.44 11.7883 15.461 11.69 15.5017C11.5918 15.5424 11.5025 15.602 11.4272 15.6772C11.352 15.7525 11.2924 15.8418 11.2517 15.94C11.211 16.0383 11.19 16.1436 11.19 16.25C11.19 16.3564 11.211 16.4617 11.2517 16.56C11.2924 16.6582 11.352 16.7475 11.4272 16.8228C11.5025 16.898 11.5918 16.9576 11.69 16.9983C11.7883 17.0391 11.8936 17.06 12 17.06C12.2148 17.06 12.4209 16.9747 12.5728 16.8228C12.7247 16.6709 12.81 16.4648 12.81 16.25C12.81 16.0352 12.7247 15.8292 12.5728 15.6772C12.4209 15.5253 12.2148 15.44 12 15.44V15.44Z"
        fill="#2A2A2A"
      />
    </>
  ),
})
