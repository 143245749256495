import { captureException } from '@sentry/nextjs'
import { isEmpty } from 'lodash'
import { useContext, useEffect } from 'react'

import type { FrontProjectType } from '@miimosa/common/types'
import { useV2User } from '@miimosa/design-system'

import { CartContext } from '@components/Cart/CartContext'
import { Hero, RewardsList } from '@components/pages/merchant'

interface Props {
  slug: string
  project: FrontProjectType
}

const MerchantInterface = ({ slug, project }: Props) => {
  const { createCart } = useContext(CartContext) || {}
  const user = useV2User('mii_fr')
  // const slugHasChanged = cartData?.project_slug !== slug

  useEffect(() => {
    // we only create a cart if there is a user and no cartData id
    // or if project has changed
    if (project && user) {
      const handleCartCreation = async () => {
        try {
          if (project.id) {
            createCart({ project_id: project.id })
          }
        } catch (err) {
          captureException(err)
        }
      }

      handleCartCreation()
      // createCart({ project_id: project.project?.id })
    }
  }, [project, user, createCart])

  if (isEmpty(project)) {
    return null
  }

  const { description, rewards, labels, title, id: projectId } = project
  const { shortDescription, description: descriptionContent, imageFileName, place } = description
  const impacts = labels?.find(({ slug }) => 'impact_goals' === slug)?.labels || []
  const rewardKinds = labels?.find(({ slug }) => 'reward_kind' === slug)?.labels || []

  return (
    <>
      <Hero
        title={title}
        place={place}
        impacts={impacts}
        userId={project.userId}
        projectId={projectId}
        avatarFileName={project.avatarFileName}
        ownerFullName={project.userFullName}
        shortDescription={shortDescription}
        description={descriptionContent}
        imageFileName={imageFileName || ''}
        labels={rewardKinds}
      />
      <RewardsList title={title} rewards={rewards} slug={slug} isPreview={project.state !== 'published'} />
    </>
  )
}

export default MerchantInterface
