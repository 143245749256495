import { Box, Flex, Text } from '@chakra-ui/react'
import { ReactNode } from 'react'

import { Close } from '@miimosa/design-system/components/icons'
import { Info, Error, Success } from '@miimosa/design-system/components/icons/alerts'
import useV2Flash from '@miimosa/design-system/lib/hooks/use_v2_flash'

const matchingColors: Record<string, { icon: ReactNode; color: string }> = {
  success: { icon: <Success />, color: '#00B077' },
  notice: { icon: <Info />, color: '#00B077' },
  error: { icon: <Error />, color: '#F64C46' },
  info: { icon: <Info />, color: '#FFF056' },
  warning: { icon: <Info />, color: '#FFF056' },
  alert: { icon: <Error />, color: '#F64C46' },
}

const Alert = ({ ...props }) => {
  // used to make alert disapear (workaround until utilisation of usePersistedState)
  const { flash, clearFlash } = useV2Flash()

  // TODO: handle displaying multiple messages ?
  // V2 does not support it for now

  if (!flash || !flash.length) {
    return null
  }

  return (
    <Flex w="50%" direction="row" justifyContent="space-between" h="56px" borderRadius="4px" bgColor="white" {...props}>
      <Flex direction="row" h="full" alignItems="center">
        <Box w="6px" h="full" bgColor={matchingColors[flash[0].level]?.color} />
        <Box mx="12px">{matchingColors[flash[0].level]?.icon}</Box>
        <Text size="xs" color="black">
          {flash[0].message}
        </Text>
      </Flex>
      <Flex h="full" alignItems="center" mr="20px">
        <Close h="15px" w="15px" onClick={clearFlash} />
      </Flex>
    </Flex>
  )
}

export default Alert
