import { useRouter } from 'next/router'
import { createContext, useEffect, useReducer } from 'react'

import { usePlatform } from '@miimosa/design-system'

import { formatQueryFilters } from '@helpers/getFilters'

export const FiltersContext = createContext<any | undefined>(undefined)
export const FiltersDispatchContext = createContext<any | undefined>(undefined)
//TODO typing

const FiltersProvider = ({ children }: { children: JSX.Element }) => {
  const { pathname, push, query } = useRouter()
  const { platform } = usePlatform()

  const reducer = (state: any, action: any) => {
    if (action.type === 'add') {
      const newFilters = {
        ...state.filters,
        [action.categoryId]: [...state.filters[action.categoryId], action.newFilter],
      }
      return { filters: newFilters, shouldResetPage: true }
    }
    if (action.type === 'remove') {
      const newFilters = {
        ...state.filters,
        [action.categoryId]: state.filters[action.categoryId].filter((item: string) => item !== action.newFilter),
      }
      return { filters: newFilters, shouldResetPage: true }
    }
    if (action.type === 'addRadio') {
      const newFilters = { ...state.filters, [action.categoryId]: [action.newFilter] }
      return { filters: newFilters, shouldResetPage: true }
    }
    if (action.type === 'clear') {
      const newFilters = Object.entries(state.filters)
        .map(([catId]) => [catId, []])
        .reduce((acc, [cat, data]) => {
          if (cat == 'type') {
            return { ...acc, ...{ [cat as string]: ['lending'] } }
          }
          return { ...acc, ...{ [cat as string]: data } }
        }, {})

      return { filters: newFilters, shouldResetPage: true }
    }
  }

  const defaultFilters = formatQueryFilters(query, platform)

  const [state, dispatch] = useReducer(reducer, {
    filters: defaultFilters,
    shouldResetPage: false,
  })

  // Mandatory
  // Needed because of navbar links to projects not using dispatch.
  useEffect(() => {
    const formattedType = query.type ? (Array.isArray(query.type) ? query.type : [query.type]) : ['lending']

    dispatch({ type: 'addRadio', categoryId: 'type', newFilter: formattedType[0] })
  }, [query.type])

  // Mandatory
  // if you don't update the state by yourself like that,
  // state is not updated because object does not change,
  useEffect(() => {
    type queryType = {
      order?: string[]
      category?: string[]
      impact?: string[]
      thematic?: string[]
      type?: string[]
      rewards?: string[]
      region?: string[]
      page?: number
    }
    let filter: queryType = {
      ...query,
      order: state?.filters['order'],
      category: state?.filters['category'],
      impact: state?.filters['impact'],
      thematic: state?.filters['thematic'],
      type: state?.filters['type'],
      rewards: state?.filters['rewards'],
      region: state?.filters['region'],
    }

    if (query.page != undefined) {
      filter = { ...filter, page: state?.shouldResetPage ? 1 : Number(query.page) || 1 }
    }

    const url = { pathname, query: filter }
    push(url)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  return (
    <FiltersContext.Provider value={state}>
      <FiltersDispatchContext.Provider value={dispatch}>{children}</FiltersDispatchContext.Provider>
    </FiltersContext.Provider>
  )
}

export default FiltersProvider
