import { Flex, Heading, useBreakpointValue } from '@chakra-ui/react'
import NextLink from 'next/link'
import { FC } from 'react'

import type { FrontCollectTypeType, FrontProjectStateType } from '@miimosa/common/types'
import { getProjectStatus } from '@miimosa/design-system'
import { Image } from '@miimosa/design-system/components'

import { ProjectProgress } from '../'

interface Props {
  title: string
  teasing: boolean
  slug: string
  imageFileName?: string | null
  state: FrontProjectStateType
  collectType: FrontCollectTypeType
  endsAt?: string | null
  interestsRatio?: number | null
  width?: string
  height?: string
  withGradient?: boolean
  progress: number
  isCip: boolean
}

const Story: FC<Props> = ({
  title,
  teasing,
  slug,
  imageFileName,
  state,
  collectType,
  endsAt,
  interestsRatio,
  progress = 0,
  width = '390px',
  height = '508px',
  withGradient = true,
}) => {
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: 'md' })
  const projectLink = `/projects/${slug}`
  const { isSuccess, isSoon, isDone } = getProjectStatus({
    status: state,
    teasing: teasing,
  })

  return (
    <NextLink
      href={!teasing ? projectLink : ''}
      className="select_item"
      data-name={title}
      data-projet={collectType}
      style={{
        position: 'relative',
        width: isMobile ? '100%' : width,
        borderRadius: '4px',
        height: height,
        backgroundColor: 'black',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'center',
        overflow: 'hidden',
        zIndex: 0,
      }}
    >
      {imageFileName && (
        <Image
          src={imageFileName}
          style={{ objectFit: 'cover' }}
          fill
          alt=""
          quality={100}
          className={withGradient ? 'gradient' : ''}
          zIndex={1}
          priority
        />
      )}
      <Flex align="center" direction="column" position="relative" zIndex={1} justifyContent="flex-end" h="full" p={10}>
        <ProjectProgress
          isDone={isDone}
          endsAt={endsAt}
          isSoon={isSoon}
          isSuccess={isSuccess}
          collectType={collectType}
          progress={progress}
          interestsRatio={interestsRatio}
        />
        <Heading
          size="sm"
          color="white"
          textAlign="center"
          height={isMobile ? '70px' : '55px'}
          maxH={isMobile ? '70px' : '55px'}
          overflow="hidden"
          textOverflow="ellipsis"
          fontWeight="400"
          mt="4"
        >
          {title}
        </Heading>
      </Flex>
    </NextLink>
  )
}

export default Story
