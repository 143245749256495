import { BoxProps, Text, Flex, MenuList, Heading, Button } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { FC, useContext } from 'react'

import type { PublicUser, FrontRewardType } from '@miimosa/common/types'
import { useV2Link } from '@miimosa/design-system'

import { CartContext } from './CartContext'
import CartRewardItem from './CartRewardItem'

interface Props {
  title: string
  user: PublicUser
  rewardsData: FrontRewardType[]
  slug: string
}

const CartMenuData: FC<Props & BoxProps> = ({ user, slug, rewardsData, title }) => {
  const { cartData, addToCart } = useContext(CartContext)
  const { t } = useTranslation('common')
  const { rewards, uuid: cartId } = cartData
  const { asPath } = useRouter()
  const payURL = useV2Link(`/projects/${slug}/pledges/new?cart_id=${cartId}`)
  const sortedRewards = rewardsData.sort(({ id }) => id)

  if (!user) {
    return (
      <MenuList width="fit-content" mx="2" mt="4" p="8">
        <Flex direction="column" align="center">
          <Heading size="xs" fontWeight="400">
            {t('connect_to_add_articles')}
          </Heading>
          <Button mt="6" as="a" href={`/authenticate?return_url=${asPath}`} variant="primary" size="sm">
            {t('connect')}
          </Button>
        </Flex>
      </MenuList>
    )
  }

  if (!rewards?.length || !cartData) {
    return (
      <MenuList minW="360px" mx="2" mt="4" p="8">
        <Heading size="xs" fontWeight="400" textAlign="center">
          {t('no_articles_in_cart')}
        </Heading>
      </MenuList>
    )
  }

  const handlePay = () => {
    location.href = payURL
  }

  const totalPrice = rewards?.reduce((acc, value) => acc + value.amount * value.quantity, 0)

  return (
    <MenuList
      minW={{ base: '100%', md: '680px' }}
      maxWidth={{ base: '100%', md: '680px' }}
      mx="2"
      p={{ base: 5, md: '8' }}
      mt="4"
    >
      <Text
        size="xl"
        mb="6"
        maxW={{ base: '100%', md: '640px' }}
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
      >
        {t('your_products_at', { name: title })}
      </Text>
      <Flex direction="column" width="100%" maxHeight="240px" overflowY="scroll">
        {sortedRewards.map((reward, index) => {
          const { id: rewardId } = reward
          const cartReward = rewards?.find(({ reward_id }) => reward_id === rewardId)

          if (!cartReward) return null
          const { title, quantity, amount } = cartReward
          // TODO quid image url in cart rewards to avoid props drilling
          const rewardDataImage = rewardsData?.find(({ id }) => id === rewardId)?.imageFileName

          return (
            <CartRewardItem
              key={index}
              id={rewardId}
              cartId={cartId}
              title={title}
              amount={amount}
              quantity={quantity}
              imageFileName={rewardDataImage}
              addToCart={addToCart}
            />
          )
        })}
      </Flex>
      <Button width="100%" disabled={!cartData?.rewards.length} variant="primary" size="md" mt="6" onClick={handlePay}>
        {t('pay')} {totalPrice}€
      </Button>
    </MenuList>
  )
}

export default CartMenuData
