import {
  Text,
  Flex,
  Input,
  InputGroup,
  InputRightAddon,
  Heading,
  Divider,
  Link,
  Button,
  useBreakpointValue,
} from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import { FC, useCallback, useState, useEffect } from 'react'

import type { FrontTermFrequencyType } from '@miimosa/common/types'
import { toV2Link, usePlatform, useV2Link } from '@miimosa/design-system'

interface Props {
  slug: string
  interestsRatio: number
  termQuantity: number
  termFrequency: FrontTermFrequencyType
}

const defaultData = {
  capital: 0,
  interests: 0,
}

const MAX_INVEST = 100000000

const InterestsCalculator: FC<Props> = ({ interestsRatio, termFrequency, termQuantity, slug }) => {
  const { t: tCommon } = useTranslation('common', { keyPrefix: 'calculator' })
  const { t } = useTranslation('project', { keyPrefix: 'collect' })
  const [showInputError, setShowInputError] = useState(false)
  const [showMaxError, setShowMaxError] = useState(false)
  const [amountValue, setAmountValue] = useState<number>(0)
  const [interestsData, setInterestData] = useState<{ capital: number; interests: number }>(defaultData)
  const duration = `${termQuantity} ` + `${t(`lending.period_${termFrequency}`)}`
  const formater = new Intl.NumberFormat('fr-FR', { maximumFractionDigits: 0 })
  const { platform, lang } = usePlatform()
  const isMobile = useBreakpointValue({ base: true, lg: false }, { fallback: 'lg' })

  const fetchInterests = useCallback(async () => {
    const result = await fetch(`/nextapi/loans/simulateInterests`, {
      method: 'POST',
      body: JSON.stringify({ amount: amountValue * 100, project_slug: slug }),
    })

    if (result.status === 200) {
      const data = await result?.json()
      return setInterestData(data)
    }
  }, [slug, amountValue])

  useEffect(() => {
    if (!amountValue) {
      return setInterestData(defaultData)
    }

    if (amountValue >= MAX_INVEST) {
      return setShowMaxError(true)
    }

    if (slug && amountValue) {
      fetchInterests()
    }
  }, [slug, fetchInterests, amountValue])

  const setInputValue = useCallback(
    (inputValue: string) => {
      setShowInputError(false)
      setShowMaxError(false)

      if (isNaN(Number(inputValue))) {
        return setShowInputError(true)
      }

      const numberValue = Number(inputValue)
      setAmountValue(numberValue)
    },
    [setAmountValue]
  )

  return (
    <Flex
      direction="column"
      position="relative"
      border="solid 1px"
      borderRadius="4px"
      borderColor="light_gray"
      bgColor="white"
      height={{ base: 'fit-content', md: 'fit-content' }}
      px={'6'}
      py="8"
      w="full"
      minW="330px"
      maxW={{ base: 'full', lg: '330px' }}
      rowGap={8}
    >
      <Flex direction="column">
        <Heading size="sm" mb={4}>
          {tCommon('simulate')}
        </Heading>
        {showMaxError && (
          <Text size="xs" py="1" color="red">
            {tCommon('max_invest_error')}
          </Text>
        )}
        {showInputError && (
          <Text size="xs" py="1" color="red">
            {tCommon('error')}
          </Text>
        )}
        <Flex direction="column" rowGap={4} alignItems="center">
          <Flex direction={'column'} gap={1} w="full" alignItems="flex-start">
            <Flex align="center">
              <Text size="md" width="fit-content" mr="2">
                {tCommon('if_invest')}
              </Text>
              <InputGroup size="sm" width="calc(100% - 110px)">
                <Input
                  variant="flushed"
                  type="number"
                  width="100%"
                  onKeyDown={(evt) => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
                  onChange={(e) => setInputValue(e?.target?.value)}
                  placeholder={'ex : 1 000€'}
                  _placeholder={{ fontSize: '14px' }}
                />
                <InputRightAddon pointerEvents="none" border="none" backgroundColor="transparent">
                  <Text color="dark_green" fontWeight="bold">
                    €
                  </Text>
                </InputRightAddon>
              </InputGroup>
            </Flex>
            <Text>
              {tCommon('example', {
                duration,
                rate: ((interestsRatio || 0) * 100).toFixed(2).replace(/\./g, ','),
              })}
            </Text>
            <Text>
              {tCommon('receive')}
              <span style={{ color: '#638dff' }}>
                {interestsData?.interests !== 0
                  ? formater.format((interestsData?.interests + interestsData?.capital) / 100)
                  : '-'}
                €
              </span>{' '}
              {tCommon('as')}{' '}
              <span style={{ color: '#638dff' }}>
                {interestsData?.interests !== 0 ? formater.format(interestsData?.interests / 100) : '-'}€
              </span>{' '}
              {tCommon('interests')}.
            </Text>
          </Flex>
          {isMobile && (
            <Link href={toV2Link(`/projects/${slug}/pledges/new`, platform, lang)} w="full">
              <Button variant="primary" size="md" w="full">
                Investir
              </Button>
            </Link>
          )}
        </Flex>
      </Flex>
      {/* <Flex direction="column" rowGap={6} mt="10">
        <Accordion defaultIndex={0} allowMultiple>
          <AccordionItem border="none" borderBottom="solid 0.5px black" borderWidth="0.5px !important">
            <AccordionButton
              px={0}
              _hover={{ bgColor: 'transparent' }}
              _expanded={{ borderBottom: 'solid 0.5px black' }}
            >
              <Box flex="1" textAlign="left">
                <Text size="sm">{t('how_it_works')}</Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4} px={0} pl="2px">
              <Flex direction="column" align="flex-start">
                {t('how_text')}
              </Flex>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem border="none" borderBottom="solid 0.5px black" borderWidth="0.5px !important">
            <AccordionButton
              px={0}
              _hover={{ bgColor: 'transparent' }}
              _expanded={{ borderBottom: 'solid 0.5px black' }}
            >
              <Box flex="1" textAlign="left">
                <Text size="sm">{t('taxed')}</Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4} px={0} pl="2px">
              <Flex direction="column" align="flex-start">
                {t('taxed_text')}
              </Flex>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem border="none" borderBottom="solid 0.5px black" borderWidth="0.5px !important">
            <AccordionButton
              px={0}
              _hover={{ bgColor: 'transparent' }}
              _expanded={{ borderBottom: 'solid 0.5px black' }}
            >
              <Box flex="1" textAlign="left">
                <Text size="sm">{t('interest')}</Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4} px={0} pl="2px">
              <Flex direction="column" align="flex-start">
                {t('interest_text')}
              </Flex>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem border="none" borderBottom="solid 0.5px black" borderWidth="0.5px !important">
            <AccordionButton
              px={0}
              _hover={{ bgColor: 'transparent' }}
              _expanded={{ borderBottom: 'solid 0.5px black' }}
            >
              <Box flex="1" textAlign="left">
                <Text size="sm">{t('twice')}</Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4} px={0} pl="2px">
              <Flex direction="column" align="flex-start">
                {t('twice_text')}
              </Flex>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Flex> */}
      {/* <Link variant="ghost" href="">
        <Text size="sm" variant="underlined" mt={4} color="green">
          {t('know_more')}
        </Text>
      </Link> */}
      <Flex direction="column" rowGap={5}>
        <Flex direction="column">
          <Text size="md" fontWeight="bold">
            Fiscalité
          </Text>
          <Divider borderColor="main_gray" mb={4} />
          <Text size="md">
            En tant que personne physique (et hors cas de dispense), les intérêts que vous percevez en contrepartie des
            sommes prêtées sont soumis à un prélèvement forfaitaire de 30% (cotisations sociales CSG-CRDS et impôt sur
            le revenu).
          </Text>
          <Link size="xs" href={`${useV2Link('help_center')}#legal-finance`} target="_blank" textDecoration="underline">
            En savoir plus sur la fiscalité du financement participatif.
          </Link>
        </Flex>
        <Flex direction="column">
          <Text size="md" fontWeight="bold">
            Aucun frais
          </Text>
          <Divider borderColor="main_gray" mb={4} />
          <Text size="md">Il n&apos;existe aucun frais pour les investisseurs.</Text>
          <Link
            size="xs"
            href={`${useV2Link('help_center')}#project-leaders`}
            target="_blank"
            textDecoration="underline"
          >
            Tout savoir sur les frais appliqués par MiiMOSA.
          </Link>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default InterestsCalculator
