import { Box, Flex, Text, useRadio } from '@chakra-ui/react'
import { FC } from 'react'

import Icon, { IconName } from '@miimosa/design-system/components/Icon'

interface Props {
  checked?: boolean
  iconColor?: string
  iconName?: IconName
  index: number
  value: string
  label?: string
  segmentRef?: any
  onInputChange: (v: string, i: number) => void
}

const SegmentedRadio: FC<Props> = (props) => {
  const { value, label, onInputChange, index, segmentRef, checked, iconColor, iconName } = props
  const { getInputProps, getCheckboxProps } = useRadio(props)
  const input = getInputProps()
  const checkbox = getCheckboxProps()
  const segmentIconColor = checked ? 'white' : iconColor

  return (
    <Box as="label" ref={segmentRef}>
      <input {...input} onChange={() => onInputChange(value, index)} />
      <Flex {...checkbox} width="100%" height="100%" align="center" cursor="pointer" id={label} p="12px">
        {iconName && <Icon size="4xs" name={iconName} color={segmentIconColor} pr="8px" />}
        <Text
          size="md"
          align="center"
          cursor="pointer"
          display="block"
          transition="color 0.5s ease"
          color={checked ? 'white' : 'main_gray'}
        >
          {label}
        </Text>
      </Flex>
    </Box>
  )
}

export default SegmentedRadio
