import { Heading, Flex, Button } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import { ForwardedRef, forwardRef } from 'react'

import type { FrontCollectTypeType } from '@miimosa/common'
import { toV2Link, usePlatform } from '@miimosa/design-system'

interface Props {
  projectSlug?: string
  isPreview: boolean
  collectType: FrontCollectTypeType
}

const DefaultReward = forwardRef(function DefaultReward(
  { projectSlug, isPreview, collectType }: Props,
  ref: ForwardedRef<HTMLButtonElement>
) {
  const { t } = useTranslation('project')
  const { platform, lang } = usePlatform()

  const redirectToV2 = () => {
    location.href = toV2Link(`/projects/${projectSlug}/pledges/new`, platform, lang)
  }

  return (
    <>
      <Heading textAlign="left">
        {collectType === 'lending' ? t('rewards.lending_title') : t('rewards.donation_title')}
      </Heading>
      <Flex
        direction="column"
        bg="white"
        w="full"
        px={4}
        py={6}
        border="solid 1px"
        borderRadius="4px"
        borderColor="light_gray"
        rowGap={4}
      >
        <Button
          variant="primary"
          size="md"
          w="full"
          isDisabled={isPreview}
          disabled={isPreview}
          onClick={() => {
            redirectToV2()
          }}
          ref={ref}
        >
          {collectType === 'lending' ? t('rewards.lending_without_rewards') : t('rewards.donation_without_rewards')}
        </Button>
      </Flex>
    </>
  )
})

export default DefaultReward
