import { BoxProps, Flex, Text } from '@chakra-ui/react'
import { type Dispatch, FC, type SetStateAction } from 'react'

import Icon, { IconName } from '@miimosa/design-system/components/Icon'

interface Props {
  iconName: IconName
  text: string
  value: string
  setOption: Dispatch<SetStateAction<string | undefined>>
  isSelected: boolean
}

const IconCard: FC<Props & BoxProps> = ({ iconName, text, value, setOption, isSelected, ...restProps }) => {
  return (
    <Flex
      borderStyle="solid"
      borderWidth={isSelected ? '2px' : '1px'}
      borderColor={isSelected ? 'dark_green' : 'lighten'}
      as="button"
      borderRadius="4px"
      onClick={() => setOption(value)}
      align="center"
      justify="center"
      width="256px"
      height="166px"
      position="relative"
      direction="column"
      px={{ base: 6, md: 8 }}
      py={{ base: 6, md: 6 }}
      {...restProps}
      _hover={{
        border: 'solid 2px #003F2C',
      }}
    >
      {isSelected && (
        <Flex
          backgroundColor="dark_green"
          align="center"
          justify="center"
          borderRadius="50%"
          width="20px"
          height="20px"
          position="absolute"
          right="8px"
          top="8px"
        >
          <Icon name="CheckMark" color="white" size="5xs" />
        </Flex>
      )}
      <Icon name={iconName} size="md" />
      <Text size="lg" mt="4">
        {text}
      </Text>
    </Flex>
  )
}

export default IconCard
