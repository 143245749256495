import { IconButton, Stack, StackProps } from '@chakra-ui/react'
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaTwitter } from 'react-icons/fa'

const socials = [
  {
    label: 'Facebook',
    href: 'https://www.facebook.com/MiiMOSAfr',
    Ico: FaFacebookF,
  },
  {
    label: 'Twitter',
    href: 'https://www.twitter.com/MiiMOSA_fr',
    Ico: FaTwitter,
  },
  {
    label: 'Instagram',
    href: 'https://www.instagram.com/MiiMOSA_fr',
    Ico: FaInstagram,
  },
  {
    label: 'Linkedin',
    href: 'https://www.linkedin.com/company/miimosa',
    Ico: FaLinkedinIn,
  },
]

interface Props extends StackProps {
  iconColor?: string
  highlightIconColor?: string
}

export const SocialStack = ({ iconColor = '#354A48', highlightIconColor = 'dark_green', ...params }: Props) => (
  <Stack spacing={3} {...params}>
    {socials.map(({ label, href, Ico }) => (
      <IconButton
        aria-label={label}
        as="a"
        p="3px"
        variant="ghost"
        href={href}
        key={label}
        target="_blank"
        icon={<Ico />}
        color={iconColor}
        _hover={
          highlightIconColor
            ? {
                color: highlightIconColor,
              }
            : {}
        }
      />
    ))}
  </Stack>
)

export default SocialStack
