import { Avatar, Flex, Text, Link, Heading, Collapse, BoxProps, IconProps, Divider, Tooltip } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import NextLink from 'next/link'
import { useState, FC, useContext } from 'react'

import type { PublicUser } from '@miimosa/common/types'
import { ChevronDown, ChevronRight } from '@miimosa/design-system/components/icons'
import IconLogout from '@miimosa/design-system/components/icons/Logout'
import { useLogout, usePlatform } from '@miimosa/design-system/lib/hooks'
import { toV2Link } from '@miimosa/design-system/lib/hooks/use_v2_link'

import ComponentLink from '@components/Header/Link'
import { WalletContext } from '@components/WalletProvider'

import { DotColor } from './DesktopMenuItem'
import { LoggedItems } from './LoggedItems'
import { NavLink, useNavItems } from './utils'

interface CollapseProps extends BoxProps {
  title: string
  TitleIcon?: FC<IconProps>
  links?: NavLink[]
  isOpen?: boolean
  onClick?: () => void
  dotColor?: string
  isSmall?: boolean
  route?: string
}

const BasicCollapse = ({ isSmall, title, links, isOpen, dotColor, onClick, route, ...props }: CollapseProps) => {
  return (
    <Flex direction="column" onClick={onClick} {...props}>
      <Flex px="20px" align="center" mt={isSmall ? '15px' : '21px'} bg={isOpen ? 'dark_green' : ''}>
        {dotColor && <DotColor dotColor={dotColor} />}
        {isSmall ? (
          <Text as="a" href={route} size="xl" color="white" ml="2">
            <Flex direction="row">{title}</Flex>
          </Text>
        ) : (
          <Heading as="h3" size="md" color="white" ml="2">
            <Flex direction="row">{title}</Flex>
          </Heading>
        )}
        <Flex direction="row" align="center" cursor="pointer" transition="transform 0.2s linear" ml="auto">
          {isOpen ? <ChevronDown color="white" height="10px" /> : <ChevronRight height="10px" color="white" />}
        </Flex>
      </Flex>
      {links && (
        <Collapse in={isOpen} animateOpacity>
          <Flex direction="column">
            <Flex direction="column" ml="14px" px="20px">
              {links.map(({ text, href, route }, index) =>
                href ? (
                  <Link
                    href={href}
                    key={`${text}_${index}`}
                    size="md"
                    ml="3"
                    color="white"
                    mt={index === 0 ? '36px' : '17px'}
                  >
                    {text}
                  </Link>
                ) : (
                  route && (
                    <NextLink href={route} passHref legacyBehavior>
                      <Link
                        href={route}
                        key={`${text}_${index}`}
                        size="md"
                        ml="3"
                        color="white"
                        mt={index === 0 ? '36px' : '17px'}
                      >
                        {text}
                      </Link>
                    </NextLink>
                  )
                )
              )}
            </Flex>
          </Flex>
        </Collapse>
      )}
    </Flex>
  )
}

const MobileNav = ({ user }: { user?: PublicUser }) => {
  const [selectedTab, setSelectedTab] = useState<number>(-1)

  const onArrowClick = (index: number) => {
    if (index === selectedTab) {
      setSelectedTab(-1)
    } else {
      setSelectedTab(index)
    }
  }

  const { platform, lang } = usePlatform()
  const logout = useLogout()
  const navItems = useNavItems(user)
  const { t } = useTranslation()

  const [tooltip, setTooltip] = useState(false)

  const wallet = useContext(WalletContext)

  return (
    <Flex direction="column" bg="dark_green" alignItems="left" minHeight="100%">
      <Flex direction="column" flex="1 1 0%">
        {user && (
          <>
            <Flex direction="row" justifyContent="space-between" px="20px">
              <Heading as="h3" size="sm">
                <Flex direction="row" alignItems="center">
                  <Avatar mr="8px" size="md" name={user.initials} src={user.avatar ?? undefined} />
                  {wallet && wallet.amount >= 0 ? (
                    <Tooltip
                      label={`Mis à jour à ${new Date(wallet.amount_synced_at).toLocaleString('fr-FR', {
                        hour: 'numeric',
                        minute: 'numeric',
                      })} le ${new Date(wallet.amount_synced_at).toLocaleString('fr-FR', {
                        timeZone: 'UTC',
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                      })}`}
                      isOpen={tooltip}
                    >
                      <Flex
                        direction="column"
                        columnGap={1}
                        my={2}
                        alignItems="flex-start"
                        color="white"
                        onClick={() => setTooltip(!tooltip)}
                      >
                        <Text size="md">{t('header.available_wallet_amount_2')}</Text>
                        <Text size="xl" fontWeight="bold">
                          {new Intl.NumberFormat('fr-FR', {
                            style: 'currency',
                            currency: 'EUR',
                            maximumFractionDigits: 2,
                          }).format(wallet.amount / 100)}
                        </Text>
                      </Flex>
                    </Tooltip>
                  ) : (
                    <Flex
                      direction="column"
                      columnGap={1}
                      my={2}
                      alignItems="flex-start"
                      color="white"
                      onClick={() => setTooltip(!tooltip)}
                    >
                      <Text size="md">{t('header.available_wallet_amount_2')}</Text>
                      <Text size="xl" fontWeight="bold">
                        {new Intl.NumberFormat('fr-FR', {
                          style: 'currency',
                          currency: 'EUR',
                          maximumFractionDigits: 2,
                        }).format(0)}
                      </Text>
                    </Flex>
                  )}
                </Flex>
              </Heading>
            </Flex>
            <Flex direction="column" px="20px" py="8px">
              <ComponentLink href={toV2Link(`/users/${user.slug}/edit`, platform, lang)}>
                <Text color="white" size="xl" _hover={{ textDecoration: 'underline' }}>
                  {t('header.logged.manage_account')}
                </Text>
              </ComponentLink>
            </Flex>
            <LoggedItems user={user} />
          </>
        )}
        <Flex direction="column" flex="1 1 0%">
          <Divider borderColor={'border'} opacity="0.1" />
          <Flex direction="column">
            {navItems.slice(0, 2).map(({ label, dotColor, links }, index) => (
              <BasicCollapse
                key={`${label}_${index}`}
                title={label}
                dotColor={dotColor}
                links={links}
                isOpen={index === selectedTab}
                onClick={() => onArrowClick(index)}
                isSmall
              />
            ))}
          </Flex>
          <Divider borderColor="border" mt="15px" opacity="0.1" />
          <Flex direction="column" px={'20px'}>
            {navItems.slice(2).map(({ label, route }, index) => (
              <NextLink href={`${route}`} key={`${label}_${index}`}>
                <Text size="xl" color="white" _hover={{ textDecoration: 'underline', fontWeight: 'bold' }} py="8px">
                  {label}
                </Text>
              </NextLink>
            ))}
          </Flex>
        </Flex>
        {user ? (
          <Flex
            w="100%"
            justifyContent="center"
            mb={{ base: '32px', md: '24px' }}
            mt={{ base: '38px', md: '16px' }}
            _hover={{ fontWeight: 'bold', textDecoration: 'underline' }}
            as="button"
            alignItems="center"
            onClick={logout}
            color="white"
          >
            <IconLogout fill="dark_green" mr="9px" />
            <Text size="xl">{t('header.logout')}</Text>
          </Flex>
        ) : (
          <Flex
            w="100%"
            justifyContent="center"
            mb={{ base: '32px', md: '24px' }}
            mt={{ base: '38px', md: '16px' }}
            alignItems="center"
            color="white"
          >
            <ComponentLink
              href={toV2Link(`/authenticate`, platform, lang)}
              _hover={{ fontWeight: 'bold', color: 'white', textDecoration: 'underline' }}
            >
              <Text size="xl">{t('header.nav.account.title')}</Text>
            </ComponentLink>
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}

export default MobileNav
