export const youtubeVideoRegex = new RegExp('^(https?://)?((www.)?youtube.com|youtu.be)/.+$')
export const youtubeIdVideoRegex = new RegExp('(?:[?&]v=|/embed/|/1/|/v/|https://(?:www.)?youtu.be/)([^&\n?#]+)')
export const vimeoVideoRegex = new RegExp(
  '(?:http:|https:|)//(?:player.|www.)?vimeo.com/(?:video/|embed/|watch?S*v=|v/)?(\\d*)'
)

export const getVideoProvider = ({ videoUrl }: { videoUrl: string }): 'youtube' | 'vimeo' | undefined => {
  if (youtubeVideoRegex.test(videoUrl)) return 'youtube'
  if (vimeoVideoRegex.test(videoUrl)) return 'vimeo'
}

export const matchVideo = ({ videoUrl, regexp }: { videoUrl: string; regexp: RegExp }) => {
  return videoUrl.match(regexp)
}

export const getValidVideoId = ({ videoUrl, regexp }: { videoUrl: string; regexp: RegExp }) => {
  const match = matchVideo({ videoUrl: videoUrl, regexp: regexp })

  if (regexp === youtubeIdVideoRegex) {
    return match && match[1].length == 11 ? match[1] : false
  } else if (regexp === vimeoVideoRegex) {
    return match && match[1] ? match[1] : false
  } else {
    return false
  }
}

export const youtubeBaseUrl = 'https://www.youtube.com/embed/'
export const vimeoBaseUrl = 'https://player.vimeo.com/video/'
