import { FlexProps, Flex } from '@chakra-ui/react'
import { FC, ReactNode } from 'react'

interface Props {
  children: ReactNode
}

const FullWidthRightColumn: FC<Props & FlexProps> = ({ children, ...props }) => {
  return (
    <Flex
      h={{ base: 'auto', lg: 'calc(100vh - 64px)' }}
      w="full"
      flex={{ base: '1 0 100%', lg: '1 0 33%', '2xl': '1 0 44%' }}
      maxW={{ base: '100%', lg: '33%', '2xl': '44%' }}
      bg="light_gray"
      direction="column"
      p={10}
      minHeight={{ base: 'auto', lg: 'calc(100vh - 64px)' }}
      {...props}
    >
      {children}
    </Flex>
  )
}

export default FullWidthRightColumn
