import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react'
import { UseFormSetValue } from 'react-hook-form'

import { Icon } from '@miimosa/design-system'
import { Error } from '@miimosa/design-system/components/icons/alerts'

import { PSFPFormValues } from '@helpers/psfp/types'

interface Props {
  isOpen: boolean
  onClose: () => void
  setValue: UseFormSetValue<PSFPFormValues>
  modalTitle: string
  modalContent: string
  inputToUpdate: keyof PSFPFormValues
  valueToUpdate: string
}

const WarningModal = ({ isOpen, onClose, setValue, inputToUpdate, valueToUpdate, modalTitle, modalContent }: Props) => {
  const CloseAndSetValue = () => {
    setValue(inputToUpdate, valueToUpdate)
    onClose()
  }

  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: 'md' })

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW="800px" minH="288px" p={{ base: 4, md: 8 }} py={{ base: 12, md: 8 }}>
        <ModalHeader p="0">
          <Flex
            bg="dark_green"
            w="25px"
            h="25px"
            borderRadius="24px"
            alignItems="center"
            justifyContent="center"
            position="absolute"
            top="-12.5px"
            right={isMobile ? '0px' : '-12.5px'}
            onClick={onClose}
            _hover={{
              cursor: 'pointer',
            }}
          >
            <Icon name="Close" size="4xs" color="white" />
          </Flex>
          <Flex direction="row" columnGap={3} h="24px" alignItems="center">
            <Box w="4px" h="full" bg="yellow" />
            <Flex>
              <Error />
            </Flex>
            <Text size="md" fontWeight="700">
              {modalTitle}
            </Text>
          </Flex>
        </ModalHeader>
        <ModalBody p="0" my={{ base: 16, md: 8 }}>
          <Text size="md">{modalContent}</Text>
        </ModalBody>

        <ModalFooter justifyContent="center" p="0">
          <Button variant="primary" size="sm" mr={3} onClick={() => CloseAndSetValue()}>
            J’ai compris, je change ma réponse
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default WarningModal
