import { type GetServerSidePropsContext } from 'next'

import { Payment } from '@miimosa/protocol'

import { BaseAPI, Result } from '../api'

import { createV3Client, ErrorV3, toV3Result } from './base'

export class UserAPI extends BaseAPI {
  constructor(ctx?: GetServerSidePropsContext) {
    super(createV3Client(ctx), toV3Result)
  }

  async getWallet(params: Payment.FetchWalletRequest): Promise<Result<Payment.FetchWalletResponse, ErrorV3>> {
    return await this.call<Payment.FetchWalletResponse, ErrorV3>((c) => c.post('/payment/v1/FetchWallet', params))
  }
}
