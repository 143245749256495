import { Flex, Heading, Text, BoxProps } from '@chakra-ui/react'
import { FC } from 'react'

interface Props {
  title: string
  description?: string
}

const HeadingBlock: FC<Props & BoxProps> = ({ title, description, ...props }) => {
  return (
    <Flex
      direction={{ base: 'column', md: 'row' }}
      width="100%"
      justify={{ md: 'space-between' }}
      align="flex-start"
      {...props}
    >
      <Heading size="lg">{title}</Heading>
      <Flex direction="column" maxWidth={{ base: '100%', md: '311px' }} mt={{ base: 10, md: 0 }}>
        {description && (
          <Text size="md" mb="4">
            {description}
          </Text>
        )}
      </Flex>
    </Flex>
  )
}

export default HeadingBlock
