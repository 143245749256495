import { Flex, Text, Heading, Button, useDisclosure } from '@chakra-ui/react'
import { Dispatch, SetStateAction } from 'react'
import { type SubmitHandler, useForm } from 'react-hook-form'

import { Icon } from '@miimosa/design-system'
import type { Leads } from '@miimosa/protocol'

import { CheckboxInput, HeadingIntro, RadioInput, WarningModal } from '@components'
import { formatData, previousPSFPFunnel, updatePSFPFunnel } from '@helpers/psfp'
import type { PSFPFormValues } from '@helpers/psfp/types'

interface Props {
  funnelStep?: Leads.Step
  leadId: string
  setCurrentFunnel: Dispatch<SetStateAction<Leads.UpdateResponse>>
}

const InvestmentObjectives = ({ funnelStep, leadId, setCurrentFunnel }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const {
    handleSubmit,
    setValue,
    control,
    register,
    formState: { errors },
  } = useForm<PSFPFormValues>({
    defaultValues: {
      INPUT_NAME_SECTORS_OF_INTEREST: funnelStep?.input_descriptors[0].default_value?.str
        ? funnelStep?.input_descriptors[0].default_value?.str.split(',')
        : [],
      INPUT_NAME_RISK_APPETITE: funnelStep?.input_descriptors[1].default_value?.str,
      INPUT_NAME_INVESTMENT_HORIZON: funnelStep?.input_descriptors[2].default_value?.str
        ? funnelStep?.input_descriptors[2].default_value?.str.split(',')
        : [],
      INPUT_NAME_STRESS_FOR_CASH:
        funnelStep?.input_descriptors[3].default_value != undefined
          ? String(funnelStep?.input_descriptors[3].default_value?.boolean)
          : undefined,
    },
  })

  const onSubmit: SubmitHandler<PSFPFormValues> = (data) => {
    if (data['INPUT_NAME_STRESS_FOR_CASH'] == 'true') {
      onOpen()
    } else {
      const formattedData = formatData({ data: data, leadId: leadId, funnelStep: funnelStep })
      const response = updatePSFPFunnel(formattedData)

      response.then((newData) => {
        if (newData) {
          setCurrentFunnel(newData)
        }
      })
    }
  }

  const onPrevious = () => {
    const response = previousPSFPFunnel({
      funnel_type: 'FUNNEL_TYPE_PSFP',
      lead_id: leadId,
    })

    response.then((newData) => {
      if (newData) {
        const createResp: Leads.UpdateResponse = {
          step: newData.step,
          progress: newData.progress,
          lead_id: leadId,
          salesforce_ref: '',
          outputs: [],
        }

        setCurrentFunnel(createResp)
      }
    })
  }

  if (!funnelStep) return null

  return (
    <Flex direction="column" as="form">
      <WarningModal
        isOpen={isOpen}
        onClose={onClose}
        setValue={setValue}
        modalTitle={"Attention : n'investissez pas l'argent dont vous êtes susceptible d'avoir besoin"}
        modalContent={
          "Il n'existe pas de marché secondaire pour les placements proposés par les plateformes de financement participatif : revendre les titres de créance que vous détenez avant leur terme sera difficile, voire impossible. Aussi nous vous conseillons de n’investir que les sommes dont vous n’avez pas besoin."
        }
        inputToUpdate={'INPUT_NAME_STRESS_FOR_CASH'}
        valueToUpdate={'false'}
      />
      <Flex direction="column" rowGap={8}>
        <HeadingIntro
          texts={[]}
          subTexts={[
            <Flex direction="column" key="subtext1" rowGap={3}>
              <Text size="md" color="dark_green" fontWeight="700">
                D’après les réponses que vous avez fournies : vous êtes un “investisseur non-averti”. Aussi, nous devons
                vous poser quelques questions supplémentaires afin de vous proposer un usage adapté de notre plateforme.
              </Text>
              <Flex direction="row" alignItems="center" columnGap={2}>
                <Icon size="4xs" name="Time" color="dark_green" />
                <Text color="dark_green">Cela devrait vous prendre moins de 5 minutes</Text>
              </Flex>
            </Flex>,
          ]}
        />
        <Flex direction="column" rowGap={8}>
          <Heading size={{ base: 'xs', md: 'sm' }} color="dark_green">
            Vos objectifs d’investissement
          </Heading>
          <Flex direction="column" rowGap={5}>
            {/* first question */}
            <CheckboxInput
              register={register}
              inputName={'INPUT_NAME_SECTORS_OF_INTEREST'}
              title={'Quels sont les secteurs d’activité qui vous intéressent ?'}
              values={[
                { value: 'agriculture', trad: 'Agriculture' },
                { value: 'food', trad: 'Agro-Alimentaire' },
                { value: 'renewable', trad: 'Energies renouvelables' },
              ]}
              errors={errors}
              isRequired={funnelStep.input_descriptors[0].validation?.required}
            />
            {/* second question */}
            <RadioInput
              title="Quel degré de risque êtes-vous prêt à supporter ?"
              control={control}
              inputName="INPUT_NAME_RISK_APPETITE"
              isRequired={funnelStep.input_descriptors[1].validation?.required}
              errors={errors}
              radioValues={[
                { value: 'low', trad: 'Faible' },
                { value: 'medium', trad: 'Modéré' },
                { value: 'high', trad: 'Élevé' },
              ]}
            />
            {/* Third Question */}
            <CheckboxInput
              register={register}
              inputName={'INPUT_NAME_INVESTMENT_HORIZON'}
              title={'Quelle est la durée envisagée de vos placements sur la plateforme ?'}
              values={[
                { value: 'short', trad: '1 à 3 ans' },
                { value: 'medium', trad: '3 à 5 ans' },
                { value: 'long', trad: '5 à 7 ans' },
              ]}
              errors={errors}
              isRequired={funnelStep.input_descriptors[2].validation?.required}
            />
            {/* Fourth Question */}
            <RadioInput
              title="Êtes-vous susceptible d’avoir besoin des sommes investies sur la plateforme pendant la durée de votre
                placement ?"
              control={control}
              inputName="INPUT_NAME_STRESS_FOR_CASH"
              isRequired={funnelStep.input_descriptors[3].validation?.required}
              errors={errors}
              radioValues={[
                { value: 'true', trad: 'Oui' },
                { value: 'false', trad: 'Non' },
              ]}
              radioDirection="row"
            />
          </Flex>
        </Flex>
        <Flex w="full" justifyContent="center" columnGap={8}>
          <Button variant="secondary" size="md" onClick={onPrevious}>
            Précédent
          </Button>
          <Button variant="primary" size="md" onClick={handleSubmit(onSubmit)}>
            Suivant
          </Button>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default InvestmentObjectives
