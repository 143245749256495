import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  Button,
  BoxProps,
  Heading,
  Text,
  Flex,
} from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { FC, useContext } from 'react'

import type { PublicUser, FrontRewardType } from '@miimosa/common/types'
import { LeftArrow, useV2Link } from '@miimosa/design-system'

import { CartContext } from './CartContext'
import CartRewardItem from './CartRewardItem'

interface Props {
  title: string
  user: PublicUser
  rewardsData: FrontRewardType[]
  slug: string
  isOpen: boolean
  onClose: () => void
}

const CartDrawer: FC<Props & BoxProps> = ({ title, user, rewardsData, slug, isOpen, onClose }) => {
  const { cartData, addToCart } = useContext(CartContext)
  const { t } = useTranslation('common')
  const { rewards, uuid: cartId } = cartData
  const { asPath } = useRouter()
  const payURL = useV2Link(`/projects/${slug}/pledges/new?cart_id=${cartId}`)
  const sortedRewards = rewardsData.sort(({ id }) => id)

  if (!user) {
    return (
      <Flex direction="column" align="center">
        <Heading size="xs" fontWeight="400">
          {t('connect_to_add_articles')}
        </Heading>
        <Button mt="6" as="a" href={`/authenticate?return_url=${asPath}`} variant="primary" size="sm">
          {t('connect')}
        </Button>
      </Flex>
    )
  }

  if (!rewards?.length || !cartData) {
    return (
      <Heading size="xs" fontWeight="400" textAlign="center">
        {t('no_articles_in_cart')}
      </Heading>
    )
  }

  const handlePay = () => {
    location.href = payURL
  }

  const totalPrice = rewards?.reduce((acc, value) => acc + value.amount * value.quantity, 0)

  return (
    <Drawer isOpen={isOpen} placement="right" size="full" onClose={onClose}>
      <DrawerOverlay marginTop="72px" />
      <DrawerContent borderTop="solid 1px" borderColor="light_gray" marginTop="72px">
        <DrawerHeader onClick={onClose}>
          <Flex align="center">
            <LeftArrow color="black" mr="2" width="12px" />
            <Text size="sm">{t('return')}</Text>
          </Flex>
        </DrawerHeader>
        <DrawerBody maxHeight="calc(100vh - 240px)" borderTop="solid 1px" borderColor="light_gray">
          <Text
            size="xl"
            my="4"
            maxW={{ base: '100%', md: '640px' }}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {t('your_products_at', { name: title })}
          </Text>
          <Flex direction="column" width="100%" maxHeight="300px" overflowY="scroll">
            {sortedRewards.map((reward, index) => {
              const { id: rewardId } = reward
              const cartReward = rewards?.find(({ reward_id }) => reward_id === rewardId)

              if (!cartReward) return null
              const { title, quantity, amount } = cartReward
              // TODO quid image url in cart rewards to avoid props drilling
              const rewardDataImage = rewardsData?.find(({ id }) => id === rewardId)?.imageFileName

              return (
                <CartRewardItem
                  key={index}
                  id={rewardId}
                  cartId={cartId}
                  title={title}
                  amount={amount}
                  quantity={quantity}
                  imageFileName={rewardDataImage}
                  addToCart={addToCart}
                />
              )
            })}
          </Flex>
          <Flex align="center" width="100%" justify="space-between" mt="4" mb="6">
            <Text size="md">
              {t('total_articles')}
              <br /> {t('tva_delivery_included')}
            </Text>
            <Text size="md">{totalPrice} €</Text>
          </Flex>
        </DrawerBody>
        <DrawerFooter height="78px" py="0" borderTop="solid 1px" pt="2" borderColor="light_gray">
          <Flex align="center" width="100%" justify="center">
            <Button width="100%" disabled={!cartData?.rewards.length} variant="primary" size="md" onClick={handlePay}>
              {t('pay')}
            </Button>
          </Flex>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}

export default CartDrawer
