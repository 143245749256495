import { Loans } from '@miimosa/protocol'

import client from './client'

export const simulateInterests = async ({ amount, project_slug }: Loans.SimulateInterestsRequest) => {
  return await client.post<Loans.SimulateInterestsResponse>('/twirp/miimosa.loans.v1.LoansService/SimulateInterests', {
    amount,
    project_slug,
  })
}
