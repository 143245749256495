import { Text, Flex, Button, forwardRef, BoxProps, useBreakpointValue } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import { FC } from 'react'

import { Icon } from '@miimosa/design-system'
import { useV2User } from '@miimosa/design-system'

interface Props {
  totalItems?: number
  onClick: () => void
}

const CartButton: FC<Props & BoxProps> = forwardRef<Props & BoxProps, 'div'>(({ onClick, totalItems }, ref) => {
  const user = useV2User('mii_fr')
  const { t } = useTranslation('common')
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: 'md' })

  const CartIcon = (
    <Flex position="relative">
      {!!totalItems && user && (
        <Flex
          align="center"
          bgColor="orange"
          position="absolute"
          top="-10px"
          right="-20px"
          py="1"
          px="3"
          borderRadius="28px"
          zIndex="1"
        >
          <Text size={{ base: 'xs', md: 'sm' }} color="white">
            {totalItems}
          </Text>
        </Flex>
      )}
      <Icon name="Cart" size={isMobile ? '4xs' : '3xs'} />
    </Flex>
  )

  return (
    <Button variant="secondary" ref={ref} size={{ base: 'smallCart', md: 'cart' }} onClick={onClick}>
      <Text size={{ base: 'lg', md: 'xl' }} fontWeight="400" mr="2">
        {t('myCart')}
      </Text>
      {CartIcon}
    </Button>
  )
})

export default CartButton
