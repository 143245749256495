import { Text, Flex } from '@chakra-ui/react'
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import NumberFormat from 'react-number-format'

const CardNumber = ({
  Icon,
  number,
  description,
  symbol,
  showDecimal,
}: {
  Icon: ReactJSXElement
  number: number
  description: string
  symbol?: string
  showDecimal?: boolean
}) => {
  return (
    <Flex direction="column" bg="white" borderRadius="4px" pt={10} pb={10} px={6}>
      <Flex justifyContent="center">{Icon}</Flex>
      <Text align="center" fontSize="41px" lineHeight="44px" color="dark_green" fontWeight="bold" mt={8} mb={4}>
        <NumberFormat
          value={number}
          displayType={'text'}
          decimalScale={showDecimal ? 2 : 0}
          decimalSeparator={showDecimal ? ',' : ''}
          thousandSeparator={' '}
          suffix={symbol ? ' ' + symbol : ''}
        />
      </Text>
      <Text maxW="full" align="center" size="lg" fontWeight="bold">
        {description}
      </Text>
    </Flex>
  )
}

export default CardNumber
