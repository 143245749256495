import { ChevronDownIcon } from '@chakra-ui/icons'
import { BoxProps, Collapse, Flex, Text, useDisclosure } from '@chakra-ui/react'
import { ReactElement, useEffect } from 'react'

interface Props extends BoxProps {
  header?: ReactElement
  children: ReactElement
  title?: string
  defaultIsOpen: boolean | undefined
  chevronSize?: string | number
  color?: string
  justifyContent?: { base: string; md?: string }
}

const CollapseArrow = ({
  children,
  header,
  title,
  defaultIsOpen,
  chevronSize,
  color = 'black',
  justifyContent = { base: 'normal', md: 'normal' },
  ...rest
}: Props) => {
  const { isOpen, onToggle, onClose, onOpen } = useDisclosure()

  // Need to handle defaultIsOpen for multiple screen sizes
  // Will close if user resize from lg to sm
  useEffect(() => {
    if (defaultIsOpen) onOpen()
    else onClose()
  }, [defaultIsOpen, onClose, onOpen])
  return (
    <Flex direction="column" {...rest}>
      <Flex
        align="center"
        onClick={onToggle}
        cursor="pointer"
        justifyContent={{ base: justifyContent.base, md: justifyContent.md }}
      >
        {header ?? (
          <Text size="lg" fontWeight="bold">
            {title}
          </Text>
        )}
        <ChevronDownIcon
          width={chevronSize ?? '1em'}
          height={chevronSize ?? '1em'}
          transition="transform 0.2s linear"
          ml="3"
          transform={isOpen ? 'rotate(180deg)' : ''}
          color={color}
        />
      </Flex>
      <Collapse in={isOpen} animateOpacity>
        {children}
      </Collapse>
    </Flex>
  )
}

export default CollapseArrow
