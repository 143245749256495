import { Flex, Text } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'

import { toV2Link, usePlatform } from '@miimosa/design-system'

const AdminBar = ({ slug }: { slug: string }) => {
  const { t } = useTranslation('project')
  const { platform, lang } = usePlatform()

  return (
    <Flex
      direction="row"
      w={{ base: '100vw', md: 'full' }}
      overflowX="auto"
      columnGap={5}
      justifyContent="space-between"
      alignItems="center"
      px={4}
    >
      <Text as="a" href={toV2Link(`/projects/${slug}/dashboard/description`, platform, lang)}>
        {t('project_key')}
      </Text>
      <Text as="a" href={toV2Link(`/projects/${slug}/dashboard/articles`, platform, lang)}>
        {t('articles_key')}
      </Text>
      {/* <Text as="a" href={toV2Link(`/projects/${slug}/comments`, platform, lang)}>
        {t('comments_key')}
      </Text> */}
      <Text as="a" href={toV2Link(`/projects/${slug}/dashboard/pledges`, platform, lang)}>
        {t('contributors_key')}
      </Text>
      <Text as="a" href={toV2Link(`/projects/${slug}/dashboard/charts`, platform, lang)} textAlign="center">
        {t('dashboard_key')}
      </Text>
      <Text as="a" href={toV2Link(`/projects/${slug}/dashboard/partner_logos`, platform, lang)}>
        {t('partners_key')}
      </Text>
    </Flex>
  )
}

export default AdminBar
