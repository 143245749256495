import { AxiosResponse } from 'axios'

import { Client } from './client'

export type ResultSuccess<T> = {
  kind: 'success'
  data: T
}

export type ResultError<T> = {
  kind: 'error'
  error: T
}

export type toResult = <T, E>(res?: AxiosResponse<any>, ex?: any) => Result<T, E>

export type Result<T, E> = ResultSuccess<T> | ResultError<E>

export class BaseAPI {
  protected readonly client: Client
  private readonly responseToResult: toResult

  constructor(client: Client, responseToResult: toResult) {
    this.client = client
    this.responseToResult = responseToResult
  }

  protected isServerSide() {
    return this.client.metadata.ctx !== undefined
  }

  protected async call<T, E>(fetcher: (c: Client) => Promise<AxiosResponse<any>>): Promise<Result<T, E>> {
    return await fetcher(this.client)
      .then((e: AxiosResponse<T>) => this.responseToResult<T, E>(e))
      .catch((err: any) => this.responseToResult<T, E>(undefined, err))
  }
}
