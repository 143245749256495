import { Box, BoxProps } from '@chakra-ui/react'

interface Props extends BoxProps {
  isOpen: boolean
  color?: string
}

export function Burger({ isOpen, color, ...props }: Props) {
  const transform = (sign: number) => ({
    transform: isOpen ? `translateY(${5 * sign}px) rotate(${45 * sign}deg)` : '',
  })
  const iconColor = color || 'dark_green'

  return (
    <Box
      position="relative"
      transition="all 0.2s"
      transform={isOpen ? 'rotate(180deg)' : ''}
      w="20px"
      h="19px"
      {...props}
      sx={{
        i: {
          left: '2px',
          h: '1px',
          w: '15px',
          position: 'absolute',
          borderRadius: '1px',
          transition: 'all 0.2s',
        },
      }}
    >
      <Box as="i" top="4px" bg={iconColor} {...transform(1)} />
      <Box as="i" top="9px" opacity={isOpen ? '0' : ''} bg={iconColor} />
      <Box as="i" bottom="4px" bg={iconColor} {...transform(-1)} />
    </Box>
  )
}

export default Burger
