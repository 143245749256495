import axios from 'axios'
import qs from 'qs'

const client = axios.create({
  baseURL: process.env.NEXT_PUBLIC_SERVER_ENDPOINT_V3 || process.env.SERVER_ENDPOINT_V3,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  paramsSerializer: function (params) {
    return qs.stringify(params, { arrayFormat: 'repeat' })
  },
})

// R is the request, T the response
export function post<R, T>(route: string, body: R) {
  return client.post<T>(route, body)
}

export default client
