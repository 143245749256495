import { Flex, Heading, Text, Checkbox } from '@chakra-ui/react'
import { ErrorMessage } from '@hookform/error-message'
import { FieldErrorsImpl, UseFormRegister } from 'react-hook-form'

import { PSFPFormValues } from '@helpers/psfp/types'

interface CheckboxProps {
  register: UseFormRegister<PSFPFormValues>
  inputName: keyof PSFPFormValues
  title: string
  values: { value: string; trad: string }[]
  errors: Partial<
    FieldErrorsImpl<{
      INPUT_NAME_OTHER_INVESTMENT_EXPERIENCE: string[]
      INPUT_NAME_SECTORS_OF_INTEREST: string[]
      INPUT_NAME_INVESTMENT_HORIZON: string[]
    }>
  >
  isRequired?: boolean
}

const CheckboxInput = ({ register, inputName, title, values, errors, isRequired }: CheckboxProps) => {
  const requiredMsg = 'Ce champ est requis. Veuillez sélectionner au moins une réponse'

  return (
    <Flex direction="column" rowGap={2}>
      <Flex direction="column" rowGap={1}>
        <Heading size="2xs">{title}</Heading>
        <Text size="sm">{'Vous pouvez sélectionner plusieurs options.'}</Text>
      </Flex>
      <Flex direction="column" rowGap={3}>
        {values.map((value) => (
          <Checkbox
            key={value.value}
            colorScheme="checkbox"
            {...register(inputName, {
              required: isRequired && requiredMsg,
            })}
            value={value.value}
          >
            {value.trad}
          </Checkbox>
        ))}
      </Flex>
      <ErrorMessage errors={errors} name={inputName} as={<Text color="red" />} />
    </Flex>
  )
}

export default CheckboxInput
