import {
  Flex,
  BoxProps,
  Text,
  Button,
  Heading,
  useBreakpointValue,
  useDisclosure,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import { FC, useRef } from 'react'

import type { FrontShippingModeType } from '@miimosa/common/types'
import { Icon, toV2Link } from '@miimosa/design-system'
import { usePlatform } from '@miimosa/design-system'

import { Image, Info } from '@components'

interface Props {
  amount: number
  quantity: number
  description: string
  availableQuantity: number
  deliveryAt: string
  imageFileName?: string | null
  rewardId: number
  title: string
  shippingMode: FrontShippingModeType
  shippingDetails: string
  isAvailable: boolean
  takenQuantity: number
  isPreview?: boolean
  linkTo?: string
  star?: boolean
  onModal?: boolean
}

const ModalComponent = ({
  isOpen,
  onClose,
  amount,
  quantity,
  description,
  availableQuantity,
  deliveryAt,
  imageFileName,
  rewardId,
  title,
  shippingMode,
  shippingDetails,
  isAvailable,
  takenQuantity,
  isPreview,
  linkTo,
  star,
  onModal = true,
}: { isOpen: boolean; onClose: () => void } & Props) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody position="relative">
          <Icon
            position="absolute"
            right="0"
            name="Close"
            size="4xs"
            onClick={onClose}
            _hover={{ cursor: 'pointer' }}
            mr={6}
            zIndex={2}
          />
          <RewardItem
            amount={amount}
            quantity={quantity}
            description={description}
            availableQuantity={availableQuantity}
            deliveryAt={deliveryAt}
            imageFileName={imageFileName}
            rewardId={rewardId}
            title={title}
            shippingMode={shippingMode}
            shippingDetails={shippingDetails}
            isAvailable={isAvailable}
            takenQuantity={takenQuantity}
            isPreview={isPreview}
            linkTo={linkTo}
            star={star}
            onModal={onModal}
            border={'0px'}
            px={0}
            py={0}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

const RewardItem: FC<Props & BoxProps> = ({
  amount,
  quantity,
  description,
  availableQuantity,
  deliveryAt,
  imageFileName,
  rewardId,
  title,
  shippingMode,
  shippingDetails,
  isPreview,
  isAvailable,
  takenQuantity,
  linkTo,
  star = false,
  onModal = false,
  ...rest
}) => {
  const { t } = useTranslation('common')
  const { t: tProject } = useTranslation('project')
  const { platform, lang } = usePlatform()
  const isMobile = useBreakpointValue({ base: true, lg: false }, { fallback: 'lg' })
  const { isOpen, onOpen, onClose } = useDisclosure()

  const contentRef = useRef<HTMLDivElement>(null)

  const ctaString = (!isAvailable && t('outOfStock')) || t('select')
  const data = [
    ...(availableQuantity > 0 ? [{ title: t('left_quantity'), value: [availableQuantity - takenQuantity] }] : []),
    ...(deliveryAt
      ? [
          {
            title: t('estimated_shipping'),
            value: [new Intl.DateTimeFormat('fr-FR', { month: 'long', year: 'numeric' }).format(new Date(deliveryAt))],
          },
        ]
      : []),
    ...(shippingMode && shippingMode !== 'no_shipping'
      ? [{ title: t('shipping'), value: [t(shippingMode)], withIcon: true, iconDetails: shippingDetails }]
      : []),
  ]

  const setHrefForReward = () => {
    if (linkTo) {
      const href =
        rewardId > 0 ? toV2Link(`${linkTo}?reward_id=${rewardId}`, platform, lang) : toV2Link(linkTo, platform, lang)
      return (location.href = href)
    } else {
      return null
    }
  }

  return (
    <Flex
      cursor={((isAvailable || isPreview) && 'default') || 'not-allowed'}
      direction="column"
      position="relative"
      border="solid 1px"
      borderRadius="4px"
      borderColor="light_gray"
      bgColor="white"
      width={{ base: '100%', lg: '330px' }}
      height={{ base: !onModal ? (imageFileName ? '650px' : '430px') : 'fit-content', lg: 'fit-content' }}
      px={4}
      pt={2}
      pb={6}
      {...rest}
    >
      <ModalComponent
        isOpen={isOpen}
        onClose={onClose}
        amount={amount}
        quantity={quantity}
        description={description}
        availableQuantity={availableQuantity}
        deliveryAt={deliveryAt}
        imageFileName={imageFileName}
        rewardId={rewardId}
        title={title}
        shippingMode={shippingMode}
        shippingDetails={shippingDetails}
        isAvailable={isAvailable}
        takenQuantity={takenQuantity}
        isPreview={isPreview}
        linkTo={linkTo}
        star={star}
      />
      <Flex
        direction="column"
        w="full"
        ref={contentRef}
        h={{ base: !onModal ? '560px' : 'fit-content', lg: 'fit-content' }}
        overflow="hidden"
        position="relative"
      >
        <Flex width="100%" justify="center" mb={1} h="20px">
          {star && (
            <Text
              color="orange"
              _before={{
                minW: '10px',
                content: '""',
                backgroundImage: '/images/up_brush_3.svg',
                backgroundRepeat: 'no-repeat',
                height: '10px',
                display: 'inline-flex',
                backgroundSize: 'contain',
                width: '10px',
                transform: 'rotateY(180deg)',
                position: 'relative',
                top: '-8px',
              }}
              _after={{
                content: '""',
                backgroundImage: '/images/up_brush_3.svg',
                backgroundRepeat: 'no-repeat',
                height: '10px',
                display: 'inline-flex',
                backgroundSize: 'contain',
                width: '10px',
                transform: 'rotateZ(60deg)',
                position: 'relative',
                top: '8px',
              }}
            >
              Coup de <Icon name="Heart" size="5xs" />
            </Text>
          )}
        </Flex>

        {imageFileName && (
          <Flex width="100%" bgColor="transparent" position="relative" minHeight="220px" height="220px">
            <Image src={imageFileName} alt="" fill style={{ objectFit: 'cover' }} />
          </Flex>
        )}
        <Flex direction="column" h="full" justifyContent="space-between">
          <Flex direction="column">
            <Heading size="md" color="dark_green" mt={4}>
              {amount > 0
                ? t('amount', {
                    val: new Intl.NumberFormat('fr-FR', {
                      maximumFractionDigits: 0,
                    }).format(amount),
                  })
                : tProject('rewards.free_amount')}
            </Heading>
            <Text size="xl" my={4} color="green">
              {title}
            </Text>
            <Flex direction="column" position="relative">
              <Text size="md" whiteSpace="pre-wrap" h="fit-content" overflow="hidden">
                {description}
              </Text>
            </Flex>
            {!!data?.length && <Info mt="4" rowGap={2} data={data} isGrey />}
            {isMobile &&
              !onModal &&
              contentRef &&
              contentRef.current &&
              contentRef.current.scrollHeight > contentRef.current.clientHeight && (
                <Flex
                  position="absolute"
                  bottom="0"
                  left="0"
                  width="100%"
                  background="linear-gradient(180deg, rgba(255,255,255,0.4) 35%, rgba(255,255,255,1) 100%)"
                  align="center"
                  justify="center"
                  height="50px"
                >
                  <Button onClick={onOpen}>{t('see_more')}</Button>
                </Flex>
              )}
          </Flex>
        </Flex>
      </Flex>
      <Flex
        direction={onModal ? 'column' : 'row'}
        mt="6"
        rowGap="2"
        align="center"
        justify={takenQuantity > 0 ? 'space-between' : 'flex-end'}
      >
        {!!takenQuantity && (
          <Text size="sm" color="green">
            {t('chosen', { total: takenQuantity })}
          </Text>
        )}
        {linkTo && (
          <Button
            onClick={() => setHrefForReward()}
            variant="primary"
            isDisabled={!isAvailable || isPreview}
            disabled={!isAvailable || isPreview}
            size="sm"
            w={onModal ? 'full' : 'fit-content'}
            className="add_cart"
          >
            {ctaString}
          </Button>
        )}
      </Flex>
    </Flex>
  )
}

export default RewardItem
