import { Button, Flex, Link, Text, useBreakpointValue } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import NextLink from 'next/link'
import { RefObject, useEffect, useRef, useState } from 'react'

import { FrontCollectTypeType } from '@miimosa/common'
import type { Subscription, navBarType } from '@miimosa/common/types'
import { Icon, toV2Link, usePlatform } from '@miimosa/design-system'

type ProjectNavBarType = {
  data: navBarType
  activeTab: string
  subscriptionState: Subscription['subscription'] | null
  slug: string
  collectType: FrontCollectTypeType
  containerTopRef: RefObject<HTMLDivElement>
  buttonRef: RefObject<HTMLButtonElement>
  isPreview: boolean
}

const ProjectNavBar = ({
  data,
  activeTab,
  subscriptionState,
  slug,
  collectType,
  containerTopRef,
  buttonRef,
  isPreview,
}: ProjectNavBarType) => {
  const { t } = useTranslation('project')
  const isMobile = useBreakpointValue({ base: true, lg: false }, { fallback: 'lg' })
  const navbar2Ref = useRef<HTMLDivElement>(null)
  const [isSubscribed, setIsSubscribed] = useState(subscriptionState === 'subscribed')
  const [goUp, setGoUp] = useState(0)
  const [opacityButtonSupport, setOpacityButtonSupport] = useState(0)
  const { platform, lang } = usePlatform()

  const handleSub = async (isSubscribed: boolean) => {
    if (!isSubscribed) {
      const res = await fetch(`/nextapi/projects/${slug}/subscriptions`, {
        credentials: 'same-origin',
        method: 'POST',
      })
      if (res.status === 200) {
        setIsSubscribed(true)
      }
    } else {
      const res = await fetch(`/nextapi/projects/${slug}/subscriptions`, {
        credentials: 'same-origin',
        method: 'DELETE',
      })
      if (res.status === 200) {
        setIsSubscribed(false)
      }
    }
  }

  const Up = () => {
    const elementPosition = containerTopRef.current?.offsetTop
    if (elementPosition) {
      const offset = elementPosition - 82 // navbar height

      window.scrollTo({
        top: offset,
        behavior: 'smooth',
      })
    }
  }

  useEffect(() => {
    if (!isMobile) {
      const onScroll = () => {
        if (navbar2Ref && navbar2Ref.current?.getBoundingClientRect().top == 0) {
          setGoUp(1)
        } else {
          setGoUp(0)
        }
      }
      window.addEventListener('scroll', onScroll)

      return () => window.removeEventListener('scroll', onScroll)
    }
  }, [isMobile])

  useEffect(() => {
    const onScroll = () => {
      if (buttonRef && buttonRef.current) {
        if (buttonRef.current.getBoundingClientRect().bottom <= 80) {
          setOpacityButtonSupport(1)
        } else {
          setOpacityButtonSupport(0)
        }
      }
    }

    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [buttonRef])

  return (
    <Flex
      direction="row"
      w="full"
      h="82px"
      minH="82px"
      alignItems="center"
      overflowX="auto"
      bgColor="white"
      columnGap={{ base: 5, md: 0 }}
      id="navProject"
      ref={navbar2Ref}
    >
      <Flex w="full" columnGap={5} py={0} h="full" alignItems="center">
        <Icon
          name="Up"
          _hover={{ cursor: 'pointer' }}
          color="dark_green"
          onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}
          opacity={goUp}
          transition={'opacity 0.3s ease'}
          size="4xs"
        />
        {Object.values(data).map(({ key, title, count, display, link }) => (
          <NextLink href={`/projects/${slug}/${link}`} key={key} style={{ height: '100%' }} scroll={false}>
            <Flex
              h="full"
              justifyContent="space-around"
              alignItems="center"
              position="relative"
              onClick={() => Up()}
              cursor="pointer"
              direction="row"
              display={display}
              _hover={{ boxShadow: 'inset 0 -4px 0 0 #003F2C' }}
              transition={'box-shadow 0.3s linear 0s'}
              boxShadow={activeTab == key ? 'inset 0 -4px 0 0 #003F2C' : ''}
              px={3}
            >
              <Text
                size={'md'}
                color="dark_green"
                h="full"
                display="flex"
                alignItems="center"
                justifyContent="center"
                whiteSpace="nowrap"
                w="full"
              >
                {title}
              </Text>
              {!!count && (
                <Flex
                  backgroundColor="orange"
                  minW={'25px'}
                  px={2}
                  py={0.5}
                  borderRadius="70px"
                  ml={2}
                  justifyContent="center"
                >
                  <Text color="white">{count}</Text>
                </Flex>
              )}
            </Flex>
          </NextLink>
        ))}
        {subscriptionState != null && (
          <Button variant="secondary" size="xs" minW="fit-content" onClick={() => handleSub(isSubscribed)}>
            {isSubscribed ? t('unfollow') : t('follow')}
          </Button>
        )}
        {!isMobile && (
          <Flex w="full" direction="column">
            <Link href={toV2Link(`/projects/${slug}/pledges/new`, platform, lang)} alignSelf="flex-end">
              <Button
                variant="primary"
                size="md"
                alignSelf="flex-end"
                opacity={opacityButtonSupport}
                transition={'opacity 0.3s ease'}
                isDisabled={isPreview ? true : false}
              >
                {collectType == 'lending' ? 'Investir' : 'Soutenir ce projet'}
              </Button>
            </Link>
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}

export default ProjectNavBar
