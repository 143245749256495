import { Flex, FlexProps, BoxProps, Heading } from '@chakra-ui/react'
import { FC } from 'react'

interface Props {
  title: string
  description?: string
  isLight?: boolean
}

const UnderlinedTitle: FC<Props & BoxProps & FlexProps> = ({ title, description, isLight }) => {
  const color = isLight ? 'white' : 'main_gray'
  return (
    <Flex width="100%" direction="column" borderBottom="2px" pb="40px" mb="24px" borderColor={color}>
      <Heading size="lg" textAlign="left" fontWeight="bold" w="100%" color={color}>
        {title}
      </Heading>
      {description && (
        <Heading size="sm" textAlign="left" w="100%" mt="16px" color={color}>
          {description}
        </Heading>
      )}
    </Flex>
  )
}

export default UnderlinedTitle
