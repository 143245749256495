import { useRouter } from 'next/router'
import { Dispatch, SetStateAction } from 'react'

const useFilter = (
  formattedFilters: Record<string, string[] | []>,
  setFormattedFilters: Dispatch<SetStateAction<Record<string, string[] | []>>>
) => {
  const { pathname, push } = useRouter()
  const url = { pathname }

  return {
    filters: formattedFilters,
    addFilter: (categoryId: string, newFilter: string) => {
      setFormattedFilters({ ...formattedFilters, [categoryId]: [...formattedFilters[categoryId], newFilter] })
    },
    removeFilter: (categoryId: string, filter: string) =>
      setFormattedFilters({
        ...formattedFilters,
        [categoryId]: formattedFilters[categoryId]?.filter((item) => item !== filter),
      }),
    addRadioFilter: (categoryId: string, newFilter: string) => {
      setFormattedFilters({ ...formattedFilters, [categoryId]: [newFilter] })
    },
    clearFilters: () => {
      const flushedParams = Object.entries(formattedFilters)
        .map(([catId]) => [catId, []])
        .reduce((acc, [cat, data]) => {
          return { ...acc, ...{ [cat as string]: data } }
        }, {})

      setFormattedFilters(flushedParams)
      push(url)
    },
  }
}

export const getTotalFilters = (filters: { [id: string]: string[] }): number =>
  Object.values(filters)?.reduce((acc, filter) => acc + filter?.length, 0)

export default useFilter
