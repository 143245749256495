import { Flex } from '@chakra-ui/react'
import { ReactNode } from 'react'

const StickyBottom = ({ children }: { children: ReactNode }) => {
  return (
    <Flex
      bgColor="white"
      py="2"
      position="sticky"
      bottom="0"
      width="100%"
      align="center"
      justify="center"
      minHeight="80px"
      zIndex="2"
    >
      {children}
    </Flex>
  )
}

export default StickyBottom
